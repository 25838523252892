export function DragSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
    >
      <path d="M6.53665 3.42292C7.34488 3.42292 8.00007 2.76773 8.00007 1.95951C8.00007 1.15129 7.34488 0.496094 6.53665 0.496094C5.72843 0.496094 5.07324 1.15129 5.07324 1.95951C5.07324 2.76773 5.72843 3.42292 6.53665 3.42292Z" />
      <path d="M6.53665 8.46393C7.34488 8.46393 8.00007 7.80874 8.00007 7.00052C8.00007 6.1923 7.34488 5.53711 6.53665 5.53711C5.72843 5.53711 5.07324 6.1923 5.07324 7.00052C5.07324 7.80874 5.72843 8.46393 6.53665 8.46393Z" />
      <path d="M6.53665 13.5049C7.34488 13.5049 8.00007 12.8498 8.00007 12.0415C8.00007 11.2333 7.34488 10.5781 6.53665 10.5781C5.72843 10.5781 5.07324 11.2333 5.07324 12.0415C5.07324 12.8498 5.72843 13.5049 6.53665 13.5049Z" />
      <path d="M1.46341 3.42292C2.27163 3.42292 2.92683 2.76773 2.92683 1.95951C2.92683 1.15129 2.27163 0.496094 1.46341 0.496094C0.655192 0.496094 0 1.15129 0 1.95951C0 2.76773 0.655192 3.42292 1.46341 3.42292Z" />
      <path d="M1.46341 8.46393C2.27163 8.46393 2.92683 7.80874 2.92683 7.00052C2.92683 6.1923 2.27163 5.53711 1.46341 5.53711C0.655192 5.53711 0 6.1923 0 7.00052C0 7.80874 0.655192 8.46393 1.46341 8.46393Z" />
      <path d="M1.46341 13.5049C2.27163 13.5049 2.92683 12.8498 2.92683 12.0415C2.92683 11.2333 2.27163 10.5781 1.46341 10.5781C0.655192 10.5781 0 11.2333 0 12.0415C0 12.8498 0.655192 13.5049 1.46341 13.5049Z" />
    </svg>
  );
}
