import { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import style from "./BasicInputs.module.scss";
import { useAppDispatch } from "store/hooks";
import {
  setDataBasicInfo,
  setIsValidBasicInfo,
} from "@global-state/pages/product/slice";
import { BasicInputProps, Input, TextareaProps } from "@ui";
import { CategorySelect } from "../../../../../common/UI/CategorySelect";

export function BasicInputs() {
  const { variationId } = useParams();

  const [field, meta] = useField("category_id");

  const { values, isValid, setFieldValue } = useFormikContext<any>();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsValidBasicInfo(isValid));
  }, [isValid]);

  useEffect(() => {
    dispatch(
      setDataBasicInfo({
        ...values,
        discount: Number(values.discount || "0"),
        buying_price: Number(values.buying_price || "0"),
        old_price: Number(values.old_price || "0"),
        retail_price: Number(values.retail_price || "0"),
      }),
    );
  }, [values]);

  return (
    <div className={style["formContent"]}>
      <div className={style["inputsContaier"]}>
        <div className="w-[50%] max-sm:w-full">
          <Input<BasicInputProps>
            formikName="sku"
            label="Артикул*"
            inputType="text"
            placeholder="#00000001"
            className={style["customInput"]}
          />
        </div>
      </div>
      <div className={style["inputsContaier"]}>
        <div className="w-[50%] max-sm:w-full">
          <Input<BasicInputProps>
            formikName="ua_name"
            label="Назва UA*"
            inputType="text"
            placeholder="Футболка червона"
            className={style["customInput"]}
          />
        </div>
        <div className="w-[50%] max-sm:w-full">
          <Input<BasicInputProps>
            formikName="ru_name"
            label="Назва RU"
            inputType="text"
            placeholder="Футболка красная"
            className={style["customInput"]}
          />
        </div>
      </div>
      <div className={style["inputsContaier"]}>
        <div className="w-[50%] max-sm:w-full">
          <Input<BasicInputProps>
            formikName="buying_price"
            label="Ціна покупки"
            inputType="text"
            className={style["customInput"]}
            placeholder="0"
          />
        </div>
        <div className="w-[50%] max-sm:w-full">
          <Input<BasicInputProps>
            formikName="old_price"
            label="Стара ціна"
            inputType="text"
            className={style["customInput"]}
            placeholder="0"
          />
        </div>
      </div>
      <div className={style["inputsContaier"]}>
        <div className="w-[50%] max-sm:w-full">
          <Input<BasicInputProps>
            formikName="retail_price"
            label="Роздрібна ціна*"
            inputType="text"
            className={style["customInput"]}
            placeholder="0"
          />
        </div>
        <div className="w-[50%] max-sm:w-full">
          <Input<BasicInputProps>
            formikName="discount"
            label="Знижка"
            inputType="text"
            className={style["customInput"]}
            placeholder="0"
          />
        </div>
      </div>
      <div className={style["inputsContaier"]}>
        <Input<TextareaProps>
          inputType="textarea"
          formikName="ua_description"
          label="Опис UA"
        />
      </div>
      <div className={style["inputsContaier"]}>
        <Input<TextareaProps>
          inputType="textarea"
          formikName="ru_description"
          label="Опис RU"
        />
      </div>
      <div className="w-[50%] max-sm:w-full">
        {!variationId && (
          <CategorySelect
            error={meta.touched && meta.error ? meta.error : ""}
            onChange={(value) => {
              setFieldValue("category_id", value);
            }}
            initialCategoryId={Number(field.value)}
          />
        )}
      </div>
    </div>
  );
}
