import Tenant, { TenantDelete } from "@interfaces/tenant";
import { authApi } from "..";
import SuperAdmin from "@interfaces/superadmin";
import { accountLogIn, accountLogOut } from "@global-state/global/slice";

const accountApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    account: build.query<SuperAdmin, void>({
      query: () => "/account",
    }),
    tenantCreate: build.mutation<SuperAdmin, Pick<Tenant, "account_type" | "subdomain">>({
      query: (body) => ({
        url: `/account/tenant`,
        method: "POST",
        body: body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            accountApi.util.upsertQueryData("account", undefined, data),
          );
        } catch { }
      },
    }),
    tenantDelete: build.mutation<SuperAdmin, Tenant["uuid"]>({
      query: (tenantId) => ({
        url: "/account/tenant?uuid=" + tenantId,
        method: "DELETE",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            accountApi.util.upsertQueryData("account", undefined, data),
          );
        } catch { }
      },
    }),
    tenantCancelDelete: build.mutation<SuperAdmin, TenantDelete["uuid"]>({
      query: (tenantId) => ({
        url: "/account/tenant/cancel_deletion",
        method: "POST",
        body: {
          uuid: tenantId
        }
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            accountApi.util.upsertQueryData("account", undefined, data),
          );
        } catch { }
      },
    }),
  }),
  overrideExisting: false,
});

const accountLogApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    forgotPasswordAccount: build.mutation<void, string>({
      query: (email) => {
        return {
          url: "/auth/forgot-password",
          method: "POST",
          body: { email }
        }
      },
    }),
    login: build.mutation<SuperAdmin & {
      access_token: string,
      refresh_token: string,
    }, {
      email: string,
      password: string,
    }>({
      query: (body) => {
        return {
          url: "/auth/login",
          method: "POST",
          body
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: { access_token, refresh_token, ...superadminData } } = await queryFulfilled;

          window.localStorage.setItem("access_token", access_token);
          window.localStorage.setItem("refresh_token", refresh_token);

          dispatch(
            accountApi.util.upsertQueryData("account", undefined, superadminData),
          );
          dispatch(accountLogIn());
        } catch { }
      },
    }),
    register: build.mutation<{
      service_token: string
    }, {
      first_name: string,
      last_name: string,
      email: string,
      country: string | null,
      tz: string | null,
      lang: string | null,
      subdomain: Tenant["subdomain"],
      account_type: Tenant["account_type"],
    }>({
      query: (body) => {
        return {
          url: "/auth/register",
          method: "POST",
          body
        }
      }
    }),
    resetPassword: build.mutation<SuperAdmin & {
      access_token: string,
      refresh_token: string,
    }, {
      token: string,
      password: string,
    }>({
      query: (body) => {
        return {
          url: "/auth/reset-password",
          method: "POST",
          body
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: { access_token, refresh_token, ...superadminData } } = await queryFulfilled;

          window.localStorage.setItem("access_token", access_token);
          window.localStorage.setItem("refresh_token", refresh_token);

          dispatch(
            accountApi.util.upsertQueryData("account", undefined, superadminData),
          );
          dispatch(accountLogIn());
        } catch { }
      },
    }),
    resendCode: build.mutation<void, void>({
      query: () => {
        return {
          url: "/auth/resend_code",
          method: "POST",
          body: {
            service_token: window.localStorage.getItem("service_token")
          }
        }
      },
    }),
    verify: build.mutation<SuperAdmin & {
      access_token: string,
      refresh_token: string,
    }, {
      service_token: string,
      verification_code: string,
    }>({
      query: (body) => {
        return {
          url: "/auth/verify",
          method: "POST",
          body
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: { access_token, refresh_token, ...superadminData } } = await queryFulfilled;

          window.localStorage.removeItem("service_token");
          window.localStorage.setItem("access_token", access_token);
          window.localStorage.setItem("refresh_token", refresh_token);

          dispatch(
            accountApi.util.upsertQueryData("account", undefined, superadminData),
          );
          dispatch(accountLogIn());
        } catch { }
      },
    }),
    logout: build.mutation<{ ok: boolean }, void>({
      query: () => {
        return {
          url: "/auth/logout",
          method: "POST",
          body: {
            access_token: window.localStorage.getItem("access_token") || "",
            refresh_token: window.localStorage.getItem("refresh_token") || "",
          },
          headers: {
            Authorization: window.localStorage.getItem("access_token") || "",
          }
        }
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data.ok) {
            window.localStorage.removeItem("access_token");
            window.localStorage.removeItem("refresh_token");
            dispatch(accountLogOut());
          }
        } catch { }
      },
    })
  }),
  overrideExisting: false,
});

export const {
  useLazyAccountQuery,
  useAccountQuery,
  useTenantCreateMutation,
  useTenantDeleteMutation,
  useTenantCancelDeleteMutation
} = accountApi;

export const {
  useForgotPasswordAccountMutation,
  useLoginMutation,
  useRegisterMutation,
  useResetPasswordMutation,
  useResendCodeMutation,
  useVerifyMutation,
  useLogoutMutation
} = accountLogApi;