import cn from "classnames";
import style from "./MenuCategoriesList.module.scss";
import { useCategoriesAllQuery } from "@RTKQuery/categories/api";
import Category from "@interfaces/category";
import { Spinner } from "@ui";

export function MenuCategoriesList({
  children,
}: {
  children: (data: Category) => JSX.Element;
}) {
  const { data, isFetching, isError, error } = useCategoriesAllQuery();

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else {
    return (
      <nav className={cn(style["menu"], "scroll")}>
        {children({
          id: 0,
          name: {
            uk: "Всі товари",
          },
          parent_id: null,
          children: [],
        })}
        {data?.map((item) => children(item))}
      </nav>
    );
  }
}
