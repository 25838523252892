import { BasicInput, PasswordInput, SearchInput, Textarea } from "./components";

export type BasicInputProps = {
  formikName: string;
  label: JSX.Element | string;
  helperText?: (text: string) => JSX.Element;
  helperTextVisibility?: boolean;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
};

export type PasswordInputProps = BasicInputProps & {
  passwordRef?: React.MutableRefObject<string>;
};

export type TextareaProps = BasicInputProps;

export type SearchInputProps = Pick<BasicInputProps, "placeholder"> & {
  onSearch: (value: string) => void;
};

type InputProps =
  | PasswordInputProps
  | BasicInputProps
  | TextareaProps
  | SearchInputProps;

export function Input<T extends InputProps>({
  inputType,
  ...props
}: { inputType: "password" | "email" | "text" | "textarea" | "search" } & T) {
  switch (inputType) {
    case "text":
    case "email":
      return <BasicInput {...(props as unknown as BasicInputProps)} />;
    case "password":
      return <PasswordInput {...(props as unknown as PasswordInputProps)} />;
    case "textarea":
      return <Textarea {...(props as unknown as TextareaProps)} />;
    case "search":
      return <SearchInput {...(props as unknown as SearchInputProps)} />;
    default:
      return null;
  }
}
