import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { Button } from "@ui";
const MODAL_NAME = "CRMPaymentRequired";

export function CRMPaymentRequired() {
  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const navigate = useNavigate();

  const crm =
    process.env.NODE_ENV === "development"
      ? `/${window.localStorage.getItem("subdomain")}`
      : "";

  function hiddenModal() {
    navigate(crm + "/billing");
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Цей функціонал не оплачено"
    >
      <Button
        text="Перейти на сторінку оплати"
        variant="outlined"
        size="big"
        className="mx-auto h-[40px] w-[250px]"
        onClick={() => {
          hiddenModal();
        }}
      />
    </CRMModalLayout>
  );
}
