import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { SubmitButton, Input, Select, Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { showModal } from "@global-state/entity/modal-state/slice";
import { useProductPropertiesGroupTreeAllQuery, useProductPropertyCreateMutation } from "@RTKQuery/product-properties/api";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Назва - обов'язкове поле"),
});

const notFoundElements = [
  {
    id: -1,
    value: "-1",
    label: "Не знайдено",
  },
];

export function CreatePropertyForm() {
  const { group } = useParams();

  const { data: listGroup } = useProductPropertiesGroupTreeAllQuery(void 0, {
    selectFromResult: ({ data, ...response }) => {
      let displayReferenceBookGroup =
        data?.map((item) => ({
          id: item.id,
          value: String(item.id),
          label: item.group,
        })) || null;

      if (
        !displayReferenceBookGroup ||
        displayReferenceBookGroup.length === 0
      ) {
        displayReferenceBookGroup = notFoundElements;
      }

      return {
        ...response,
        data: displayReferenceBookGroup,
      };
    },
  });

  const [
    createProductProperty,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useProductPropertyCreateMutation({
    fixedCacheKey: "add-book",
  });

  const activeModal = useAppSelector(
    (state) => state.modalState["CreatePropertyModal"],
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        group_id: listGroup[0].value,
        multi_selection: false,
        required: false,
        type: "characteristic"
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        createProductProperty({
          group_id: Number(
            activeModal?.mode === "import" ? values.group_id : group,
          ),
          name: values.name.trim(),
          multi_selection: values.multi_selection,
          required: values.required,
          type: values.type as "characteristic" | "variation"
        })
          .unwrap()
          .then(() => {
            actions.resetForm();
          })
          .catch(() => { });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mb-[40px] flex flex-col gap-[20px]">
            <Input formikName="name" label="Назва" inputType="text" />
            <Select
              formikName="type"
              label="Тип"
              listValue={[
                {
                  id: 1,
                  value: "variation",
                  label: "Варіація",
                },
                {
                  id: 2,
                  value: "characteristic",
                  label: "Характеристика",
                },
              ]}
            />
            <Checkbox
              label="Обов'язкова"
              checked={values.required}
              onChange={() => {
                setFieldValue("required", !values.required);
              }}
            />
            <Checkbox
              label="Мультивибір"
              checked={values.multi_selection}
              onChange={() => {
                setFieldValue("multi_selection", !values.multi_selection);
              }}
            />
            {activeModal?.mode === "import" ? (
              <Select
                formikName="group_id"
                label="Група"
                listValue={listGroup}
                addItemButtonFunc={() => {
                  dispatch(
                    showModal({
                      modalName: "CreatePropertiesGroupModal",
                    }),
                  );
                }}
              />
            ) : null}
          </div>
          <SubmitButton
            type="submit"
            disabled={isLoading}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Зберегти"
            successText="Успішно збережено"
            errorText="Не вдалось зберегти"
            errorMesage={
              error
                ? (error as unknown as { data: { error: string } }).data?.error
                : ""
            }
          />
        </Form>
      )}
    </Formik>
  );
}
