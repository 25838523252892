import { BigConteined, BigOutlined, Small } from "./components/buttons";

export interface Props {
  type?: "add" | "delete" | "edit";
  tabIndex?: number;
  variant?: "contained" | "outlined";
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: "big" | "small";
  text?: string;
  disabled?: boolean;
  className?: string;
  icon?: (defaultStyle: string, fill: string) => JSX.Element;
  alignIcon?: "left" | "right";
  color?: "default" | "success" | "error";
  customStyle?: {
    button?: string;
    text?: string;
    icon?: string;
  };
  darkAlternative?: boolean;
}

export function Button({
  variant = "contained",
  size = "small",
  ...shared
}: Props) {
  if (size === "big") {
    if (variant === "contained") {
      return <BigConteined {...shared} />;
    } else {
      return <BigOutlined {...shared} />;
    }
  } else {
    return <Small {...shared} />;
  }
}
