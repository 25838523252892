import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import style from "./DeletePropertyValueModal.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { Button, SubmitButton } from "@ui";
import { hideModal } from "@global-state/entity/modal-state/slice";
import {
  referenceBook_deleteValues_clearData,
  referenceBook_deleteValues_deleteData,
  referenceBook_editValues_clearData,
  referenceBook_editValues_deleteData,
} from "@global-state/pages/productProperties/slice";
import { useProductPropertyValuesDeleteMutation } from "@RTKQuery/product-properties/api";

const MODAL_NAME = "DeletePropertyValueModal";

export function DeletePropertyValueModal() {
  const { propertyId } = useParams();

  const [
    deleteProductPropertyValues,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useProductPropertyValuesDeleteMutation();

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const listItems = useAppSelector(
    (state) => state.productPropertiesPage.delete_values,
  );
  let listIds: number[] = [];

  if (activeModal?.mode === "single") {
    listIds = [listItems.find((item) => item.tag === "single")?.id || -1];
  } else {
    listIds = Array.from(new Set(listItems.map((item) => item.id)));
  }

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        if (activeModal?.mode === "single") {
          dispatch(
            referenceBook_deleteValues_deleteData({
              id: listIds[0],
              singleOnly: false,
            }),
          );
          dispatch(
            referenceBook_editValues_deleteData({
              id: listIds[0],
              singleOnly: false,
            }),
          );
        } else {
          dispatch(referenceBook_deleteValues_clearData());
          dispatch(referenceBook_editValues_clearData());
        }
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess && activeModal?.mode === "single") {
      dispatch(
        referenceBook_deleteValues_deleteData({
          id: listIds[0],
          singleOnly: true,
        }),
      );
    }
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Ви впевнені, що хочете видалити вибрані значення?"
    >
      <>
        <div className={style["buttons"]}>
          <div className="w-[50%]">
            <Button
              text="Скасувати"
              variant="outlined"
              size="big"
              onClick={() => {
                hiddenModal();
              }}
            />
          </div>
          <div className="w-[50%]">
            <SubmitButton
              type="submit"
              disabled={isLoading}
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              uninitializedText="Підтвердити"
              successText="Успішно видалено"
              errorText="Не вдалось видалити"
              errorMesage={""}
              onClick={() => {
                deleteProductPropertyValues({
                  property_id: Number(propertyId),
                  items: listIds,
                });
              }}
            />
          </div>
        </div>
        <p className={style["errorMessage"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : ""}
        </p>
      </>
    </CRMModalLayout>
  );
}
