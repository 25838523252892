import { useEffect, useState } from "react";
import { HideSvg, ShowSvg } from ".";
import cn from "classnames";

import style from "./Burger.module.scss";
import { useLocation } from "react-router-dom";

export function Burger({ children }: { children: JSX.Element }) {
  const [visibility, setVisibility] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    setVisibility(false);
  }, [location]);

  return (
    <div className={cn(style["burger"], visibility && style["burger_visible"])}>
      <button
        className={style["showButton"]}
        onClick={() => {
          setVisibility(true);
        }}
      >
        <ShowSvg />
      </button>
      <button
        className={style["hideButton"]}
        onClick={() => {
          setVisibility(false);
        }}
      >
        <HideSvg />
      </button>
      <div className={style["backdrop"]}>
        <div className={style["menuModal"]}>{children}</div>
      </div>
    </div>
  );
}
