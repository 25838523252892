import { ConnectedProduct } from "@interfaces/stock";
import { Button } from "@ui";
import { СonnectionSvg } from "./Сonnection.svg";
import { useAppDispatch } from "store/hooks";
import { showModal } from "@global-state/entity/modal-state/slice";
import { setActiveProviderProductStock } from "@global-state/pages/stock/slice";

export function ProvidersItemButton({
  modalData,
}: {
  modalData: ConnectedProduct;
}) {
  const dispatch = useAppDispatch();

  return (
    <div className="mx-[15px] mb-[20px]">
      <Button
        customStyle={{
          button: "h-[30px]",
          text: "text-[13px]",
        }}
        icon={() => <СonnectionSvg />}
        size="big"
        variant="outlined"
        text="Зв'язати варіації"
        onClick={() => {
          dispatch(setActiveProviderProductStock(modalData));
          dispatch(
            showModal({
              modalName: "ConnectedProductStockModal",
            }),
          );
        }}
      />
    </div>
  );
}
