import style from "./TableProductWrapper.module.scss";

export function TableProductWrapper({
  children,
  colCount,
}: {
  children: JSX.Element;
  colCount: number;
}) {
  return (
    <div className={style["scrollContainer"]}>
      <div
        style={
          {
            "--col-count": colCount,
          } as React.CSSProperties
        }
        className={style["gridContainer"]}
      >
        {children}
      </div>
    </div>
  );
}
