export function ShowSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
    >
      <rect x="9" width="9" height="2" rx="1" fill="#C2C2C2" />
      <rect x="4" y="5" width="14" height="2" rx="1" fill="#C2C2C2" />
      <rect y="10" width="18" height="2" rx="1" fill="#C2C2C2" />
    </svg>
  );
}
