import { redirect } from "react-router-dom";

import {
  LoginCRMPage,
  ForgotPasswordCRMPage,
  ResetPasswordCRMPage,
} from "../../pages";
import { CRMAuthLayout } from "../../pages/layout/crm";

const routes = [
  {
    path: "login",
    element: <LoginCRMPage />,
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordCRMPage />,
  },
  {
    path: "reset-password/:token",
    element: <ResetPasswordCRMPage />,
  },
];

export const CRMAuthRouterConfig = (crm: string) => [
  {
    path: "*",
    loader: () => {
      const rootPath = process.env.NODE_ENV === "development" ? `/${crm}` : "";

      return redirect(rootPath + "/auth/login");
    },
  },
  {
    path: process.env.NODE_ENV === "development" ? `${crm}/auth` : "auth",
    element: <CRMAuthLayout />,
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      const rootPath = process.env.NODE_ENV === "development" ? `/${crm}` : "";

      if (
        pathname === rootPath + "/auth" ||
        pathname === rootPath + "/auth/" ||
        pathname === rootPath ||
        pathname === rootPath + "/"
      ) {
        return redirect(rootPath + "/auth/login");
      }

      return null;
    },
    children: routes,
  },
];
