import cn from "classnames";
import style from "./MenuPropertiesGroups.module.scss";
import { useState } from "react";
import {
  MenuPropertyGroupsCreateButton,
  MenuPropertyGroupsItem,
  MenuPropertyGroupsLabel,
  MenuPropertyGroupsList,
} from "./components";

export function MenuPropertiesGroups() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={style["sidebar"]}>
      <MenuPropertyGroupsLabel isOpen={isOpen} setIsOpen={setIsOpen} />
      <div
        className={cn(style["groupsBox"], !isOpen && style["groupsBox_close"])}
      >
        <MenuPropertyGroupsList>
          {(data) => <MenuPropertyGroupsItem key={data.id} data={data} />}
        </MenuPropertyGroupsList>
        <MenuPropertyGroupsCreateButton />
      </div>
    </div>
  );
}
