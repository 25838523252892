import { showModal } from "@global-state/entity/modal-state/slice";
import { Button } from "@ui";
import { useAppDispatch } from "store/hooks";
import style from "./MenuPropertiesGroupsCreateButton.module.scss";

export function MenuPropertyGroupsCreateButton() {
  const dispatch = useAppDispatch();

  return (
    <div className={style["button"]}>
      <Button
        type="add"
        size="big"
        variant="outlined"
        onClick={() => {
          dispatch(
            showModal({
              modalName: "CreatePropertiesGroupModal",
            }),
          );
        }}
      />
    </div>
  );
}
