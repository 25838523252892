import { SystemNotification } from "@interfaces/system-notifications";
import { authApi } from "..";

const systemNotificationsApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        systemNotificationAll: build.query<SystemNotification[], void>({
            query: () => "notifications",
        }),
        systemNotificationOnRead: build.mutation<void, SystemNotification["id"][]>({
            query: (listIds) => {
                return {
                    url: `notifications`,
                    method: "PATCH",
                    body: listIds
                }
            },
            async onQueryStarted(listIds, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        systemNotificationsApi.util.updateQueryData(
                            "systemNotificationAll",
                            undefined,
                            (draft) => (draft.map(item => {
                                if (listIds.includes(item.id)) {
                                    return {
                                        ...item,
                                        read: true
                                    }
                                } else {
                                    return item
                                }
                            })),
                        ),
                    );
                } catch { }
            },
        })
    }),
    overrideExisting: false,
});

export const {
    useSystemNotificationAllQuery,
    useLazySystemNotificationAllQuery,
    useSystemNotificationOnReadMutation
} = systemNotificationsApi;