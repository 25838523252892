import { createSlice } from "@reduxjs/toolkit";
import { SliceAction } from "store/store";


const initialState: Record<string, {
  visibility: boolean,
  mode?: string
}> = {};

const modalStateSlice = createSlice({
  initialState,
  name: "modalState",
  reducers: {
    showModal: (state, action: SliceAction<{
      modalName: string,
      mode?: string
    }>) => {
      state[action.payload.modalName] = {
        visibility: true,
        mode: action.payload.mode
      }
    },
    hideModal: (state, action: SliceAction<string>) => {
      if (state[action.payload]) {
        state[action.payload].visibility = false;
      }
    },
    hideAllModal: () => {
      return {};
    }
  },
});

const { reducer, actions } = modalStateSlice;

export default reducer;

export const {
  showModal,
  hideModal,
  hideAllModal
} = actions;
