import { SelectableParameter } from "@interfaces/product";
import { ListVariationsItem } from "..";
import cn from "classnames";
import style from "./ListVariations.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SwitchButton } from "@ui";
import { setActivatedVariations } from "@global-state/pages/product/slice";
import { useContext, useEffect } from "react";
import { variationsContext } from "../../CoreVariations";

export function ListVariations({ data, mode }: { data: SelectableParameter[], mode: "create" | "edit" }) {
  const context = useContext(variationsContext);
  const activatedVariations = useAppSelector(state => state.productPage.activatedVariations);

  const dispatch = useAppDispatch();

  const listElement: JSX.Element[] = [];

  data.forEach((item, i) => {
    listElement.push(<ListVariationsItem key={item.id} data={item} />);
    if (i !== data.length - 1) {
      listElement.push(
        <div key={item.id + "divider_left"} className={style["divider"]}></div>,
      );
      listElement.push(
        <div
          key={item.id + "divider_right"}
          className={cn(style["divider"], "max-sm:hidden")}
        ></div>,
      );
    }
  });

  useEffect(() => {
    const localStorageVariations: Record<string, number[]> = JSON.parse(window.localStorage.getItem("productPage_active_groupProperty") || "null")?.variations

    if (localStorageVariations) {
      if (Object.keys(localStorageVariations).length) {
        dispatch(setActivatedVariations(true))
      }
    }
  }, []);

  return (
    <div className="flex flex-col gap-[10px]">
      {
        mode === "edit"
          ? <p className="text-[#838383] text-[13px]">Варіації:</p>
          : <SwitchButton
            label="Варіації:"
            checked={activatedVariations}
            onChange={() => {
              if (activatedVariations) {
                context?.clearSelections();

                dispatch(setActivatedVariations(false));
              } else {
                dispatch(setActivatedVariations(true));
              }
            }}
          />
      }
      {
        activatedVariations
          ? <div className={style["container"]}>
            <div className={style["gridTable"]}>{listElement}</div>
          </div>
          : null
      }
    </div>
  );
}
