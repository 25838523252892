import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { useEffect, useState } from "react";
import { PageButton } from "./components";
import { ArrowSvg } from ".";
import cn from "classnames";
import style from "./PaginationPagesButton.module.scss";

export function PaginationPagesButton({
  count,
  activePage,
  onClickPage,
  showFirstButton,
  showLastButton,
  fullWidth,
}: {
  count: number;
  activePage: number;
  onClickPage: (page: number) => void;
  showFirstButton?: boolean;
  showLastButton?: boolean;
  fullWidth?: boolean;
}) {
  const [pagesButton, setPagesButton] = useState<JSX.Element[]>([]);

  useEffect(() => {
    const element: JSX.Element[] = [];

    if (count <= 7) {
      for (let i = 0; i < count; i++) {
        element.push(
          <PageButton
            key={i}
            numberPage={i}
            activePage={activePage}
            onClick={onClickPage}
            className={
              activePage === count - 1 && i === count - 2
                ? "!flex"
                : activePage === 0 && i === 1
                  ? "!flex"
                  : ""
            }
          />,
        );
      }
    } else {
      if ([0, 1, 2, 3].includes(activePage)) {
        for (let i = 0; i < 5; i++) {
          element.push(
            <PageButton
              key={i}
              numberPage={i}
              activePage={activePage}
              onClick={onClickPage}
              className={activePage === 0 && i === 1 ? "!flex" : ""}
            />,
          );
        }
        element.push(
          <div key="treeDotsBack" className={style["treeDotsForward"]}>
            ...
          </div>,
        );
        element.push(
          <PageButton
            key={count - 1}
            numberPage={count - 1}
            activePage={activePage}
            onClick={onClickPage}
          />,
        );
      } else if (
        [count - 1, count - 2, count - 3, count - 4].includes(activePage)
      ) {
        element.push(
          <PageButton
            key={0}
            numberPage={0}
            activePage={activePage}
            onClick={onClickPage}
          />,
        );
        element.push(
          <div key="treeDotsForward" className={style["treeDotsForward"]}>
            ...
          </div>,
        );
        for (let i = count - 5; i < count; i++) {
          element.push(
            <PageButton
              key={i}
              numberPage={i}
              activePage={activePage}
              onClick={onClickPage}
              className={
                activePage === count - 1 && i === count - 2 ? "!flex" : ""
              }
            />,
          );
        }
      } else {
        element.push(
          <PageButton
            key={0}
            numberPage={0}
            activePage={activePage}
            onClick={onClickPage}
          />,
        );
        element.push(
          <div key={"treeDotsBack"} className={style["treeDotsForward"]}>
            ...
          </div>,
        );
        element.push(
          <PageButton
            key={activePage - 1}
            numberPage={activePage - 1}
            activePage={activePage}
            onClick={onClickPage}
          />,
        );
        element.push(
          <PageButton
            key={activePage}
            numberPage={activePage}
            activePage={activePage}
            onClick={onClickPage}
          />,
        );
        element.push(
          <PageButton
            key={activePage + 1}
            numberPage={activePage + 1}
            activePage={activePage}
            onClick={onClickPage}
          />,
        );
        element.push(
          <div key={"treeDotsForward"} className={style["treeDotsForward"]}>
            ...
          </div>,
        );
        element.push(
          <PageButton
            key={count - 1}
            numberPage={count - 1}
            activePage={activePage}
            onClick={onClickPage}
          />,
        );
      }
    }

    setPagesButton(element);
  }, [count, activePage]);

  return (
    <div
      className={cn(
        style["paginationPagesButton"],
        fullWidth && "justify-between",
      )}
    >
      {showFirstButton && (
        <button
          className="group flex h-[35px] w-[35px] items-center justify-center"
          disabled={activePage === 0}
          onClick={() => onClickPage(0)}
        >
          <BiFirstPage
            className={
              "h-[24px] w-[24px] fill-[#ababab]  transition-colors ease-linear " +
              (activePage === 0 ? "" : "group-hover:fill-[#40a9ff]")
            }
          />
        </button>
      )}
      <button
        className={style["oneStepButton"]}
        disabled={activePage === 0}
        onClick={() => onClickPage(activePage - 1)}
      >
        <ArrowSvg />
        <p>Попередня</p>
      </button>
      <div className="flex gap-[5px]">{pagesButton}</div>
      <button
        className={style["oneStepButton"]}
        disabled={activePage + 1 === count}
        onClick={() => onClickPage(activePage + 1)}
      >
        <p>Наступна</p>
        <ArrowSvg className="rotate-180" />
      </button>
      {showLastButton && (
        <button
          className="group flex h-[35px] w-[35px] items-center justify-center"
          disabled={activePage + 1 === count}
          onClick={() => onClickPage(count - 1)}
        >
          <BiLastPage
            className={
              "h-[24px] w-[24px] fill-[#ababab]  transition-colors ease-linear " +
              (activePage + 1 === count ? "" : "group-hover:fill-[#40a9ff]")
            }
          />
        </button>
      )}
    </div>
  );
}
