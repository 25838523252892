import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { accountLogOut, CRMLogOut } from "@global-state/global/slice";
import { showModal } from "@global-state/entity/modal-state/slice";

function baseQuery(tokenType: "access_token_crm" | "refresh_token_crm") {
  return fetchBaseQuery({
    baseUrl: (() => {
      const protocol = "https://";
      const subDomain = window.localStorage.getItem("subdomain");
      const suffix = subDomain === "account" ? "-api." : ".api."
      const mainDomain = window.location.host.includes(".com.ua") ? "levelup.com.ua" : "dev-levelup.win";
      const CRMType = subDomain === "account" ? "" : "/droppers";

      return protocol + subDomain + suffix + mainDomain + CRMType
    })(),
    prepareHeaders: (headers) => {
      const subDomain = window.localStorage.getItem("subdomain");

      const token = window.localStorage.getItem((subDomain === "account") ? tokenType.replace("_crm", "") : tokenType);
      if (token) {
        headers.set("authorization", token);
      }
      return headers;
    },
  });
}

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const subDomain = window.localStorage.getItem("subdomain");
  let result = await baseQuery("access_token_crm")(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery("refresh_token_crm")(
      {
        url: "/auth/refresh_token",
        method: "POST",
      },
      api,
      extraOptions,
    );

    if (refreshResult.data) {
      const { access_token } = refreshResult.data as { access_token: string };

      window.localStorage.setItem((subDomain === "account") ? "access_token" : "access_token_crm", access_token);

      result = await baseQuery("access_token_crm")(args, api, extraOptions);
    } else {
      if (subDomain === "account") {
        api.dispatch(accountLogOut());
      } else {
        api.dispatch(CRMLogOut());
      }
    }
  }
  if (result.error && result.error.status === 402) {
    api.dispatch(showModal({
      modalName: "CRMPaymentRequired",
    }));
  }
  return result;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Product", "ProductHoroshop", "ProductsProviders", "UnloadingList", "CharList", "HoroshopCategory", "Category"],
  endpoints: () => ({}),
});

export const logApi = createApi({
  reducerPath: "logApi",
  baseQuery: fetchBaseQuery({
    baseUrl: (() => {
      const protocol = "https://";
      const subDomain = process.env.NODE_ENV === "development"
        ? window.location.pathname.split("/")[1] || "account"
        : window.location.host.split(".")[0];
      const suffix = subDomain === "account" ? "-api." : ".api."
      const mainDomain = window.location.host.includes(".com.ua") ? "levelup.com.ua" : "dev-levelup.win";
      const CRMType = subDomain === "account" ? "" : "/droppers";

      return protocol + subDomain + suffix + mainDomain + CRMType
    })(),
  }),
  endpoints: () => ({}),
});
