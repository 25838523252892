import { UnloadingsContent, TopPanel } from "components/catalog/unloadings";
import style from "./UnloadingAllPage.module.scss";
import { DeleteLinkModal } from "@modals";
import { Helmet } from "react-helmet";

export function UnloadingAllPage() {
  return (
    <main className={style["layout"]}>
      <Helmet>
        <title>Вигрузки</title>
      </Helmet>
      <TopPanel />
      <UnloadingsContent />
      <DeleteLinkModal />
    </main>
  );
}
