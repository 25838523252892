import { BillingHistoryRecord } from "@interfaces/billing";
import { useBalanceHistoryQuery } from "@RTKQuery/balance/api";
import style from "./HistoryBillingBody.module.scss";
import { Spinner } from "@ui";

export function HistoryBillingBody({
  children,
}: {
  children: (data: BillingHistoryRecord) => JSX.Element;
}) {
  const { data, isFetching, isError, error } = useBalanceHistoryQuery();

  const reverseData: BillingHistoryRecord[] | undefined = JSON.parse(
    JSON.stringify(data || []),
  );

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else if (!data || data?.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Історія пуста.</p>
      </div>
    );
  } else {
    return <>{reverseData?.reverse().map((item) => children(item))}</>;
  }
}
