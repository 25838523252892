import { createSlice } from "@reduxjs/toolkit";

const initialState: {
    CRMAuth: boolean;
    accountAuth: boolean;
} = {
    CRMAuth: false,
    accountAuth: false,
};

const globalSlice = createSlice({
    initialState,
    name: "global",
    reducers: {
        CRMLogIn: (state) => {
            state.CRMAuth = true;
        },
        CRMLogOut: (state) => {
            state.CRMAuth = false;
        },
        accountLogIn: (state) => {
            state.accountAuth = true;
        },
        accountLogOut: (state) => {
            state.accountAuth = false;
        },
    },
});

const { reducer, actions } = globalSlice;

export default reducer;

export const {
    CRMLogIn,
    CRMLogOut,
    accountLogIn,
    accountLogOut,
} = actions;
