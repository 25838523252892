import React, { forwardRef, CSSProperties } from "react";
import cn from "classnames";

import styleModule from "./Action.module.scss";

export interface ActionProps extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties["cursor"];
}

export const Action = forwardRef<HTMLButtonElement, ActionProps>(
  ({ active, className, cursor, style, ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className={cn(styleModule["action"], className)}
        tabIndex={0}
        style={
          {
            ...style,
            cursor,
          } as CSSProperties
        }
      />
    );
  },
);
