import { useEffect, useState } from "react";
import cn from "classnames";
import style from "./HoroshopCategorySelect.module.scss";
import {
  useHoroshopCategoriesAllQuery,
  useHoroshopCategoryCreateMutation,
} from "@RTKQuery/horoshop-categories/api";
import { HoroshopCategory } from "@interfaces/unloading";
import { ItemHints, ListValues } from "./components";

export function HoroshopCategorySelect({
  listValue,
  setListValue,
}: {
  listValue: string[];
  setListValue: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const [showlistHints, setShowlistHints] = useState<boolean>(false);
  const [valueInput, setValueInput] = useState<string>("");

  const { data: listHints } = useHoroshopCategoriesAllQuery();

  const [createHoroshopCategory] = useHoroshopCategoryCreateMutation();

  useEffect(() => {
    function hideHandler(e: any) {
      if (showlistHints) {
        if (!e.target.closest("[data-nohide]")) {
          setShowlistHints(false);
        }
      }
    }

    document.body.addEventListener("click", hideHandler);

    return () => {
      document.body.removeEventListener("click", hideHandler);
    };
  }, [showlistHints]);

  return (
    <div className="relative w-full">
      <p className={style["label"]}>Категорія товару</p>
      <input
        data-nohide
        className={cn(style["input"], style["error_off"])}
        type="text"
        placeholder="Puma"
        value={valueInput}
        onChange={(e) => {
          setValueInput(e.target.value);
        }}
        onClick={() => {
          setShowlistHints(true);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (valueInput && !listValue.includes(valueInput)) {
              setValueInput("");
              setListValue((state) => [...state, valueInput]);
              if (
                !(
                  listHints &&
                  listHints.findIndex((item) => item.name === valueInput) >= 0
                )
              ) {
                createHoroshopCategory(valueInput);
              }
            } else {
              setValueInput("");
            }
          }
        }}
      />
      <p className={style["hintEnter"]}>Щоб додати категорію, нажмайте Enter</p>
      <ListValues
        listValue={listValue}
        onDelete={(curentItem) => {
          setListValue((state) => state.filter((item) => item !== curentItem));
        }}
      />
      {listHints && listHints?.length > 0 ? (
        <div
          data-nohide
          className={cn(style["listHints"], showlistHints && "!block")}
        >
          <div className={style["scrollContainer"]}>
            {generateListHints(
              listHints.filter((item) =>
                item.name
                  .toLocaleLowerCase()
                  .includes(valueInput.toLocaleLowerCase()),
              ),
              listValue,
              setListValue,
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function generateListHints(
  listHints: HoroshopCategory[],
  listValue: string[],
  setListValue: React.Dispatch<React.SetStateAction<string[]>>,
) {
  const list: JSX.Element[] = [];

  listHints.forEach((curentItem) => {
    list.push(
      <ItemHints
        key={curentItem.id}
        data={curentItem}
        listValue={listValue}
        setListValue={setListValue}
      />,
    );
    list.push(
      <div
        key={"divider" + curentItem.id}
        className={style["dividerHints"]}
      ></div>,
    );
  });

  return list;
}
