export function СonnectionSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="10"
      viewBox="0 0 18 13"
      fill="none"
    >
      <path
        d="M6.84913 2.92423C7.18319 2.47736 7.61791 2.11553 8.11802 1.86811C8.61812 1.62068 9.16953 1.49463 9.72749 1.50018H13.2919C14.2352 1.50018 15.14 1.87487 15.807 2.54182C16.4741 3.20878 16.8488 4.11337 16.8488 5.05659C16.8488 5.99981 16.4741 6.9044 15.807 7.57135C15.14 8.23831 14.2352 8.613 13.2919 8.613H9.72749C8.90872 8.61259 8.11516 8.32976 7.48075 7.81225C6.84633 7.29474 6.4099 6.57424 6.24512 5.77235"
        strokeWidth="1.26833"
        strokeMiterlimit="10"
      />
      <path
        d="M11.7551 7.22738C11.5903 6.42548 11.1539 5.70498 10.5194 5.18747C9.88502 4.66996 9.09147 4.38713 8.2727 4.38672H4.7083C3.76495 4.38672 2.86023 4.76141 2.19317 5.42837C1.52611 6.09533 1.15137 6.99991 1.15137 7.94313C1.15137 8.88635 1.52611 9.79094 2.19317 10.4579C2.86023 11.1249 3.76495 11.4995 4.7083 11.4995H8.2727C8.73913 11.5004 9.20115 11.4093 9.63234 11.2314C10.0635 11.0536 10.4554 10.7925 10.7857 10.4632C10.9071 10.3447 11.017 10.2149 11.1138 10.0755"
        strokeWidth="1.26833"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
