import style from "./InDevelopment.module.scss";

export function InDevelopment({ resourceName }: { resourceName: string }) {
  return (
    <div className={style["container"]}>
      <p className={style["namePage"]}>{resourceName}</p>
      <p className={style["message"]}>В розробці</p>
    </div>
  );
}
