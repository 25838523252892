import { forwardRef } from "react";
import { Action, ActionProps } from "../Action";

export const Handle = forwardRef<HTMLButtonElement, ActionProps>(
  (props, ref) => {
    return (
      <Action
        ref={ref}
        cursor="grab"
        data-cypress="draggable-handle"
        {...props}
      >
        <div className="group flex h-full w-full items-center justify-center">
          <svg
            className="stroke-[#B1B1B1] transition-colors ease-linear group-hover:stroke-black dark:group-hover:stroke-white"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
          >
            <path
              d="M7 13V9M7 13L9 11M7 13L5 11M7 5V1M7 1L5 3M7 1L9 3M13 7H9M13 7L11 5M13 7L11 9M5 7H1M1 7L3 9M1 7L3 5"
              strokeWidth="1.3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Action>
    );
  },
);
