import { ProductStock } from "@interfaces/stock";
import { useProductStockOneQuery } from "@RTKQuery/stock/api";
import { useParams } from "react-router-dom";

export function AllProductsBody({
  children,
}: {
  children: (data: ProductStock) => JSX.Element;
}) {
  const { productId } = useParams();

  const { data } = useProductStockOneQuery(Number(productId));

  if (data) {
    return (
      <>
        {children(data.product)}
        {data.children.map((item) => children(item))}
      </>
    );
  } else {
    return null;
  }
}
