import { UpdatePropertyValueForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import {
  referenceBook_deleteValues_clearData,
  referenceBook_deleteValues_deleteData,
  referenceBook_editValues_clearData,
  referenceBook_editValues_deleteData,
} from "@global-state/pages/productProperties/slice";
import { useProductPropertyValuesUpdateMutation } from "@RTKQuery/product-properties/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "UpdatePropertyValueModal";

export function UpdatePropertyValueModal() {
  const [_, { isSuccess, reset }] = useProductPropertyValuesUpdateMutation({
    fixedCacheKey: "edit-book-value",
  });

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const listItems = useAppSelector(
    (state) => state.productPropertiesPage.edit_values,
  );
  let singleId: number =
    listItems.find((item) => item.tag === "single")?.id || -1;

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        if (activeModal?.mode === "single") {
          dispatch(
            referenceBook_deleteValues_deleteData({
              id: singleId,
              singleOnly: false,
            }),
          );
          dispatch(
            referenceBook_editValues_deleteData({
              id: singleId,
              singleOnly: false,
            }),
          );
        } else {
          dispatch(referenceBook_deleteValues_clearData());
          dispatch(referenceBook_editValues_clearData());
        }
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess && activeModal?.mode === "single") {
      dispatch(
        referenceBook_editValues_deleteData({
          id: singleId,
          singleOnly: true,
        }),
      );
    }
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Відредагувати значення"
    >
      <UpdatePropertyValueForm />
    </CRMModalLayout>
  );
}
