import style from "./MenuPropertiesGroupsList.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { Spinner } from "@ui";
import { useProductPropertiesGroupTreeAllQuery } from "@RTKQuery/product-properties/api";
import { ProductPropertiesGroup } from "@interfaces/product-characteristics";

export function MenuPropertyGroupsList({
  children,
}: {
  children: (data: ProductPropertiesGroup) => JSX.Element;
}) {
  const { group } = useParams();
  const { data, isFetching, isError, error } = useProductPropertiesGroupTreeAllQuery();

  const navigate = useNavigate();
  const location = useLocation();

  const crm =
    process.env.NODE_ENV === "development"
      ? `/${window.localStorage.getItem("subdomain")}`
      : "";

  useEffect(() => {
    if (data && data.length > 0) {
      const listIdGroup = data.reduce<number[]>(
        (arr, group) => [...arr, group.id],
        [],
      );

      if (!listIdGroup.includes(+(group || "-1"))) {
        navigate(crm + "/product-properties/" + data[0].id, {
          replace: true,
        });
      }
    }
    if (data && data.length === 0) {
      if (
        location.pathname !== crm + "/product-properties/" &&
        location.pathname !== crm + "/product-properties"
      ) {
        navigate(crm + "/product-properties");
      }
    }
  }, [location, data]);

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else if (!data || data.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодної групи.</p>
      </div>
    );
  } else {
    return (
      <nav className={style["menu"]}>{data.map((item) => children(item))}</nav>
    );
  }
}
