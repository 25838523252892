import {
  AddCategoryModal,
  DeleteCategoryModal,
  DeleteProductModal,
  EditCategoryModal,
  ImportProductsModal,
} from "@modals";
import {
  MultipleActions,
  Pagination,
  TableProduct,
  TopPanel,
} from "../../../../components/catalog/products";
import { Helmet } from "react-helmet";
import style from "./ProductAllPage.module.scss";
import { MenuCategories } from "components/common/MenuCategories";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { products_clearAllData } from "@global-state/pages/products/slice";

export function ProductAllPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(products_clearAllData());
    };
  }, []);

  return (
    <main className={style["layout"]}>
      <Helmet>
        <title>Каталог товарів</title>
      </Helmet>
      <TopPanel />
      <div className={style["flexContainer"]}>
        <div className={style["categories"]}>
          <MenuCategories pageName="catalog" />
        </div>
        <div className={style["tableBox"]}>
          <TableProduct />
          <MultipleActions />
          <Pagination />
        </div>
      </div>
      <>
        <AddCategoryModal />
        <DeleteCategoryModal />
        <EditCategoryModal />
        <DeleteProductModal />
        <ImportProductsModal />
      </>
    </main>
  );
}
