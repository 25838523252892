export function HideSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <rect
        x="1"
        y="10"
        width="14"
        height="2"
        rx="1"
        transform="rotate(-45 1 10)"
        fill="white"
      />
      <rect
        x="2"
        width="14"
        height="2"
        rx="1"
        transform="rotate(45 2 0)"
        fill="white"
      />
    </svg>
  );
}
