import { useAppSelector } from "store/hooks";
import { List } from "./components";

import style from "./TenantsDeleted.module.scss";
import { useAccountQuery } from "@RTKQuery/account/api";

export function TenantsDeleted() {
  const { data: tenantsDeleted } = useAccountQuery(void 0, {
    selectFromResult: ({ data, ...args }) => {
      return {
        data: data?.deleted_tenants || [],
        ...args,
      };
    },
  });

  if (tenantsDeleted.length !== 0) {
    return (
      <div className={style["container"]}>
        <p className={style["title"]}>CRM, заплановані до видалення</p>
        <List />
      </div>
    );
  } else {
    return null;
  }
}
