import { Props } from "../../../Button";
import { DeleteIcon, EditIcon, SmallAddIcon } from "../../icons";
import cn from "classnames";

import style from "./Small.module.scss";

export function Small({
  onClick,
  type,
  className,
  disabled,
  icon,
  customStyle,
  ...props
}: Props) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={cn(
        style["button"],
        type === "add" && style["button_add"],
        type === "delete" && style["button_delete"],
        type === "edit" && style["button_edit"],
        disabled && style["button_disabled"],
        className,
        customStyle?.button,
      )}
      onClick={onClick}
      {...props}
    >
      {icon ? (
        icon(
          cn(
            "!h-[12px] !w-[12px] max-sm:!h-[9px] max-sm:!w-[9px]",
            customStyle?.icon,
          ),
          style["svg_fill"],
        )
      ) : (
        <>
          {type === "add" ? <SmallAddIcon /> : null}
          {type === "delete" ? <DeleteIcon /> : null}
          {type === "edit" ? <EditIcon /> : null}
        </>
      )}
    </button>
  );
}
