import { useEffect, useState } from "react";
import cn from "classnames";

import style from "./PaginationLimitSelect.module.scss";
import { SpoilerButton } from "@ui";

export function PaginationLimitSelect({
  limitList,
  onClick,
  isActive,
}: {
  limitList: number[];
  onClick: (limit: number) => void;
  isActive: (limit: number) => boolean;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    function eventAction(e: any) {
      if (!e.target.closest("[data-select]")) {
        setIsOpen(false);
      }
    }

    document.body.addEventListener("click", eventAction);

    return () => {
      document.body.removeEventListener("click", eventAction);
    };
  }, []);

  return (
    <div className={style["selectContainer"]}>
      <p className={style["label"]}>Показати</p>
      <div className="relative">
        <div
          data-select
          className={cn(
            style["currentValue"],
            isOpen && style["currentValue_active"],
          )}
          onClick={() => setIsOpen((state) => !state)}
        >
          <p className={style["value"]}>
            {limitList.find((limit) => isActive(limit))}
          </p>
          <SpoilerButton isOpen={isOpen} className="p-0" />
        </div>
        <div className={cn(style["listValues"], isOpen ? "flex" : "hidden")}>
          {limitList.map((limit) => (
            <div
              key={limit}
              className={cn(
                style["value"],
                isActive(limit) && style["value_active"],
              )}
              onClick={() => onClick(limit)}
            >
              {limit}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
