import { useParams } from "react-router-dom";
import { Spinner } from "@ui";
import style from "./TableValuesBody.module.scss";
import { ProductPropertyValue } from "@interfaces/product-characteristics";
import { useProductPropertyValuesAllQuery } from "@RTKQuery/product-properties/api";

export function TableValuesBody({
  children,
}: {
  children: (data: ProductPropertyValue) => JSX.Element;
}) {
  const { propertyId } = useParams();
  const { data, isFetching, isError, error } = useProductPropertyValuesAllQuery(
    Number(propertyId || "-1"),
  );

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else if (!data || data.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодного значення.</p>
      </div>
    );
  } else {
    return <>{data?.map((item) => children(item))}</>;
  }
}
