import { SelectableParameter } from "@interfaces/product";
import { ListVariationsValuesItem } from "..";
import style from "./ListVariationsValues.module.scss";
import { MultipleSelect } from "@ui";
import { useContext } from "react";
import { variationsContext } from "../../CoreVariations";

export function ListVariationsValues({
  data,
  parent,
}: {
  data: SelectableParameter["values"];
  parent: SelectableParameter;
}) {
  const context = useContext(variationsContext);

  if (data.length > 10) {
    return (
      <MultipleSelect<SelectableParameter["values"][0]>
        allDataList={data}
        keyForId="id"
        keyForLabel="name"
        selectDataList={data.filter((item) => item.selected)}
        onChange={(listItem, item, select) => {
          context?.switchChekedParameterValue(parent.id, item.id, select);
        }}
      />
    );
  } else {
    return (
      <div className={style["list"]}>
        {data.map((item) => (
          <ListVariationsValuesItem
            key={item.id}
            data={item}
            parent={parent}
          />
        ))}
      </div>
    );
  }
}
