import { useAppDispatch, useAppSelector } from "store/hooks";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { useNavigate } from "react-router-dom";

const MODAL_NAME = "ImportSendSuccessModal";

export function ImportSendSuccessModal() {
  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function hiddenModal() {
    navigate("..", {
      relative: "path",
    });
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Дані успішно відправлено!"
      description={
        "Після завершення імпорту вам на пошту прийде лист з результатом."
      }
    >
      <></>
    </CRMModalLayout>
  );
}
