import { InputNoFormik, Radio, SwitchButton } from "@ui";
import style from "./QuickSettings.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  selectFileFormat,
  setUnloadingName,
  switchUseMaxDropPrice,
  switchUseRetailPrice,
} from "@global-state/pages/unloading/slice";
import { FileFormat } from "@interfaces/unloading";

export function QuickSettings() {
  const { name, use_max_drop_price, use_retail_price, file_format } =
    useAppSelector((state) => state.unloadingPage.unloadingInfoObject);

  const dispatch = useAppDispatch();

  return (
    <div className={style["container"]}>
      <div className={style["name"]}>
        <InputNoFormik
          label="Назва вигрузки"
          defaultValue={name}
          setValue={(newValue) => {
            dispatch(setUnloadingName(newValue));
          }}
        />
      </div>
      <div className={style["switch"]}>
        <p className={style["label"]}>
          Брати максимальну ціну <br /> постачальника
        </p>
        <SwitchButton
          checked={Boolean(use_max_drop_price)}
          onChange={() => {
            dispatch(switchUseMaxDropPrice());
          }}
        />
      </div>
      <div className={style["switch"]}>
        <p className={style["label"]}>Роздрібна ціна</p>
        <SwitchButton
          checked={Boolean(use_retail_price)}
          onChange={() => {
            dispatch(switchUseRetailPrice());
          }}
        />
      </div>
      <p className={style["labelGroup"]}>Формат файлу</p>
      <div className={style["radioGroup"]}>
        <Radio
          label="csv"
          checked={file_format === FileFormat.CSV}
          onChange={() => {
            dispatch(selectFileFormat(FileFormat.CSV));
          }}
        />
        <Radio
          label="xlsx"
          checked={file_format === FileFormat.XLSX}
          onChange={() => {
            dispatch(selectFileFormat(FileFormat.XLSX));
          }}
        />
        <Radio
          label="xml"
          checked={file_format === FileFormat.XML}
          onChange={() => {
            dispatch(selectFileFormat(FileFormat.XML));
          }}
        />
        <Radio
          label="yml"
          checked={file_format === FileFormat.YML}
          onChange={() => {
            dispatch(selectFileFormat(FileFormat.YML));
          }}
        />
      </div>
    </div>
  );
}
