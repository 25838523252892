import { Functionalities } from "@interfaces/functionalities";
import { createSlice } from "@reduxjs/toolkit";
import { SliceAction } from "store/store";


const initialState: {
    functionalityData: Functionalities | null;
} = {
    functionalityData: null
};

const settingsAdditionalServicesSlice = createSlice({
    initialState,
    name: "settingsAdditionalServicesPage",
    reducers: {
        setFunctionalityData: (state, action: SliceAction<Functionalities>) => {
            state.functionalityData = action.payload;
        },
        clearFunctionalityData: (state) => {
            state.functionalityData = null;
        },
        clearAllSettingsAdditionalServicesData: (state) => {
            state.functionalityData = null;
        },
    },
});

const { reducer, actions } = settingsAdditionalServicesSlice;

export default reducer;

export const {
    setFunctionalityData,
    clearFunctionalityData,
    clearAllSettingsAdditionalServicesData,
} = actions;
