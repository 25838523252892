import { Pagination, TableProduct, TopPanel } from "components/catalog/stocks";
import style from "./StockAllPage.module.scss";
import { MenuCategories } from "components/common/MenuCategories";
import { Helmet } from "react-helmet";

export function StockAllPage() {
  return (
    <main className={style["layout"]}>
      <Helmet>
        <title>Наявність товарів</title>
      </Helmet>
      <TopPanel />
      <div className={style["flexContainer"]}>
        <div className={style["categories"]}>
          <MenuCategories
            pageName="stock"
            addRootCategoryBtn={false}
            addCategoryBtn={false}
            editCategoryBtn={false}
            removeCategoryBtn={false}
          />
        </div>
        <div className={style["tableBox"]}>
          <TableProduct />
          <Pagination />
        </div>
      </div>
    </main>
  );
}
