import { useAppSelector } from "store/hooks";
import {
  MultipleActions,
  ProductVariationsList,
  ProductVariationsWrapper,
} from "./components";

export function ProductVariations() {
  const activatedVariations = useAppSelector(state => state.productPage.activatedVariations);

  if (activatedVariations) {
    return (
      <ProductVariationsWrapper>
        <>
          <ProductVariationsList />
          <MultipleActions />
        </>
      </ProductVariationsWrapper>
    );
  } else {
    return null
  }
}
