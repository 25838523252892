import { SortButton } from "./components";
import style from "./TableProductsProvidersHead.module.scss";
import cn from "classnames";

export function TableProductsProvidersHead({
  sortBy,
  setSortBy,
}: {
  sortBy: string;
  setSortBy: React.Dispatch<
    React.SetStateAction<
      "provider/asc" | "provider/desc" | "name/asc" | "name/desc" | ""
    >
  >;
}) {
  return (
    <div className={style["head"]}>
      <div className={cn(style["headColumn"], "justify-self-center")}>Фото</div>
      <div className={style["headColumn"]}>
        <button
          className={style["sortButtonContainer"]}
          onClick={() => {
            setSortBy((state) => {
              if (state === "provider/asc") {
                return "provider/desc";
              } else if (state === "provider/desc") {
                return "";
              } else {
                return "provider/asc";
              }
            });
          }}
        >
          <p>Постачальник</p>
          <SortButton sort={sortBy} nameCol={"provider"} />
        </button>
      </div>
      <div className={style["headColumn"]}>Артикул</div>
      <div className={style["headColumn"]}>
        <button
          className={style["sortButtonContainer"]}
          onClick={() => {
            setSortBy((state) => {
              if (state === "name/asc") {
                return "name/desc";
              } else if (state === "name/desc") {
                return "";
              } else {
                return "name/asc";
              }
            });
          }}
        >
          <p>Назва</p>
          <SortButton sort={sortBy} nameCol={"name"} />
        </button>
      </div>
      <div className={style["headColumn"]}>Ціна</div>
    </div>
  );
}
