import { createContext, useEffect, useState } from "react";
import { ListVariations } from "./components";
import { SelectableParameter } from "@interfaces/product";
import { useAppDispatch } from "store/hooks";
import { addErrorVariations, removeErrorVariations, setCombinationsSelected, setErrorVariations } from "@global-state/pages/product/slice";

export const variationsContext = createContext<{
  data: SelectableParameter[];
  switchChekedParameter: (
    id: SelectableParameter["id"],
    status: boolean,
  ) => void;
  switchChekedParameterValue: (
    id: SelectableParameter["id"],
    idValue: SelectableParameter["values"][0]["id"],
    status: boolean,
  ) => void;
  clearSelections: () => void
} | null>(null);

export function CoreVariations({ data, mode }: { data: SelectableParameter[], mode: "create" | "edit" }) {
  const [parameters, setParameters] = useState<SelectableParameter[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const localStorageVariations: Record<string, number[]> = JSON.parse(window.localStorage.getItem("productPage_active_groupProperty") || "null")?.variations

    const generatedData = localStorageVariations && (mode === "create") ? data.map(char => ({
      ...char,
      selected: char.values.length === (localStorageVariations[char.id]?.length || -1),
      values: char.values.map(value => ({
        ...value,
        selected: localStorageVariations[char.id]?.includes(value.id)
      }))
    })) : data

    const reqError: number[] = [];

    for (let i = 0; i < generatedData.length; i++) {
      if (generatedData[i].required && !generatedData[i].values.some(value => value.selected)) {
        reqError.push(generatedData[i].id)
      }
    }

    dispatch(setErrorVariations(reqError))

    setParameters(generatedData)
  }, [data]);

  useEffect(() => {
    if (mode === "create") {
      const propertiesSelect: Record<string, number[]> = {};
      parameters.forEach((char) => {
        const valuesSelect: number[] = [];

        char.values.forEach((value) => {
          if (value.selected) {
            valuesSelect.push(value.id);
          }
        });

        if (valuesSelect.length > 0) {
          propertiesSelect[String(char.id)] = valuesSelect;
        }
      });

      const localStorageGroupProperty = JSON.parse(window.localStorage.getItem("productPage_active_groupProperty") || "null")
      window.localStorage.setItem("productPage_active_groupProperty", JSON.stringify({
        ...localStorageGroupProperty,
        variations: propertiesSelect
      }))
    }

    dispatch(
      setCombinationsSelected(
        parameters
          .map((obj) => ({
            name: obj.name,
            values: obj.values
              .filter((value) => value.selected)
              .map((value) => value.name),
          }))
          .filter((obj) => obj.values.length > 0),
      ),
    );
  }, [parameters]);

  return (
    <variationsContext.Provider
      value={{
        data: parameters,
        switchChekedParameter(id, status) {
          let isRequired: boolean = false;

          setParameters((state) => {
            const newObj = state.map((item) => {
              if (item.id === id) {
                isRequired = item.required;

                return {
                  ...item,
                  selected: status,
                  values: item.values.map((value, index) => ({
                    ...value,
                    selected: status,
                  })),
                };
              } else {
                return item;
              }
            })

            if (isRequired) {
              if (status) {
                dispatch(removeErrorVariations(id))
              } else {
                dispatch(addErrorVariations(id))
              }
            }

            return newObj;
          });
        },
        switchChekedParameterValue(id, idValue, status) {
          let checkedValue = 0;
          let isRequired: boolean = false;

          setParameters((state) => {
            const newObj = state.map((item) => {
              if (item.id === id) {
                isRequired = item.required;

                return {
                  ...item,
                  values: item.values.map((value) => {
                    if (value.selected) {
                      checkedValue++;
                    }

                    if (value.id === idValue) {
                      if (status) {
                        checkedValue++;
                      } else {
                        checkedValue--;
                      }

                      return {
                        ...value,
                        selected: status,
                      };
                    } else {
                      if (item.multi_selection) {
                        return value;
                      } else {
                        return {
                          ...value,
                          selected: false,
                        };
                      }
                    }
                  }),
                  selected: item.values.length === checkedValue,
                };
              } else {
                return item;
              }
            })

            if (isRequired) {
              if (checkedValue >= 1) {
                dispatch(removeErrorVariations(id))
              } else {
                dispatch(addErrorVariations(id))
              }
            }

            return newObj;
          });
        },
        clearSelections() {
          setParameters(state => state.map(item => {
            if (item.required) {
              dispatch(addErrorVariations(item.id))
            }

            return {
              ...item,
              selected: false,
              values: item.values.map(value => ({
                ...value,
                selected: false
              }))
            }
          }))
        }
      }}
    >
      <ListVariations data={parameters} mode={mode} />
    </variationsContext.Provider>
  );
}
