import { SelectableParameter } from "@interfaces/product";
import { ListCharacteristicsItem } from "..";
import cn from "classnames";
import style from "./ListCharacteristics.module.scss";
import { SwitchButton } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setActivatedChars } from "@global-state/pages/product/slice";
import { useContext, useEffect } from "react";
import { characteristicsContext } from "../../CoreChars";

export function ListCharacteristics({ data, mode }: { data: SelectableParameter[], mode: "create" | "edit" }) {
  const context = useContext(characteristicsContext);
  const activatedChars = useAppSelector(state => state.productPage.activatedChars);

  const dispatch = useAppDispatch();

  const listElement: JSX.Element[] = [];

  data.forEach((item, i) => {
    listElement.push(<ListCharacteristicsItem key={item.id} data={item} />);
    if (i !== data.length - 1) {
      listElement.push(
        <div key={item.id + "divider_left"} className={style["divider"]}></div>,
      );
      listElement.push(
        <div
          key={item.id + "divider_right"}
          className={cn(style["divider"], "max-sm:hidden")}
        ></div>,
      );
    }
  });

  useEffect(() => {
    const localStorageChars: Record<string, number[]> = JSON.parse(window.localStorage.getItem("productPage_active_groupProperty") || "null")?.chars

    if (localStorageChars) {
      if (Object.keys(localStorageChars).length) {
        dispatch(setActivatedChars(true))
      }
    }
  }, []);

  return (
    <div className="flex flex-col gap-[10px]">
      {
        mode === "edit"
          ? <p className="text-[#838383] text-[13px]">Характеристики:</p>
          : <SwitchButton
            label="Характеристики:"
            checked={activatedChars}
            onChange={() => {
              if (activatedChars) {
                context?.clearSelections();
                
                dispatch(setActivatedChars(false));
              } else {
                dispatch(setActivatedChars(true))
              }
            }}
          />
      }
      {
        activatedChars
          ? <div className={style["container"]}>
            <div className={style["gridTable"]}>{listElement}</div>
          </div>
          : null
      }
    </div>
  );
}
