import style from "./PropertyButtons.module.scss";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";

import { showModal } from "@global-state/entity/modal-state/slice";
import {
  referenceBook_deleteProperties_addData,
  referenceBook_editProperty_setData,
} from "@global-state/pages/productProperties/slice";
import { ProductPropertiesGroup, ProductProperty } from "@interfaces/product-characteristics";

export function PropertyButtons({
  book,
  group,
}: {
  book: ProductProperty;
  group: ProductPropertiesGroup;
}) {
  const dispatch = useAppDispatch();

  return (
    <div className={style["buttons"]}>
      <Button
        type="edit"
        onClick={() => {
          dispatch(
            referenceBook_editProperty_setData({
              id: book.id,
              name: book.name,
              group_id: group.id,
              multi_selection: book.multi_selection,
              required: book.required,
              type: book.type
            }),
          );
          dispatch(
            showModal({
              modalName: "UpdatePropertyModal",
              mode: "single",
            }),
          );
        }}
      />
      <Button
        type="delete"
        onClick={() => {
          dispatch(
            referenceBook_deleteProperties_addData([
              {
                id: book.id,
                tag: "single",
              },
            ]),
          );
          dispatch(
            showModal({
              modalName: "DeletePropertyModal",
              mode: "single",
            }),
          );
        }}
      />
    </div>
  );
}
