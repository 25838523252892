import { Link } from "react-router-dom";
import { PropertyButtons, PropertyCheckbox } from "./components";
import style from "./TablePropertiesItem.module.scss";
import { ProductPropertiesGroup, ProductProperty } from "@interfaces/product-characteristics";

export function TablePropertiesItem({
  book,
  group,
}: {
  book: ProductProperty;
  group: ProductPropertiesGroup;
}) {
  const crm = window.localStorage.getItem("subdomain");

  return (
    <div className={style["item"]}>
      <PropertyCheckbox book={book} />
      <Link
        to={
          process.env.NODE_ENV === "development"
            ? `/${crm}/product-properties/${group.id}/property/${book.id}`
            : `/product-properties/${group.id}/property/${book.id}`
        }
        
        className={style["link"]}
      >
        <p className="truncate" title={book.name} >{book.name}</p>
        <p className="truncate">{book.type === "characteristic" ? "Характеристика" : "Варіація"}</p>
      </Link>
      <PropertyButtons book={book} group={group} />
    </div>
  );
}
