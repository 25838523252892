import style from "./TableProductItem.module.scss";

export function TableProductItem({
  data,
  colCount,
}: {
  data: (string | null)[];
  colCount: number;
}) {
  const cols: JSX.Element[] = [];

  for (let i = 1; i < colCount; i++) {
    cols.push(
      <div key={i} className={style["bodyColumn"]} title={data[i] || ""}>
        <p className="truncate">{data[i] || ""}</p>
      </div>,
    );
  }

  return <div className={style["item"]}>{cols}</div>;
}
