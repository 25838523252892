import { Form, Formik } from "formik";
import * as Yup from "yup";
import style from "./ResetPasswordForm.module.scss";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Input, PasswordInputProps, SubmitButton } from "@ui";
import { useResetPasswordMutation } from "@RTKQuery/account/api";

export function ResetPasswordForm() {
  const [
    resetPassword,
    { isUninitialized, isSuccess, isLoading, isError, error, reset },
  ] = useResetPasswordMutation();

  const { token } = useParams();

  const passwordRef = useRef<string>("");

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        reset();
      }, 1700);
    }
  }, [isError]);

  return (
    <Formik
      initialValues={{
        password: "",
        repeatPassword: "",
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required("Пароль - обов'язкове поле"),
        repeatPassword: Yup.string()
          .test(
            "is-equal",
            "Паролі не співпадають",
            (value, context) => value === passwordRef.current,
          )
          .required("Підтвердження нового паролю - обов'язкове поле"),
      })}
      onSubmit={(values) => {
        resetPassword({
          password: values.password.trim(),
          token: token || "",
        });
      }}
    >
      <Form>
        <div className={style["inputsBox"]}>
          <Input<PasswordInputProps>
            formikName="password"
            label="Новий пароль"
            inputType="password"
            passwordRef={passwordRef}
          />
          <Input<PasswordInputProps>
            formikName="repeatPassword"
            label="Підтвердження нового паролю"
            inputType="password"
          />
        </div>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="submit"
            disabled={isLoading}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Зберегти"
            successText="Успішно збережено"
            errorText="Не вдалось зберегти"
            errorMesage={
              error
                ? (error as unknown as { data: { error: string } }).data?.error
                : ""
            }
          />
        </div>
      </Form>
    </Formik>
  );
}
