import { useEffect, useRef } from "react";
import style from "./DeleteProductModal.module.scss";
import { useProductDeleteMutation } from "@RTKQuery/products/api";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { Button, SubmitButton } from "@ui";
import { hideModal } from "@global-state/entity/modal-state/slice";
import {
  product_delete_clearData,
  product_delete_deleteData,
} from "@global-state/pages/products/slice";

const MODAL_NAME = "DeleteProductModal";

export function DeleteProductModal() {
  const [
    deleteProducts,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useProductDeleteMutation();

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const listItems = useAppSelector(
    (state) => state.productsPage.product.delete,
  );
  let listIds: number[] = [];

  if (activeModal?.mode === "single") {
    listIds = [listItems.find((item) => item.tag === "single")?.id || -1];
  } else {
    listIds = Array.from(new Set(listItems.map((item) => item.id)));
  }

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        if (activeModal?.mode === "single") {
          dispatch(
            product_delete_deleteData({
              id: listIds[0],
              singleOnly: false,
            }),
          );
        } else {
          dispatch(product_delete_clearData());
        }
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess && activeModal?.mode === "single") {
      dispatch(
        product_delete_deleteData({
          id: listIds[0],
          singleOnly: true,
        }),
      );
    }
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Ви впевнені, що хочете видалити вибрані товари?"
    >
      <>
        <div className={style["buttons"]}>
          <div className="w-[50%]">
            <Button
              text="Скасувати"
              variant="outlined"
              size="big"
              onClick={() => {
                hiddenModal();
              }}
            />
          </div>
          <div className="w-[50%]">
            <SubmitButton
              type="submit"
              disabled={isLoading}
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              uninitializedText="Підтвердити"
              successText="Успішно видалено"
              errorText="Не вдалось видалити"
              errorMesage={""}
              onClick={() => {
                deleteProducts(listIds);
              }}
            />
          </div>
        </div>
        <p className={style["errorMessage"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : ""}
        </p>
      </>
    </CRMModalLayout>
  );
}
