import { redirect } from "react-router-dom";
import { AccountDashboardPage, AccountSettingsPage } from "../../pages";
import { AccountMainLayout } from "../../pages/layout/account";

const rootPath = process.env.NODE_ENV === "development" ? "/account" : "";

const routes = [
  {
    path: "dashboard",
    element: <AccountDashboardPage />,
  },
  {
    path: "settings",
    element: <AccountSettingsPage />,
  },
];

export const accountMainRouterConfig = [
  {
    path: "*",
    loader: ({ request }: { request: any }) => {
      return redirect(rootPath + "/dashboard");
    },
  },
  {
    path: process.env.NODE_ENV === "development" ? "account" : "/",
    element: <AccountMainLayout />,
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      if (pathname === rootPath || pathname === rootPath + "/") {
        return redirect(rootPath + "/dashboard");
      }

      return null;
    },
    children: routes,
  },
];
