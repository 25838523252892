import { useEffect, useState } from "react";
import cn from "classnames";
import style from "./MultipleSelect.module.scss";
import { ItemHints, ListSelectedValues } from "./components";

export function MultipleSelect<T>({
  allDataList,
  selectDataList,
  keyForLabel,
  keyForId,
  onChange,
  inputLabel,
}: {
  allDataList: T[];
  selectDataList: T[];
  keyForLabel: keyof T;
  keyForId: keyof T;
  onChange: (listItem: T[], curentItem: T, select: boolean) => void;
  inputLabel?: string;
}) {
  const [showlistHints, setShowlistHints] = useState<boolean>(false);
  const [valueInput, setValueInput] = useState<string>("");

  useEffect(() => {
    function hideHandler(e: any) {
      if (showlistHints) {
        if (!e.target.closest("[data-nohide]")) {
          setShowlistHints(false);
        }
      }
    }

    document.body.addEventListener("click", hideHandler);

    return () => {
      document.body.removeEventListener("click", hideHandler);
    };
  }, [showlistHints]);

  return (
    <div className="relative w-full">
      <p className={style["label"]}>{inputLabel}</p>
      <input
        data-nohide
        className={cn(style["input"], style["error_off"])}
        type="text"
        value={valueInput}
        onChange={(e) => {
          setValueInput(e.target.value);
        }}
        onClick={() => {
          setShowlistHints(true);
        }}
      />
      <ListSelectedValues<T>
        keyForLabel={keyForLabel}
        listValue={selectDataList}
        onDelete={(curentItem) => {
          onChange(
            selectDataList.filter(
              (item) => item[keyForId] !== curentItem[keyForId],
            ),
            curentItem,
            false,
          );
        }}
      />
      {allDataList && allDataList?.length > 0 ? (
        <div
          data-nohide
          className={cn(style["listHints"], showlistHints && "!block")}
        >
          <div className={style["scrollContainer"]}>
            {generateListHints<T>(
              allDataList.filter((item) =>
                String(item[keyForLabel])
                  .toLocaleLowerCase()
                  .includes(valueInput.toLocaleLowerCase()),
              ),
              selectDataList,
              keyForLabel,
              keyForId,
              onChange,
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function generateListHints<T>(
  allDataList: T[],
  selectDataList: T[],
  keyForLabel: keyof T,
  keyForId: keyof T,
  onChange: (listItem: T[], curentItem: T, select: boolean) => void,
) {
  const list: JSX.Element[] = [];

  allDataList.forEach((curentItem) => {
    list.push(
      <ItemHints<T>
        key={Number(curentItem[keyForId])}
        dataItem={curentItem}
        selectDataList={selectDataList}
        keyForId={keyForId}
        keyForLabel={keyForLabel}
        onSelect={(item, select) => {
          if (select) {
            onChange([...selectDataList, item], item, select);
          } else {
            onChange(
              selectDataList.filter(
                (item) => item[keyForId] !== curentItem[keyForId],
              ),
              item,
              select,
            );
          }
        }}
      />,
    );
    list.push(
      <div
        key={"divider" + Number(curentItem[keyForId])}
        className={style["dividerHints"]}
      ></div>,
    );
  });

  return list;
}
