import { EditCategoryForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { useCategoryUpdateMutation } from "@RTKQuery/categories/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "EditCategoryModal";

export function EditCategoryModal() {
  const [_, { isSuccess, reset }] = useCategoryUpdateMutation({
    fixedCacheKey: "edit-category",
  });

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Відредагувати категорію"
    >
      <EditCategoryForm />
    </CRMModalLayout>
  );
}
