import { Item } from "./components";
import style from "./List.module.scss";
import { useAccountQuery } from "@RTKQuery/account/api";

export function List() {
  const { data: tenantsDeleted } = useAccountQuery(void 0, {
    selectFromResult: ({ data, ...args }) => {
      return {
        data: data?.deleted_tenants || [],
        ...args,
      };
    },
  });

  return (
    <table className={style["table"]}>
      <thead className={style["thead"]}>
        <tr className={style["row"]}>
          <th className={style["col"]}>Назва</th>
          <th className={style["col"]}></th>
        </tr>
      </thead>
      <tbody>
        {tenantsDeleted.map((tenant) => (
          <Item key={tenant.uuid} data={tenant} />
        ))}
      </tbody>
    </table>
  );
}
