import {
  referenceBook_deleteProperties_addData,
  referenceBook_deleteProperties_deleteData,
} from "@global-state/pages/productProperties/slice";
import { ProductProperty } from "@interfaces/product-characteristics";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function PropertyCheckbox({
  book,
}: {
  book: ProductProperty;
}) {
  const delete_books_ids = useAppSelector(
    (state) => state.productPropertiesPage.delete_properties,
  ).map((item) => item.id);

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        delete_books_ids.includes(book.id)
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            referenceBook_deleteProperties_addData([
              {
                id: book.id,
                tag: "multiple",
              },
            ]),
          );
        } else {
          dispatch(
            referenceBook_deleteProperties_deleteData({
              id: book.id,
              singleOnly: false,
            }),
          );
        }
      }}
    />
  );
}
