import { createSlice } from "@reduxjs/toolkit";
import { SliceAction } from "store/store";

const initialState: {
  balance: string;
} = {
  balance: ""
};

const balanceSlice = createSlice({
  initialState,
  name: "balancePage",
  reducers: {
    setBalanceTopUpValue: (state, action: SliceAction<string>) => {
      state.balance = action.payload;
    },
    clearBalanceTopUpValue: (state) => {
      state.balance = "";
    },
    clearAllBalancePageData: (state) => {
      state.balance = ""
    },
  },
});

const { reducer, actions } = balanceSlice;

export default reducer;

export const {
  setBalanceTopUpValue,
  clearBalanceTopUpValue,
  clearAllBalancePageData,
} = actions;
