import cn from "classnames";
import style from "./Item.module.scss";
import { TenantDelete } from "@interfaces/tenant";
import { SubmitButton } from "@ui";
import { useTenantCancelDeleteMutation } from "@RTKQuery/account/api";

export function Item({ data }: { data: TenantDelete }) {
  const [tenantCancelDelete, { isUninitialized, isLoading, isError, error }] =
    useTenantCancelDeleteMutation();

  return (
    <tr>
      <td className={style["col"]}>
        <p className={style["subdomain"]}>{data.subdomain}</p>
        <div className={style["fullLinkBox"]}>
          <div
            className={cn(
              style["customOverflow"],
              "overflow-gradient",
              "custom-responsive-domain",
            )}
          ></div>
          <div className="custom-responsive-domain overflow-hidden">
            <p className={style["fullLink"]}>{data.subdomain}.levelup.com.ua</p>
          </div>
        </div>
      </td>
      <td className={style["col"]}>
        <div className={style["box"]}>
          <div className={style["deleteTime"]}>
            <span className={style["time"]}>{data.will_be_deleted_in}</span>
          </div>
          <div className={style["buttonBox"]}>
            <SubmitButton
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={false}
              isError={isError}
              errorText="Помилка"
              successText=""
              uninitializedText="Відмінити"
              type="button"
              className={style["customButton"]}
              errorMesage={
                error
                  ? (error as unknown as { data: { error: string } }).data
                      ?.error
                  : ""
              }
              onClick={() => {
                tenantCancelDelete(data.uuid);
              }}
            />
          </div>
        </div>
      </td>
    </tr>
  );
}
