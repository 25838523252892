import { useEffect, useState } from "react";
import style from "./MenuActions.module.scss";
import { SpoilerButton } from "@ui";
import cn from "classnames";

export function MenuActions({
  label,
  children,
}: {
  label: string | JSX.Element;
  children: JSX.Element[] | JSX.Element;
}) {
  const [showMultipleActions, setShowMultipleActions] =
    useState<boolean>(false);

  useEffect(() => {
    function clickHendler(e: any) {
      if (!e.target.closest("[data-select]")) {
        setShowMultipleActions(false);
      }
    }

    document.body.addEventListener("click", clickHendler);

    return () => {
      document.body.removeEventListener("click", clickHendler);
    };
  }, []);

  return (
    <div className={style["multipleActions"]}>
      <div
        data-select
        className={style["showButton"]}
        onClick={() => {
          setShowMultipleActions((state) => !state);
        }}
      >
        {label}
        <SpoilerButton isOpen={showMultipleActions} />
      </div>
      <div className={cn(style["listActions"], showMultipleActions && "!flex")}>
        {children}
      </div>
    </div>
  );
}
