import CRMUser from "@interfaces/user";
import { authApi, logApi } from "..";
import { setCRMUserAllDTO } from "./SetCRMUserAll.dto";
import { CRMLogIn, CRMLogOut } from "@global-state/global/slice";

export const CRMUserApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        CRMUser: build.query<CRMUser, void>({
            query: () => "/auth/profile",
            transformResponse(response, meta, arg) {
                return setCRMUserAllDTO({ profile: response });
            },
        }),
    }),
    overrideExisting: false,
});

const CRMUserLogApi = logApi.injectEndpoints({
    endpoints: (build) => ({
        forgotPasswordCRM: build.mutation<void, string>({
            query: (email) => {
                return {
                    url: "/auth/forgot-password",
                    method: "POST",
                    body: { email }
                }
            },
        }),
        loginCRM: build.mutation<{
            access_token: string,
            refresh_token: string,
            profile: any
        }, {
            email: string,
            password: string,
        }>({
            query: (body) => {
                return {
                    url: "/auth/login",
                    method: "POST",
                    body
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    window.localStorage.setItem(
                        "access_token_crm",
                        data.access_token,
                    );
                    window.localStorage.setItem(
                        "refresh_token_crm",
                        data.refresh_token,
                    );

                    dispatch(
                        CRMUserApi.util.upsertQueryData("CRMUser", undefined, setCRMUserAllDTO(data)),
                    );
                    dispatch(CRMLogIn());
                } catch { }
            },
        }),
        resetPasswordCRM: build.mutation<{
            access_token: string,
            refresh_token: string,
            profile: any
        }, {
            token: string,
            password: string,
        }>({
            query: (body) => {
                return {
                    url: "/auth/reset-password",
                    method: "POST",
                    body
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    window.localStorage.setItem(
                        "access_token_crm",
                        data.access_token,
                    );
                    window.localStorage.setItem(
                        "refresh_token_crm",
                        data.refresh_token,
                    );

                    dispatch(
                        CRMUserApi.util.upsertQueryData("CRMUser", undefined, setCRMUserAllDTO(data)),
                    );
                    dispatch(CRMLogIn());
                } catch { }
            },
        }),
        logoutCRM: build.mutation<{ ok: boolean }, { role: CRMUser["role"]["name"] }>({
            query: () => {
                return {
                    url: "/auth/logout",
                    method: "POST",
                    body: {
                        access_token: window.localStorage.getItem("access_token_crm") || "",
                        refresh_token: window.localStorage.getItem("refresh_token_crm") || "",
                    },
                    headers: {
                        Authorization: window.localStorage.getItem("access_token_crm") || "",
                    }
                }
            },
            async onQueryStarted({ role }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    if (data.ok) {
                        window.localStorage.removeItem("access_token_crm");
                        window.localStorage.removeItem("refresh_token_crm");

                        dispatch(CRMLogOut());

                        if (role === "Super Admin") {
                            const url = process.env.NODE_ENV === "development"
                                ? `http://localhost:3000/account/dashboard`
                                : `https://account.${window.location.host.includes(".com.ua") ? "levelup.com.ua" : "dev-levelup.win"}/dashboard`;

                            window.location.href = url;
                        }
                    }
                } catch { }
            },
        })
    }),
    overrideExisting: false,
});

export const {
    useLazyCRMUserQuery,
    useCRMUserQuery,
} = CRMUserApi;

export const {
    useForgotPasswordCRMMutation,
    useLoginCRMMutation,
    useResetPasswordCRMMutation,
    useLogoutCRMMutation
} = CRMUserLogApi