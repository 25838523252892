export function CheckMarkSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12px"
      height="10px"
      viewBox="0 0 12 10"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7933 0.252801C12.0689 0.589878 12.0689 1.13637 11.7933 1.47345L5.23356 9.49439C4.68224 10.1686 3.78836 10.1685 3.23703 9.49439L0.206746 5.78908C-0.0689154 5.45202 -0.0689154 4.90549 0.206746 4.56844C0.482415 4.23139 0.929352 4.23139 1.20502 4.56844L4.2353 8.27376L10.795 0.252801C11.0707 -0.084267 11.5176 -0.084267 11.7933 0.252801Z"
        fill="#4674B8"
      />
    </svg>
  );
}
