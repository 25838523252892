import { Balance, BillingHistoryRecord } from "@interfaces/billing";
import { authApi } from "..";

const balanceApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        balance: build.query<{
            ok: boolean,
            balance: Balance
          }, void>({
            query: () => "billing",
          }),
          balanceHistory: build.query<BillingHistoryRecord[], void>({
            query: () => "billing/history"
          }),
        topUpBalance: build.mutation<{pageUrl: string}, {amount: number}>({
            query: (data) => {
                return {
                    url: "billing/top-up",
                    method: "POST",
                    body: data
                }
            },
        })
    }),
    overrideExisting: false,
});

export const {
    useBalanceQuery,
    useBalanceHistoryQuery,
    useTopUpBalanceMutation
} = balanceApi;