import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { useProductProviderAddMutation } from "@RTKQuery/stock/api";
import { SaveButton, SearchTitle, TableProductsProviders } from "./components";
import { clearAddProductProviderSelectData } from "@global-state/pages/stock/slice";

const MODAL_NAME = "AddProductProviderModal";

export function AddProductProviderModal() {
  const [_, addProductProviderStatus] = useProductProviderAddMutation({
    fixedCacheKey: "productProvider_add",
  });

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (addProductProviderStatus.isSuccess) {
      if (!activeModal?.visibility) {
        dispatch(clearAddProductProviderSelectData());
        addProductProviderStatus.reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [addProductProviderStatus.isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      addProductProviderStatus.reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [addProductProviderStatus.isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!addProductProviderStatus.isSuccess) {
      dispatch(clearAddProductProviderSelectData());
    }
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      className="h-[80vh] max-w-[1170px] px-[60px] py-[40px] max-lg:px-[15px] max-sm:h-[95vh]"
    >
      <div className="flex h-full flex-col">
        <SearchTitle />
        <TableProductsProviders />
        <SaveButton />
      </div>
    </CRMModalLayout>
  );
}
