import { useCallback, useEffect, useRef, useState } from "react";
import style from "./VerifyForm.module.scss";
import { Timer } from "components/common/Timer";
import cn from "classnames";
import { RiLoader5Fill } from "react-icons/ri";
import { SubmitButton } from "@ui";
import {
  useResendCodeMutation,
  useVerifyMutation,
} from "@RTKQuery/account/api";

export function VerifyForm() {
  const [verify, verifyStatus] = useVerifyMutation();
  const [resendCode, resendCodeStatus] = useResendCodeMutation();

  const [code, setCode] = useState<string[]>(["", "", "", "", "", ""]);

  const [timerEnd, setTimerEnd] = useState<boolean>(false);
  const [timerError, setTimerError] = useState<string>("");

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    if (resendCodeStatus.isError || resendCodeStatus.isSuccess) {
      setTimeout(() => {
        resendCodeStatus.reset();
      }, 10000);
    }
  }, [resendCodeStatus.isError, resendCodeStatus.isSuccess]);

  useEffect(() => {
    if (verifyStatus.isError) {
      setTimeout(() => {
        verifyStatus.reset();
      }, 10000);
    }
  }, [verifyStatus.isError]);

  const handleInputChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (e.key === "Backspace" && !code[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain").slice(0, 6);
    const newCode = [...code];
    for (let i = 0; i < pastedData.length; i++) {
      newCode[i] = pastedData[i];
      inputRefs.current[i]?.focus();
    }
    setCode(newCode);
  };

  useEffect(() => {
    if (timerEnd) {
      setTimerError("Час верифікації вийшов");
    }
  }, [timerEnd]);

  const setEnd = useCallback(() => setTimerEnd(true), []);

  return (
    <div className={style["container"]}>
      <div className={style["inputsBox"]}>
        {[...Array(6)].map((_, index) => (
          <input
            className={style["input"]}
            key={index}
            ref={(el) => (inputRefs.current[index] = el)}
            maxLength={1}
            value={code[index]}
            onChange={(e) => handleInputChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            onPaste={handlePaste}
          />
        ))}
      </div>
      <Timer setEnd={setEnd} />
      <div className={style["buttonsBox"]}>
        <button
          className={cn(
            style["resendCodeButton"],
            resendCodeStatus.isUninitialized && style["uninitialized"],
            resendCodeStatus.isSuccess && style["success"],
            resendCodeStatus.isLoading && style["loading"],
            resendCodeStatus.isError && style["error"],
          )}
          disabled={!resendCodeStatus.isUninitialized}
          onClick={() => {
            resendCode();
          }}
        >
          <div className={style["buttonContent"]}>
            {resendCodeStatus.isLoading ? (
              <div className={style["loader"]}>
                <RiLoader5Fill className={style["icon"]} />
              </div>
            ) : (
              <span className={style["buttonText"]}>
                {resendCodeStatus.isUninitialized && "Відправити код ще раз"}
                {resendCodeStatus.isSuccess && "Код відправлено"}
                {timerError ||
                  (resendCodeStatus.error
                    ? (
                        resendCodeStatus.error as unknown as {
                          data: { error: string };
                        }
                      ).data?.error
                    : "")}
              </span>
            )}
          </div>
        </button>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="button"
            disabled={verifyStatus.isLoading || timerEnd}
            hFull
            isUninitialized={verifyStatus.isUninitialized}
            isLoading={verifyStatus.isLoading}
            isSuccess={verifyStatus.isSuccess}
            isError={verifyStatus.isError}
            uninitializedText="Далі"
            successText="Успішно веривіковано"
            errorText="Помилка"
            errorMesage=""
            onClick={() => {
              verify({
                service_token:
                  window.localStorage.getItem("service_token") || "",
                verification_code: code.join(""),
              });
            }}
          />
        </div>
      </div>
      {verifyStatus.isError ? (
        <p className={style["errorMessage"]}>
          {verifyStatus.error
            ? (verifyStatus.error as unknown as { data: { error: string } })
                .data?.error
            : "Непередбачувана помилка"}
        </p>
      ) : null}
    </div>
  );
}
