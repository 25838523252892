import { useContext } from "react";
import { ListCharacteristicsValues } from "..";
import style from "./ListCharacteristicsItem.module.scss";
import { SelectableParameter } from "@interfaces/product";
import { characteristicsContext } from "../../CoreChars";
import { Checkbox } from "@ui";
import { useAppSelector } from "store/hooks";

export function ListCharacteristicsItem({
  data,
}: {
  data: SelectableParameter;
}) {
  const context = useContext(characteristicsContext);
  const errorsChars = useAppSelector(state => state.productPage.errorsChars);

  return (
    <>
      <div className={style["itemName"]}>
        {data.multi_selection ? (
          <Checkbox
            label={<p title={data.name} className={style["customLabel"]}>{data.name}</p>}
            alignLabel="left"
            checked={data.selected}
            onChange={(e) => {
              context?.switchChekedParameter(data.id, e.target.checked);
            }}
          />
        ) : (
          <p title={data.name} className={style["customLabel"]}>{data.name}</p>
        )}
      </div>
      <ListCharacteristicsValues data={data.values} parent={data} />
      {
        errorsChars[data.id]
        ? <p className={style["errorText"]}>Потрібно обрати мінімум одне значення</p>
        : null
      }
    </>
  );
}
