import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import style from "./ImportProductsModal.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { RiLoader5Fill } from "react-icons/ri";
import {
  addImportSelectedColumn,
  setImportData,
} from "@global-state/pages/products/slice";
import Excel from "exceljs";

const MODAL_NAME = "ImportProductsModal";

export let workbookFile: Excel.Workbook | null = null;

export function ImportProductsModal() {
  const [status, setStatus] = useState<
    "idle" | "success" | { errorText: string } | "load"
  >("idle");

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);
  const importRequiredColumn = useAppSelector(
    (state) => state.productsPage.importRequiredColumn,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  const crm =
    process.env.NODE_ENV === "development"
      ? `/${window.localStorage.getItem("subdomain")}`
      : "";

  useEffect(() => {
    if (status === "success") {
      if (!activeModal?.visibility) {
        setStatus("idle");
      } else {
        navigate(crm + "/catalog/import");

        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [status, activeModal]);

  useEffect(() => {
    if (typeof status === "object") {
      const timer = setTimeout(() => {
        setStatus("idle");
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [status]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Імпорт з Horoshop"
    >
      <>
        <label
          htmlFor="filesModal"
          className={style["fileDropZone"]}
          onDragOver={(e) => {
            e.preventDefault();
            e.currentTarget.classList.add(style["dragOver"]);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            e.currentTarget.classList.remove(style["dragOver"]);
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.currentTarget.classList.remove(style["dragOver"]);

            const file = e.dataTransfer.files?.[0];

            if (file) {
              handleFileInputChange(file);
            }
          }}
        >
          <div className={style["descr"]}>
            {status === "load" ? (
              <div className={style["loader"]}>
                <RiLoader5Fill className={style["icon"]} />
              </div>
            ) : null}
            {status !== "load" ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="54"
                  height="54"
                  viewBox="0 0 54 54"
                  fill="none"
                >
                  <path
                    opacity="0.5"
                    d="M39.5 19.5049C44.9375 19.5352 47.8822 19.7763 49.8032 21.6973C52 23.894 52 27.4295 52 34.5005V37.0005C52 44.0715 52 47.6073 49.8032 49.8038C47.6065 52.0005 44.071 52.0005 37 52.0005H17C9.92893 52.0005 6.3934 52.0005 4.1967 49.8038C2 47.6073 2 44.0715 2 37.0005V34.5005C2 27.4295 2 23.894 4.1967 21.6972C6.11765 19.7763 9.06243 19.5352 14.5 19.5049"
                    stroke="#ACACAC"
                    strokeWidth="2.75"
                    strokeLinecap="round"
                  />
                  <path
                    d="M27 34.5V2M27 2L34.5 10.75M27 2L19.5 10.75"
                    stroke="#868686"
                    strokeWidth="2.75"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <p>Завантажте файл .xlsx</p>
              </>
            ) : null}
          </div>
          <input
            id="filesModal"
            type="file"
            accept=".xlsx, .xls"
            onChange={(e) => {
              const file = e.target.files?.[0];

              if (file) {
                handleFileInputChange(file);
              }

              e.target.value = "";
            }}
            style={{ opacity: 0, visibility: "hidden", width: 0, height: 0 }}
          />
        </label>
        <p className={style["errorMessage"]}>
          {typeof status === "object" ? status.errorText : ""}
        </p>
      </>
    </CRMModalLayout>
  );

  function handleFileInputChange(file: File) {
    setStatus("load");

    const wb = new Excel.Workbook();
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    reader.onload = () => {
      const buffer = reader.result;

      if (buffer && typeof buffer !== "string") {
        wb.xlsx
          .load(buffer, {
            ignoreNodes: [
              "sheetPr",
              "dimension",
              "sheetViews",
              "sheetFormatPr",
              "cols",
              "autoFilter",
              "mergeCells",
              "rowBreaks",
              "hyperlinks",
              "pageMargins",
              "dataValidations",
              "pageSetup",
              "headerFooter",
              "printOptions",
              "picture",
              "drawing",
              "sheetProtection",
              "tableParts",
              "conditionalFormatting",
              "extLst",
            ],
          })
          .then((workbook) => {
            const rows: string[][] = [];

            workbookFile = workbook;

            const sheet = workbook?.getWorksheet(1);

            sheet
              ?.getRows(
                1,
                (sheet.lastRow?.number || 0) > 50
                  ? 50
                  : sheet.lastRow?.number || 0,
              )
              ?.forEach((item) => {
                if (item.values.length !== 0) {
                  rows.push(
                    (item.values as any[]).map((item) =>
                      item instanceof Date
                        ? item.toLocaleString("en-GB", { timeZone: "UTC" })
                        : item,
                    ),
                  );
                }
              });

            if (findRequiredColumn(rows[0])) {
              setStatus("success");
              dispatch(setImportData(rows));
            }
          })
          .catch((err) => {
            console.log(err);
            setStatus({ errorText: "Цей файл не відповідає шаблону Horoshop" });
          });
      }
    };
  }

  function findRequiredColumn(fileColumns: string[]): boolean {
    return importRequiredColumn.every((item) => {
      let serverName: string = "";

      const index = fileColumns.findIndex((colName) => {
        if (Array.isArray(item.name)) {
          if (
            item?.name.some(
              (name) =>
                colName?.replace(/ /g, "").toLocaleLowerCase() ===
                name.replace(/ /g, "").toLocaleLowerCase(),
            )
          ) {
            serverName = item.name[0].replace(/[()]/g, "").replace(/ /g, "_");

            return true;
          } else {
            return false;
          }
        } else {
          if (
            colName?.replace(/ /g, "").toLocaleLowerCase() ===
            item?.name?.replace(/ /g, "").toLocaleLowerCase()
          ) {
            serverName = item.name.replace(/[()]/g, "").replace(/ /g, "_");

            return true;
          } else {
            return false;
          }
        }
      });

      if (index !== -1) {
        dispatch(
          addImportSelectedColumn({
            index,
            tag: "required",
            payload: {
              serverName,
              type: item.type,
            },
          }),
        );
        return true;
      } else {
        setStatus({
          errorText: "Відсутня колонка: " + item.name,
        });
        return false;
      }
    });
  }
}
