import { Link, useSearchParams } from "react-router-dom";
import style from "./TopPanel.module.scss";
import { Button, Input, SearchInputProps } from "@ui";
import { MenuActions } from "components/common/MenuActions";
import { useAppDispatch } from "store/hooks";
import { showModal } from "@global-state/entity/modal-state/slice";

export function TopPanel() {
  const [_, setQueryParams] = useSearchParams();

  const dispatch = useAppDispatch();

  return (
    <div className={style["topPanel"]}>
      <p className={style["currentPage"]}>Товари</p>
      <div className={style["search"]}>
        <Input<SearchInputProps>
          inputType="search"
          placeholder="Пошук..."
          onSearch={(value) => {
            setQueryParams((params) =>
              Object.assign(
                {
                  limit: params.get("limit") || "20",
                  offset: params.get("offset") || "0",
                },
                value && {
                  search: value,
                },
              ),
            );
          }}
        />
      </div>
      <div className={style["actions"]}>
        <Link to={"product/add"}>
          <Button
            type="add"
            size="big"
            text="Новий товар"
            onClick={() => {}}
            className={style["custom"]}
          />
        </Link>
        <MenuActions label={"Імпорт"}>
          <Button
            size="big"
            variant="contained"
            text="Імпорт з Horoshop"
            className={
              "h-[35px] w-full  justify-start whitespace-nowrap px-[10px]"
            }
            onClick={() => {
              dispatch(
                showModal({
                  modalName: "ImportProductsModal",
                }),
              );
            }}
            darkAlternative
          />
        </MenuActions>
      </div>
    </div>
  );
}
