import { Button, Spinner } from "@ui";
import style from "./ListServices.module.scss";
import { useFunctionalitiesAllQuery } from "@RTKQuery/functionalities/api";
import { Functionalities } from "@interfaces/functionalities";
import { showModal } from "@global-state/entity/modal-state/slice";
import { useAppDispatch } from "store/hooks";
import { setFunctionalityData } from "@global-state/pages/settings.additionalServices/slice";
import cn from "classnames";

export function ListServices() {
  const { data, isFetching, isError, error } = useFunctionalitiesAllQuery();

  if (isFetching) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner className="fill-[#ffffff] dark:fill-[#2c2d31]" />
      </div>
    );
  } else if (isError) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <p className="text-[22px] font-medium text-[#6A6A6A] max-sm:text-[18px]">
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else if (!data || data.length === 0) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <p className="text-[22px] font-medium text-[#6A6A6A] max-sm:text-[18px]">
          Не знайдено додаткових послуг.
        </p>
      </div>
    );
  } else {
    return (
      <div className={style["scrollWrapper"]}>
        <div className={style["list"]}>
          {data
            ?.slice()
            .sort((a, b) => {
              return a.id - b.id;
            })
            .map((item) => <ListServicesItem key={item.id} data={item} />)}
        </div>
      </div>
    );
  }
}

function ListServicesItem({ data }: { data: Functionalities }) {
  const dispatch = useAppDispatch();

  return (
    <div className={style["item"]}>
      <div
        className={cn(
          style["activeStatus"],
          data.status === "inactive" && "opacity-0",
        )}
      >
        Підключено
      </div>
      <div className="flex h-full flex-col justify-around p-[30px] pt-0">
        <div>
          <p className={style["title"]}>{data.name}</p>
          <p className={style["description"]}>{data.description}</p>
        </div>
        <Button
          className={"mx-auto max-h-[42px] max-w-[190px]"}
          onClick={() => {
            dispatch(setFunctionalityData(data));
            dispatch(
              showModal({
                modalName:
                  data.status === "inactive"
                    ? "ServicesActivateModal"
                    : "ServicesDeactivateModal",
              }),
            );
          }}
          size="big"
          text={data.status === "inactive" ? "Обрати тариф" : "Відключити"}
        />
      </div>
    </div>
  );
}
