import { UpdatePropertyForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import {
  referenceBook_deleteProperties_deleteData,
  referenceBook_editProperty_clearData,
} from "@global-state/pages/productProperties/slice";
import { useProductPropertyUpdateMutation } from "@RTKQuery/product-properties/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "UpdatePropertyModal";

export function UpdatePropertyModal() {
  const [_, { isSuccess, reset }] = useProductPropertyUpdateMutation({
    fixedCacheKey: "edit-book",
  });

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const edit_books = useAppSelector(
    (state) => state.productPropertiesPage.edit_property,
  );

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        dispatch(
          referenceBook_deleteProperties_deleteData({
            id: edit_books?.id || -1,
            singleOnly: false,
          }),
        );
       
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess) {
      dispatch(referenceBook_editProperty_clearData());
    }
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Відредагувати властивість"
    >
      <UpdatePropertyForm />
    </CRMModalLayout>
  );
}
