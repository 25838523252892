import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import style from "./ForgotPasswordCRMForm.module.scss";
import { SubmitButton, Input } from "@ui";
import { useForgotPasswordCRMMutation } from "@RTKQuery/CRMUser/api";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Невірний формат email")
    .required("E-mail - обов'язкове поле"),
});

export function ForgotPasswordCRMForm() {
  const [
    forgotPassword,
    { isUninitialized, isSuccess, isError, isLoading, reset, error },
  ] = useForgotPasswordCRMMutation();

  useEffect(() => {
    if (isSuccess || isError) {
      setTimeout(() => {
        reset();
      }, 1700);
    }
  }, [isSuccess, isError]);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        forgotPassword(values.email.trim())
          .unwrap()
          .then(() => {
            actions.resetForm();
          })
          .catch(() => {});
      }}
    >
      <Form>
        <div className={style["inputsBox"]}>
          <Input
            formikName="email"
            label="E-mail"
            inputType="email"
            placeholder="youremail@gmail.com"
          />
        </div>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="submit"
            disabled={isLoading}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Скинути"
            successText="Успішно"
            errorText="Помилка"
            errorMesage={
              error
                ? (error as unknown as { data: { error: string } }).data?.error
                : ""
            }
          />
        </div>
      </Form>
    </Formik>
  );
}
