import { useEffect, useState } from "react";
import { Branch } from "./components";
import cn from "classnames";
import style from "./CategorySelect.module.scss";
import { useCategoriesAllQuery } from "@RTKQuery/categories/api";
import { SpoilerButton } from "@ui";

export function CategorySelect({
  onChange,
  error,
  labelPosition = "outside",
  subMenuPosition = "top",
  label = "Категорія*",
  nullable,
  initialCategoryId,
}: {
  onChange: (value: string) => void;
  error?: string;
  labelPosition?: "outside" | "inside";
  subMenuPosition?: "top" | "bottom";
  label?: string;
  nullable?: boolean;
  initialCategoryId?: number;
}) {
  const { data } = useCategoriesAllQuery();
  const [currentCategory, setCurrentCategory] = useState<{
    value: string;
    label: string;
  }>({
    value: "-1",
    label: labelPosition === "inside" ? label : "Невизначено",
  });

  const [showListValue, setShowListValue] = useState<boolean>(false);

  function changeValue(data: { value: string; label: string }) {
    onChange(data.value);
    setCurrentCategory(data);
    setShowListValue(false);
  }

  useEffect(() => {
    function eventAction(e: any) {
      if (!e.target.closest("[data-select]")) {
        setShowListValue(false);
      }
    }

    document.body.addEventListener("click", eventAction);

    return () => {
      document.body.removeEventListener("click", eventAction);
    };
  }, []);

  return (
    <div>
      {labelPosition === "outside" ? (
        <p className={style["label"]}>{label}</p>
      ) : null}
      <div className="relative">
        <div
          data-select
          className={cn(
            style["container"],
            showListValue && "!border-input-main",
            error && "!border-[#D03A3A]",
          )}
          onClick={() => {
            setShowListValue((state) => !state);
          }}
        >
          <input
            className={cn(style["input"], error && "!text-[#D03A3A]")}
            type="text"
            value={currentCategory?.label}
            readOnly
          />
          <SpoilerButton className="p-0" isOpen={showListValue} />
        </div>
        <div
          className={cn(
            style["listValues"],
            showListValue ? "flex" : "hidden",
            "scroll",
          )}
          style={
            subMenuPosition === "bottom"
              ? {
                  top: "  calc(100% + 10px)",
                }
              : {
                  bottom: "  calc(100% + 10px)",
                }
          }
        >
          {nullable ? (
            <Branch
              key={0}
              currentCategory={currentCategory}
              data={{
                id: 0,
                name: {
                  uk: "Невизначено",
                },
                parent_id: null,
                children: [],
              }}
              changeValue={changeValue}
              initialCategoryId={initialCategoryId}
            />
          ) : null}
          {data?.map((item) => (
            <Branch
              key={item.id}
              currentCategory={currentCategory}
              data={item}
              changeValue={changeValue}
              initialCategoryId={initialCategoryId}
            />
          ))}
        </div>
        {error ? <p className={style["errorMessage"]}>{error}</p> : null}
      </div>
    </div>
  );
}
