import { createSlice } from "@reduxjs/toolkit";

import { StockPageData } from "./interface";
import { ConnectedProduct, ConnectedProductStock } from "@interfaces/stock";
import { SliceAction } from "store/store";

const initialState: StockPageData = {
    deleteProductProviderId: -1,
    addProductProviderSelectData: {
        product_id: -1,
        supplier_id: -1
    },
    searchTextProductProvider: "",
    activeConnectedProduct: null
}

const stockSlice = createSlice({
    initialState,
    name: "stockPage",
    reducers: {
        setDeleteProductProviderId: (state, action: SliceAction<StockPageData["deleteProductProviderId"]>) => {
            state.deleteProductProviderId = action.payload;
        },
        clearDeleteProductProviderId: (state) => {
            state.deleteProductProviderId = -1;
        },

        setAddProductProviderSelectData: (state, action: SliceAction<StockPageData["addProductProviderSelectData"]>) => {
            state.addProductProviderSelectData = action.payload;
        },
        clearAddProductProviderSelectData: (state) => {
            state.addProductProviderSelectData = {
                product_id: -1,
                supplier_id: -1
            };
        },

        setSearchTextProductProvider: (state, action: SliceAction<StockPageData["searchTextProductProvider"]>) => {
            state.searchTextProductProvider = action.payload;
        },

        setActiveProviderProductStock: (state, action: SliceAction<ConnectedProduct>) => {
            state.activeConnectedProduct = action.payload;
        },

        clearActiveProviderProductStock: (state) => {
            state.activeConnectedProduct = null;
        },

        setProviderProductStockItemConnected: (state, action: SliceAction<{
            idStockItem: number,
            connectedTo: ConnectedProductStock["connected_to"]
        }>) => {
            const { connectedTo, idStockItem } = action.payload;
            
            if (state.activeConnectedProduct) {
                state.activeConnectedProduct.stock = state.activeConnectedProduct.stock.map(item => {
                    if (item.connected_to === connectedTo) {
                        return {
                            ...item, 
                            connected_to: null
                        }
                    } else {
                        return item;
                    }
                })

                const elem = state.activeConnectedProduct.stock.find(item => item.id === idStockItem );

                if (elem) {
                    elem.connected_to = connectedTo;
                }
            }
        },

        clearAllStockData: (state) => {
            state.deleteProductProviderId = -1;
            state.addProductProviderSelectData = {
                product_id: -1,
                supplier_id: -1
            };
            state.activeConnectedProduct = null
        }
    }
})

const { reducer, actions } = stockSlice;

export default reducer;

export const {
    setDeleteProductProviderId,
    clearDeleteProductProviderId,

    setAddProductProviderSelectData,
    clearAddProductProviderSelectData,

    setSearchTextProductProvider,

    setActiveProviderProductStock,
    clearActiveProviderProductStock,
    setProviderProductStockItemConnected,

    clearAllStockData
} = actions;