export function DarkSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M9.33333 3.5V1M13.9167 8.5V4.33333M10.5833 2.25H8.08333M16 6.41667H11.8333M11.4623 12.5126C12.4857 12.5126 13.4577 12.2922 14.3333 11.8962C13.2389 14.3162 10.8035 16 7.97483 16C4.12276 16 1 12.8772 1 9.02517C1 6.19647 2.68384 3.76109 5.10385 2.66667C4.70787 3.54227 4.48743 4.51423 4.48743 5.53769C4.48743 9.38983 7.61017 12.5126 11.4623 12.5126Z"
        strokeWidth="1.86"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
