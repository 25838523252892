import {
  product_delete_addData,
  product_delete_deleteData,
} from "@global-state/pages/products/slice";
import { ProductTable } from "@interfaces/product";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function ProductCheckbox({ product }: { product: ProductTable }) {
  const delete_products_ids = useAppSelector(
    (state) => state.productsPage.product.delete,
  )
    .filter((item) => item.tag === "multiple")
    .map((item) => item.id);

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={delete_products_ids.includes(product.id)}
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            product_delete_addData([
              {
                id: product.id,
                tag: "multiple",
              },
            ]),
          );
        } else {
          dispatch(
            product_delete_deleteData({
              id: product.id,
              singleOnly: false,
            }),
          );
        }
      }}
    />
  );
}
