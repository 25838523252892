import {
  clearSelectProduct,
  setSelectProduct,
} from "@global-state/pages/unloading/slice";
import { useProductsAllHoroshopQuery } from "@RTKQuery/unloading/api";
import { Checkbox } from "@ui";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllProductsCheckbox() {
  const { unloadingId } = useParams();
  const [queryParams] = useSearchParams();

  const { id } = useAppSelector(
    (state) => state.unloadingPage.unloadingInfoObject,
  );

  const activeCategory = useAppSelector(
    (state) => state.unloadingPage.activeCategory,
  );

  const { data } = useProductsAllHoroshopQuery({
    categoriesIds: [activeCategory],
    unloadingId: Number(unloadingId) || 0,
    limit: +(queryParams.get("limit") || "20"),
    offset: +(queryParams.get("offset") || "0"),
    search: queryParams.get("search") || undefined,
  });

  const selectProduct = useAppSelector(
    (state) => state.unloadingPage.selectProduct,
  );

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={data?.products.length === selectProduct.length}
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(setSelectProduct(data?.products || []));
        } else {
          dispatch(clearSelectProduct());
        }
      }}
    />
  );
}
