import { useParams } from "react-router-dom";
import style from "./UserProductVariationsCol.module.scss";
import { useProductStockOneQuery } from "@RTKQuery/stock/api";
import { UserProductVariationsColItem } from "./components";

export function UserProductVariationsCol() {
  const { productId } = useParams();

  const { data } = useProductStockOneQuery(Number(productId));

  return (
    <div className={style["col"]}>
      <div className={style["title"]}>Варіації товару</div>
      <div className={style["list"]}>
        {data?.product ? (
          <UserProductVariationsColItem data={data.product} />
        ) : null}
        {data?.children.map((item) => (
          <UserProductVariationsColItem key={item.id} data={item} />
        ))}
      </div>
    </div>
  );
}
