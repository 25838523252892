import { useParams } from "react-router-dom";
import {
  ProvidersItemButton,
  ProvidersItemFooterItem,
  ProvidersItemFooterList,
  ProvidersItemFooterWrapper,
  ProvidersItemHeader,
  ProvidersItemMainBody,
  ProvidersItemMainHead,
  ProvidersItemMainItem,
  ProvidersItemMainWrapper,
  ProvidersItemWrapper,
  ProvidersList,
  ProvidersWrapper,
} from "./components";
import { useProductStockOneConnectedProductsQuery } from "@RTKQuery/stock/api";
import style from "./Providers.module.scss";

export function Providers() {
  const { productId } = useParams();

  const { data } = useProductStockOneConnectedProductsQuery(Number(productId));

  if (!data || data.length === 0) {
    return (
      <ProvidersWrapper countElement={data?.length || 0}>
        <div className={style["centered"]}>
          <p className={style["message"]}>Не підключено жодного товару.</p>
        </div>
      </ProvidersWrapper>
    );
  } else {
    return (
      <ProvidersWrapper countElement={data?.length || 0}>
        <ProvidersList data={data}>
          {({ block_id, stock, supplier }, index) => (
            <ProvidersItemWrapper key={block_id}>
              <>
                <ProvidersItemHeader
                  data={{ block_id, supplier, sku: stock[0].sku }}
                />
                <ProvidersItemMainWrapper>
                  <>
                    <ProvidersItemMainHead productName={stock[0].name} />
                    <ProvidersItemMainBody
                      data={stock.filter((item) => item.connected_to)}
                    >
                      {(data) => (
                        <ProvidersItemMainItem key={data.id} data={data} />
                      )}
                    </ProvidersItemMainBody>
                  </>
                </ProvidersItemMainWrapper>
                <ProvidersItemButton
                  modalData={{ block_id, stock, supplier }}
                />
                <ProvidersItemFooterWrapper>
                  <ProvidersItemFooterList
                    data={stock.filter((item) => !item.connected_to)}
                  >
                    {(data) => (
                      <ProvidersItemFooterItem key={data.id} data={data} />
                    )}
                  </ProvidersItemFooterList>
                </ProvidersItemFooterWrapper>
              </>
            </ProvidersItemWrapper>
          )}
        </ProvidersList>
      </ProvidersWrapper>
    );
  }
}
