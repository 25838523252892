import { Link, useParams } from "react-router-dom";
import style from "./MenuPropertiesGroupsItem.module.scss";
import cn from "classnames";
import { useAppDispatch } from "store/hooks";
import {
  referenceBook_deleteGroup_setData,
  referenceBook_editGroup_setData,
} from "@global-state/pages/productProperties/slice";
import { showModal } from "@global-state/entity/modal-state/slice";
import { Button } from "@ui";
import { ProductPropertiesGroup } from "@interfaces/product-characteristics";

export function MenuPropertyGroupsItem({ data }: { data: ProductPropertiesGroup }) {
  const { group } = useParams();

  const dispatch = useAppDispatch();

  return (
    <div
      className={cn(
        style["group"],
        data.id === Number(group) && style["group_active"],
      )}
    >
      <Link title={data.group} className={style["link"]} to={data.id + ""}>
        {data.group}
      </Link>
      <div className={style["actions"]}>
        <Button
          type="edit"
          onClick={() => {
            dispatch(
              referenceBook_editGroup_setData({
                id: data.id,
                group: data.group,
              }),
            );
            dispatch(
              showModal({
                modalName: "UpdatePropertiesGroupModal",
              }),
            );
          }}
        />
        <Button
          type="delete"
          onClick={() => {
            dispatch(referenceBook_deleteGroup_setData(data.id));
            dispatch(
              showModal({
                modalName: "DeletePropertiesGroupModal",
              }),
            );
          }}
        />
      </div>
    </div>
  );
}
