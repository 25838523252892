import { hideModal } from "@global-state/entity/modal-state/slice";
import { clearFunctionalityData } from "@global-state/pages/settings.additionalServices/slice";
import { useFunctionalitiesDeactivateMutation } from "@RTKQuery/functionalities/api";
import { Button, SubmitButton } from "@ui";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./ServicesDeactivateModal.module.scss";

const MODAL_NAME = "ServicesDeactivateModal";

export function ServicesDeactivateModal() {
  const [
    deactivate,
    { isSuccess, isError, isLoading, isUninitialized, reset, error },
  ] = useFunctionalitiesDeactivateMutation();

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);
  const setFunctionalityData = useAppSelector(
    (state) => state.settingsAdditionalServicesPage.functionalityData,
  );

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    dispatch(clearFunctionalityData());
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Відключити послугу?"
    >
      <>
        <div className={style["buttons"]}>
          <div className="w-[50%]">
            <Button
              text="Скасувати"
              variant="outlined"
              size="big"
              onClick={() => {
                hiddenModal();
              }}
            />
          </div>
          <div className="w-[50%]">
            <SubmitButton
              type="button"
              disabled={isLoading}
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              uninitializedText="Підтвердити"
              successText="Успішно відключено"
              errorText="Не вдалось відключити"
              errorMesage={""}
              onClick={() => {
                deactivate(setFunctionalityData?.id || -1);
              }}
            />
          </div>
        </div>
        <p className={style["errorMessage"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : ""}
        </p>
      </>
    </CRMModalLayout>
  );
}
