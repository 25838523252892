import { CurrentBalance, HistoryBilling, TopPanel } from "components/billing";
import style from "./BillingPage.module.scss";
import { PaymentMethodModal } from "@modals";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { clearAllBalancePageData } from "@global-state/pages/balance/slice";
import { Helmet } from "react-helmet";

export function BillingPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllBalancePageData());
    };
  }, []);

  return (
    <main className={style["layout"]}>
      <Helmet>
        <title>Баланс</title>
      </Helmet>
      <TopPanel />
      <div className={style["flexContainer"]}>
        <CurrentBalance />
        <HistoryBilling />
      </div>
      <PaymentMethodModal />
    </main>
  );
}
