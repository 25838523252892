import {
  referenceBook_deleteProperties_clearData,
  referenceBook_deleteProperties_setData,
} from "@global-state/pages/productProperties/slice";
import { ProductPropertiesGroup } from "@interfaces/product-characteristics";
import { useProductPropertiesGroupTreeAllQuery } from "@RTKQuery/product-properties/api";
import { Checkbox } from "@ui";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllPropertiesCheckbox() {
  const { group } = useParams();

  const { data } = useProductPropertiesGroupTreeAllQuery();

  const groupItem: ProductPropertiesGroup | undefined = data?.find((item) => {
    return item.id === Number(group || "-1");
  });

  const delete_books = useAppSelector(
    (state) => state.productPropertiesPage.delete_properties,
  );

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        groupItem?.items.length ===
          delete_books.filter((item) => item.tag === "multiple").length
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            referenceBook_deleteProperties_setData(
              groupItem?.items.map((book) => ({
                id: book.id,
                tag: "multiple",
              })) || [],
            ),
          );
        } else {
          dispatch(referenceBook_deleteProperties_clearData());
        }
      }}
    />
  );
}
