import { Outlet } from "react-router-dom";
import {
  MenuPropertiesGroups,
  TopPanel,
} from "../../../components/catalog/productProperties";
import {
  CreatePropertiesGroupModal,
  CreatePropertyModal,
  CreatePropertyValueModal,
  DeletePropertiesGroupModal,
  DeletePropertyModal,
  DeletePropertyValueModal,
  UpdatePropertiesGroupModal,
  UpdatePropertyModal,
  UpdatePropertyValueModal,
} from "@modals";
import style from "./ProductPropertiesPage.module.scss";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import { referenceBook_clearAllData } from "@global-state/pages/productProperties/slice";

export function ProductPropertiesPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(referenceBook_clearAllData());
    };
  }, []);

  return (
    <main className={style["layout"]}>
      <TopPanel />
      <div className={style["flexContainer"]}>
        <div className={style["categories"]}>
          <MenuPropertiesGroups />
        </div>
        <div className={style["page"]}>
          <Outlet />
        </div>
      </div>
      <>
        <CreatePropertyModal />
        <CreatePropertiesGroupModal />
        <CreatePropertyValueModal />
        <DeletePropertyModal />
        <DeletePropertiesGroupModal />
        <DeletePropertyValueModal />
        <UpdatePropertyModal />
        <UpdatePropertiesGroupModal />
        <UpdatePropertyValueModal />
      </>
    </main>
  );
}
