import { Button, Input, SearchInputProps } from "@ui";
import style from "./ControlPanel.module.scss";
import { useParams, useSearchParams } from "react-router-dom";
import { FilterSvg } from "./Filter.svg";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { hideModal, showModal } from "@global-state/entity/modal-state/slice";
import { SearchSvg } from "./Search.svg";
import cn from "classnames";
import { useEffect, useState } from "react";
import { MenuActions } from "components/common/MenuActions";

export function ControlPanel() {
  const { unloadingId } = useParams();
  const [_, setQueryParams] = useSearchParams();

  const [showMobileSearch, setShowMobileSearch] = useState<boolean>(false);

  const activeModal = useAppSelector(
    (state) => state.modalState["ProductsCategories"],
  );
  const selectProduct = useAppSelector(
    (state) => state.unloadingPage.selectProduct,
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    function clickHendler(e: any) {
      if (!e.target.closest("[data-search]")) {
        setShowMobileSearch(false);
      }
    }

    document.body.addEventListener("click", clickHendler);

    return () => {
      document.body.removeEventListener("click", clickHendler);
    };
  }, []);

  return (
    <div className={style["container"]}>
      <div className={style["mobileButton"]}>
        <button
          data-nohide
          onClick={() => {
            if (activeModal?.visibility) {
              dispatch(hideModal("ProductsCategories"));
            } else {
              dispatch(
                showModal({
                  modalName: "ProductsCategories",
                }),
              );
            }
          }}
          className={style["categoryButton"]}
        >
          <FilterSvg />
        </button>
        <div data-search className="relative">
          <button
            onClick={() => {
              setShowMobileSearch((state) => !state);
            }}
            className={style["searchButton"]}
          >
            <SearchSvg />
          </button>
          <div
            className={cn(style["mobileSearch"], showMobileSearch && "!block")}
          >
            <Input<SearchInputProps>
              inputType="search"
              placeholder="Пошук..."
              onSearch={(value) => {
                setQueryParams((params) =>
                  Object.assign(
                    {
                      limit: params.get("limit") || "20",
                      offset: params.get("offset") || "0",
                    },
                    value && {
                      search: value,
                    },
                  ),
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className={style["searchBox"]}>
        <Input<SearchInputProps>
          inputType="search"
          placeholder="Пошук..."
          onSearch={(value) => {
            setQueryParams((params) =>
              Object.assign(
                {
                  limit: params.get("limit") || "20",
                  offset: params.get("offset") || "0",
                },
                value && {
                  search: value,
                },
              ),
            );
          }}
        />
      </div>
      {unloadingId && (
        <MenuActions label={<p>Відмічено: {selectProduct.length}</p>}>
          <Button
            size="big"
            variant="contained"
            text="Додати Horoshop категорії"
            className={style["customButton"]}
            disabled={selectProduct.length === 0}
            onClick={() => {
              dispatch(
                showModal({
                  modalName: "SetHoroshopCategoriesModal",
                }),
              );
            }}
            darkAlternative
          />
          <Button
            size="big"
            variant="contained"
            text="Видалити Horoshop категорії"
            className={style["customButton"]}
            disabled={selectProduct.length === 0}
            onClick={() => {
              dispatch(
                showModal({
                  modalName: "RemoveHoroshopCategoriesModal",
                }),
              );
            }}
            darkAlternative
          />
        </MenuActions>
      )}
    </div>
  );
}
