import { Helmet } from "react-helmet";
import { Tenants, TenantsDeleted } from "../../../components/account/dashboard";
import style from "./AccountDashboardPage.module.scss";

export function AccountDashboardPage() {
  return (
    <main className={style["page"]}>
      <Helmet>
        <title>LevelUp</title>
      </Helmet>
      <Tenants />
      <TenantsDeleted />
    </main>
  );
}
