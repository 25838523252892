import { ConnectedProductStock } from "@interfaces/stock";
import style from "./ProvidersItemFooterItem.module.scss";

export function ProvidersItemFooterItem({
  data,
}: {
  data: ConnectedProductStock;
}) {
  return (
    <div className={style["item"]}>
      <p className="line-clamp-2 text-center">{data.variation}</p>
      <div className={style["qty"]}>{data.qty}</div>
    </div>
  );
}
