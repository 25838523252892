import { PaginationPagesButton } from "components/common/PaginationPagesButton";
import style from "./ProvidersWrapper.module.scss";
import { useState } from "react";

export function ProvidersWrapper({
  countElement,
  children,
}: {
  countElement: number;
  children: JSX.Element;
}) {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  return (
    <div className={style["wrapper"]}>
      <div className={style["scrollContainer"]}>
        <div
          className={style["sliderHelper"]}
          style={
            {
              "--active-slide": activeSlide,
            } as React.CSSProperties
          }
        >
          {children}
        </div>
        <div className={style["divider"]}></div>
        <div className={style["sliderControll"]}>
          <PaginationPagesButton
            fullWidth
            count={countElement}
            activePage={activeSlide}
            onClickPage={(page) => {
              setActiveSlide(page);
            }}
          />
        </div>
      </div>
    </div>
  );
}
