import {
  AllProductsBody,
  AllProductsHead,
  AllProductsItem,
  AllProductsWrapper,
} from "./components";

export function AllProducts() {
  return (
    <AllProductsWrapper>
      <>
        <AllProductsHead />
        <AllProductsBody>
          {(data) => <AllProductsItem key={data.id} data={data} />}
        </AllProductsBody>
      </>
    </AllProductsWrapper>
  );
}
