import { useProductProviderConnectingMutation } from "@RTKQuery/stock/api";
import { SubmitButton } from "@ui";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/hooks";

export function SaveButton() {
  const { productId } = useParams();

  const [
    productProviderConnecting,
    { isLoading, isUninitialized, isSuccess, isError, error },
  ] = useProductProviderConnectingMutation({
    fixedCacheKey: "productProvider_connect",
  });

  const activeConnectedProduct = useAppSelector(
    (state) => state.stockPage.activeConnectedProduct,
  );

  return (
    <SubmitButton
      type="button"
      disabled={isLoading}
      isUninitialized={isUninitialized}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      uninitializedText="Зберегти"
      successText="Успішно збережено"
      errorText="Не вдалось зберегти"
      className="mx-auto h-[40px] w-[225px] shrink-0"
      errorMesage={
        error
          ? (
              error as unknown as {
                data: { error: string };
              }
            ).data?.error
          : ""
      }
      onClick={() => {
        if (activeConnectedProduct) {
          productProviderConnecting({
            parent_id: Number(productId),
            block_id: activeConnectedProduct.block_id,
            products: activeConnectedProduct.stock.map((item) => ({
              id: item.id,
              connected_to: item.connected_to,
            })),
          });
        }
      }}
    />
  );
}
