export function CopySvg({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M5.26318 9.05299C5.26318 7.26669 5.26318 6.37354 5.81812 5.81861C6.37305 5.26367 7.2662 5.26367 9.0525 5.26367H10.9472C12.7335 5.26367 13.6266 5.26367 14.1815 5.81861C14.7365 6.37354 14.7365 7.26669 14.7365 9.05299V12.2108C14.7365 13.997 14.7365 14.8902 14.1815 15.4451C13.6266 16.0001 12.7335 16.0001 10.9472 16.0001H9.0525C7.2662 16.0001 6.37305 16.0001 5.81812 15.4451C5.26318 14.8902 5.26318 13.997 5.26318 12.2108V9.05299Z"
        strokeWidth="1.6"
      />
      <path
        d="M2.89466 11.7364C1.84827 11.7364 1 10.8882 1 9.84175V6.05243C1 3.67069 1 2.47982 1.73991 1.73991C2.47982 1 3.67069 1 6.05243 1H8.57864C9.62506 1 10.4733 1.84827 10.4733 2.89466"
        strokeWidth="1.6"
      />
    </svg>
  );
}
