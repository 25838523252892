import CRMUser from "@interfaces/user";

export function setCRMUserAllDTO(data: any): CRMUser {
  return {
    user: {
      uuid: data.profile.uuid,
      first_name: data.profile.first_name,
      lang: data.profile.lang,
      last_name: data.profile.last_name,
      tz: data.profile.tz,
      email: data.profile.email,
      phone: data.profile.phone,
    },
    settings: {
      active_functionalities: data.profile.settings.active_functionalities,
    },
    role: {
      id: data.profile.role.id,
      uuid: data.profile.role.uuid,
      is_custom: data.profile.role.is_custom,
      is_system: data.profile.role.is_system,
      is_visible: data.profile.role.is_visible,
      description: data.profile.role.description,
      name: data.profile.role.name,
      title: data.profile.role.title,
    },
    profile_status: {
      is_active: data.profile.is_active,
      is_verified: data.profile.is_verified,
      is_visible: data.profile.is_visible,
    },
  };
}
