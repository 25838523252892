import { useAppSelector } from "store/hooks";
import { CoreChars, CoreVariations, CreateMod, EditMod } from "./components";
import { useParams } from "react-router-dom";

export function PropertiesSettings() {
  const { variationId } = useParams();

  const authenticProductObject = useAppSelector(
    (state) => state.productPage.authenticProductObject,
  );

  if (!variationId) {
    if (authenticProductObject) {
      if (authenticProductObject.product.properties.length !== 0) {
        return (
          <EditMod
            dataProductCharacterictics={
              authenticProductObject.product.properties
            }
          >
            {(selectebleProductData) => <CoreChars data={selectebleProductData} mode="edit" />}
          </EditMod>
        );
      } else {
        return null;
      }
    } else {
      return (
        <CreateMod>
          {(propertyChars, propertyVariations) => {
            return <div className="flex flex-col gap-[25px]">
              {
                (propertyChars.length > 0) && <CoreChars data={propertyChars} mode="create" />
              }
              {
                (propertyVariations.length > 0) && <CoreVariations data={propertyVariations} mode="create"/>
              }
            </div>;
          }}
        </CreateMod>
      );
    }
  } else {
    return null;
  }
}
