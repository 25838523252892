import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import { Checkbox, Input, Select, SubmitButton } from "@ui";
import { useProductPropertiesGroupTreeAllQuery, useProductPropertyUpdateMutation } from "@RTKQuery/product-properties/api";

const ValidationSchema = Yup.object().shape({
  friends: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .max(36, "Максимальна довжина рядка 36 символів")
        .required("Назва довідника - обов'язкове поле"),
    }),
  ),
});

export function UpdatePropertyForm() {
  const [
    updateProductProperty,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useProductPropertyUpdateMutation({
    fixedCacheKey: "edit-book",
  });

  const listGroup = useProductPropertiesGroupTreeAllQuery().data?.map((item) => ({
    id: item.id,
    label: item.group,
    value: item.id + "",
  }));

  const edit_books = useAppSelector(
    (state) => state.productPropertiesPage.edit_property,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: edit_books?.name || "",
        group_id: String(edit_books?.group_id || -1),
        multi_selection: edit_books?.multi_selection || false,
        required: edit_books?.required || false,
        type: edit_books?.type || "characteristic",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        updateProductProperty([{
          id: edit_books?.id || -1,
          name: values.name.trim(),
          group_id: +values.group_id,
          multi_selection: values.multi_selection,
          required: values.required,
          type: values.type
        }]);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mb-[40px] flex flex-col gap-[20px]">
            <div className="flex gap-[15px] max-sm:mb-[30px] max-sm:flex-col max-sm:gap-[10px]">
              <div className="w-[50%] max-sm:w-[100%]">
                <Input
                  formikName={"name"}
                  label="Нова назва властивості"
                  inputType="text"
                />
              </div>
              <div className="w-[50%] max-sm:w-[100%]">
                <Select
                  formikName={"group_id"}
                  label="Нова група"
                  listValue={listGroup || []}
                />
              </div>
            </div>
            <Select
              formikName="type"
              label="Тип"
              listValue={[
                {
                  id: 1,
                  value: "variation",
                  label: "Варіація",
                },
                {
                  id: 2,
                  value: "characteristic",
                  label: "Характеристика",
                },
              ]}
            />
            <Checkbox
              label="Обов'язкова"
              checked={values.required}
              onChange={() => {
                setFieldValue("required", !values.required);
              }}
            />
            <Checkbox
              label="Мультивибір"
              checked={values.multi_selection}
              onChange={() => {
                setFieldValue("multi_selection", !values.multi_selection);
              }}
            />
          </div>
          <SubmitButton
            type="submit"
            disabled={isLoading}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Зберегти"
            successText="Успішно збережено"
            errorText="Не вдалось зберегти"
            errorMesage={
              error
                ? (error as unknown as { data: { error: string } }).data?.error
                : ""
            }
          />
        </Form>
      )}
    </Formik>
  );
}
