export interface SystemNotification {
  id: number;
  created_at: string;
  message: string;
  read: boolean;
  name: string;
  type: SystemNotificationType;
  result:
    | {
        [SystemNotificationType.HOROSHOP_IMPORT]: ResultHoroshopImport;
      }[SystemNotificationType]
    | null;
}

interface ResultHoroshopImport {
  pictures: {
    added?: number;
    errors?: string[];
  };
  products: {
    added?: number;
    errors?: string[];
    existing?: number;
  };
}

export enum SystemNotificationType {
  HOROSHOP_IMPORT = "HOROSHOP_IMPORT",
}

function HOROSHOP_IMPORT(
  notification: SystemNotification,
): notification is SystemNotification & { result: ResultHoroshopImport } {
  return notification.type === SystemNotificationType.HOROSHOP_IMPORT;
}

export function isTypeSystemNotification<T extends SystemNotificationType>(
  type: T,
  notification: SystemNotification,
): notification is SystemNotification & {
  result: {
    [SystemNotificationType.HOROSHOP_IMPORT]: ResultHoroshopImport;
  }[T];
} {
  if (type === SystemNotificationType.HOROSHOP_IMPORT) {
    return HOROSHOP_IMPORT(notification);
  }
  throw new Error("Invalid notification type");
}
