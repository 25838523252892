import {
  TablePropertiesBody,
  TablePropertiesHead,
  TablePropertiesItem,
  TablePropertiesWrapper,
} from "./components";

export function TableProperties() {
  return (
    <TablePropertiesWrapper>
      <>
        <TablePropertiesHead />
        <TablePropertiesBody>
          {(book, group) => (
            <TablePropertiesItem key={book.id} book={book} group={group} />
          )}
        </TablePropertiesBody>
      </>
    </TablePropertiesWrapper>
  );
}
