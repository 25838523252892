import { Form, Formik } from "formik";
import * as Yup from "yup";
import style from "./CreateTenantForm.module.scss";
import { BasicInputProps, Input, Radio, SubmitButton } from "@ui";
import { useTenantCreateMutation } from "@RTKQuery/account/api";
import Tenant from "@interfaces/tenant";

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-z0-9-]+$/, "Тільки малі латинські літери, цифри та дефіс")
    .min(4, "Не менше 4 символів")
    .max(16, "Не більше 16 символів")
    .required("Назва домену - обов'язкове поле"),
});

export function CreateTenantForm() {
  const [
    tenantCreate,
    { isUninitialized, isLoading, isError, isSuccess, error },
  ] = useTenantCreateMutation({
    fixedCacheKey: "create_tenant",
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        accountType: "dropper",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        tenantCreate({
          subdomain: values.name.trim(),
          account_type: values.accountType as Tenant["account_type"],
        })
          .unwrap()
          .then(() => {
            actions.resetForm();
          })
          .catch(() => {});
      }}
    >
      {(props) => (
        <Form>
          <div className={style["container"]}>
            <div className={style["inputsBox"]}>
              <p className={style["prefix"]}>https://</p>
              <div className="grow">
                <Input<BasicInputProps>
                  formikName="name"
                  label=""
                  inputType="text"
                />
              </div>
              <p className={style["suffix"]}>.levelup.com.ua</p>
            </div>
            <div className={style["radioBox"]}>
              <Radio
                checked={props.values.accountType === "dropper"}
                onChange={() => {
                  props.setFieldValue("accountType", "dropper");
                }}
                label="Дропшипер"
              />
              <Radio
                checked={props.values.accountType === "supplier"}
                onChange={() => {
                  props.setFieldValue("accountType", "supplier");
                }}
                label="Постачальник"
              />
            </div>
          </div>
          <SubmitButton
            type="submit"
            disabled={isLoading}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Створити нову CRM"
            successText="Успішно створено"
            errorText="Не вдалось створити"
            errorMesage={
              error
                ? (error as unknown as { data: { error: string } }).data?.error
                : ""
            }
          />
        </Form>
      )}
    </Formik>
  );
}
