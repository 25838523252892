import { Checkbox } from "components/common/UI/Checkbox";
import style from "./ItemHints.module.scss";

export function ItemHints<T>({
  dataItem,
  selectDataList,
  keyForLabel,
  keyForId,
  onSelect,
}: {
  dataItem: T;
  selectDataList: T[];
  keyForLabel: keyof T;
  keyForId: keyof T;
  onSelect: (value: T, select: boolean) => void;
}) {
  return (
    <div className={style["itemHints"]}>
      <div className="flex gap-[13px]">
        <Checkbox
          checked={
            selectDataList.findIndex(
              (item) => item[keyForId] === dataItem[keyForId],
            ) !== -1
          }
          onChange={(e) => {
            onSelect(dataItem, e.target.checked);
          }}
        />
        <p>{String(dataItem[keyForLabel])}</p>
      </div>
    </div>
  );
}
