import style from "./AllProductsHead.module.scss";

export function AllProductsHead() {
  return (
    <div className={style["head"]}>
      <div className={style["col"]}>Артикул</div>
      <div className={style["col"]}>Властивості</div>
      <div className={style["col"]}>Наша наявність</div>
      <div className={style["col"]}>Заг. Наявність</div>
    </div>
  );
}
