import { useField, useFormikContext } from "formik";
import { RxCrossCircled } from "react-icons/rx";
import { BasicInputProps } from "../../Input";
import cn from "classnames";

import style from "./Basic.module.scss";

export function BasicInput({
  formikName,
  placeholder,
  label,
  helperText,
  className,
  readOnly,
  helperTextVisibility = true,
}: BasicInputProps) {
  const { setFieldValue, values } = useFormikContext<Record<string, any>>();
  const [field, meta] = useField(formikName);

  return (
    <div className={className}>
      {typeof label === "string" ? (
        <p className={style["label"]}>{label}</p>
      ) : (
        label
      )}
      <div
        className={cn(
          style["container"],
          meta.touched && meta.error ? style["error_on"] : style["error_off"],
        )}
      >
        <input
          {...field}
          className={style["input"]}
          type="text"
          placeholder={placeholder || ""}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              e.preventDefault();
            }
          }}
          readOnly={readOnly}
        />
        <div className={style["clearButton"]}>
          {values[field.name] ? (
            <RxCrossCircled
              className={style["icon"]}
              onClick={() => {
                setFieldValue(field.name, "");
              }}
            />
          ) : null}
        </div>
      </div>
      {helperTextVisibility ? (
        meta.error?.includes("повинно бути числовим") ? (
          helperText ? (
            helperText(meta.error || "")
          ) : (
            <p className={style["errorMessage"]}>{meta.error || ""}</p>
          )
        ) : meta.touched && meta.error ? (
          helperText ? (
            helperText(meta.error || "")
          ) : (
            <p className={style["errorMessage"]}>{meta.error || ""}</p>
          )
        ) : null
      ) : null}
    </div>
  );
}
