import { AllProductsCheckbox, SortButton } from "./components";
import cn from "classnames";

import style from "./TableProductsHead.module.scss";
import { clearSelectProduct } from "@global-state/pages/unloading/slice";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "store/hooks";

export function TableProductsHead({
  sortBy,
  setSortBy,
}: {
  sortBy: string;
  setSortBy: React.Dispatch<
    React.SetStateAction<
      | ""
      | "price/asc"
      | "price/desc"
      | "category/asc"
      | "category/desc"
      | "ua_name/desc"
      | "ua_name/asc"
    >
  >;
}) {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(clearSelectProduct())
  }, [location]);

  return (
    <div className={style["head"]}>
      <div></div>
      <div className={cn(style["headColumn"], "!justify-start pl-[10px]")}>
        <button
          className={style["sortButtonContainer"]}
          onClick={() => {
            setSortBy((state) => {
              if (state === "ua_name/asc") {
                return "ua_name/desc";
              } else if (state === "ua_name/desc") {
                return "";
              } else {
                return "ua_name/asc";
              }
            });
          }}
        >
          <p>Назва</p>
          <SortButton sort={sortBy} nameCol={"ua_name"} />
        </button>
      </div>
      <div className={style["headColumn"]}>Артикул</div>
      <div className={style["headColumn"]}>Фото</div>
      <div className={style["headColumn"]}>
        <button
          className={style["sortButtonContainer"]}
          onClick={() => {
            setSortBy((state) => {
              if (state === "price/asc") {
                return "price/desc";
              } else if (state === "price/desc") {
                return "";
              } else {
                return "price/asc";
              }
            });
          }}
        >
          <p>Ціна</p>
          <SortButton sort={sortBy} nameCol={"price"} />
        </button>
      </div>
      <div className={cn(style["headColumn"], "!justify-start")}>
        <button
          className={style["sortButtonContainer"]}
          onClick={() => {
            setSortBy((state) => {
              if (state === "category/asc") {
                return "category/desc";
              } else if (state === "category/desc") {
                return "";
              } else {
                return "category/asc";
              }
            });
          }}
        >
          <p>Хорошоп категорії</p>
          <SortButton sort={sortBy} nameCol={"category"} />
        </button>
      </div>
      <div className={style["headColumn"]}>
        <AllProductsCheckbox />
      </div>
    </div>
  );
}
