import { HoroshopCategory } from "@interfaces/unloading";
import { authApi } from "..";

const horoshopCategoriesApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        horoshopCategoriesAll: build.query<HoroshopCategory[], string | void>({
            query: (search) => "horoshop/categories/history" + (search ? `?search=${search}` : ""),
            providesTags: ["HoroshopCategory"],
        }),
        horoshopCategoryCreate: build.mutation<HoroshopCategory, HoroshopCategory["name"]>({
            query: (data) => {
                return {
                    url: "horoshop/categories/history",
                    method: "POST",
                    body: {
                        name: data
                    }
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        horoshopCategoriesApi.util.updateQueryData(
                            "horoshopCategoriesAll",
                            undefined,
                            (draft) => {
                                Object.assign(draft, data);
                            },
                        ),
                    );
                } catch { }
            },
        }),
        horoshopCategoryUpdate: build.mutation<HoroshopCategory[], HoroshopCategory>({
            query: (data) => {
                return {
                    url: "horoshop/categories/history",
                    method: "PUT",
                    body: data
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        horoshopCategoriesApi.util.upsertQueryData(
                            "horoshopCategoriesAll",
                            undefined,
                            data
                        ),
                    );
                } catch { }
            },
            invalidatesTags: [{ type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" }],
        }),
        horoshopCategoryDelete: build.mutation<HoroshopCategory[], HoroshopCategory>({
            query: (data) => {
                return {
                    url: `horoshop/categories/history?items=${data.id}`,
                    method: "DELETE"
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        horoshopCategoriesApi.util.upsertQueryData(
                            "horoshopCategoriesAll",
                            undefined,
                            data
                        ),
                    );
                } catch { }
            },
            invalidatesTags: [{ type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useHoroshopCategoriesAllQuery,
    useHoroshopCategoryCreateMutation,
    useHoroshopCategoryUpdateMutation,
    useHoroshopCategoryDeleteMutation
} = horoshopCategoriesApi;