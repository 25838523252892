import { useAppSelector } from "store/hooks";
import style from "./ProviderProductVariationsCol.module.scss";
import { DraggableItem } from "../DraggableItem";
import { useDroppable } from "@dnd-kit/core";
import cn from "classnames";

export function ProviderProductVariationsCol() {
  const providerStockList = useAppSelector(
    (state) => state.stockPage.activeConnectedProduct?.stock,
  );

  const { setNodeRef, active, isOver } = useDroppable({
    id: "disConnect",
  });

  return (
    <div
      ref={setNodeRef}
      className={cn(
        style["col"],
        isOver && active?.data.current?.connected_to && style["col_over"],
      )}
    >
      <div className={style["title"]}>Варіації постачальника</div>
      <div className={style["list"]}>
        {providerStockList?.map((item) => {
          if (item.connected_to) {
            return null;
          } else {
            return <DraggableItem key={item.id} data={item} />;
          }
        })}
      </div>
    </div>
  );
}
