export function ChatsSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.4261 4.74051H4.33329C3.90814 4.7394 3.48695 4.82217 3.09384 4.9841C2.70073 5.14602 2.34343 5.38392 2.04241 5.68415C1.74139 5.98438 1.50256 6.34106 1.33961 6.73374C1.17665 7.12642 1.09277 7.5474 1.09277 7.97255V12.8333C1.09277 13.6928 1.43418 14.517 2.0419 15.1247C2.64961 15.7324 3.47385 16.0738 4.33329 16.0738H5.95355L8.3797 18.5L10.8483 16.0738H12.4685C13.328 16.0738 14.1522 15.7324 14.7599 15.1247C15.3676 14.517 15.709 13.6928 15.709 12.8333V7.97255C15.7091 7.54379 15.6238 7.11931 15.4582 6.72383C15.2926 6.32835 15.05 5.96977 14.7445 5.66897C14.4389 5.36817 14.0766 5.13117 13.6786 4.97177C13.2806 4.81237 12.8548 4.73376 12.4261 4.74051Z"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M15.6666 12.8333C16.0936 12.8333 16.5163 12.749 16.9106 12.585C17.3049 12.4211 17.6629 12.1809 17.964 11.8782C18.2651 11.5755 18.5035 11.2163 18.6653 10.8212C18.8272 10.426 18.9093 10.0028 18.9071 9.57585V4.74052C18.9071 3.88108 18.5657 3.05684 17.958 2.44913C17.3503 1.84141 16.526 1.5 15.6666 1.5H7.57377C6.71433 1.5 5.89009 1.84141 5.28238 2.44913C4.67466 3.05684 4.33325 3.88108 4.33325 4.74052"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M4.33325 10.4072H5.95351"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M7.57373 10.4072H9.19399"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
      <path
        d="M10.8059 10.4072H12.4262"
        strokeWidth="1.8"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
