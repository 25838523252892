import { useProductImportExcelMutation } from "@RTKQuery/products/api";
import style from "./TopPanel.module.scss";
import { Checkbox, SelectNoFormik, SubmitButton } from "@ui";
import { workbookFile } from "components/modals/products.modals/product/ImportProductsModal/ImportProductsModal";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CategorySelect } from "components/common/UI/CategorySelect";
import { useEffect, useRef, useState } from "react";
import { ImportExcel } from "@interfaces/import";
import { showModal } from "@global-state/entity/modal-state/slice";
import { useProductPropertiesGroupTreeAllQuery } from "@RTKQuery/product-properties/api";
import { clearByTagImportSelectedColumn, importSelectedPropertiesGroupIdSet } from "@global-state/pages/products/slice";

export function TopPanel() {
  const { data } = useProductPropertiesGroupTreeAllQuery();

  const [importExcel, status] = useProductImportExcelMutation({
    fixedCacheKey: "import",
  });

  const {importSelectedColumn, counterSameCharsError, importSelectedPropertiesGroupId} = useAppSelector(
    (state) => state.productsPage,
  );

  const importSelectedPropertiesGroup = data?.find(group => group.id === importSelectedPropertiesGroupId);

  const [selectCategoryId, setSelectCategoryId] = useState<string>("0");
  const [childrenPicturesAreUnique, setChildrenPicturesAreUnique] = useState<boolean>(false);
  const [doNotUploadPictures, setDoNotUploadPictures] = useState<boolean>(false);
  const [errorNotSelectedPropertiesGroup, setErrorNotSelectedPropertiesGroup] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (status.isError) {
      refSetTimeout.current = setTimeout(() => {
        status.reset();
      }, 5000);
    }

    return () => {
      if (status.isError) {
        status.reset();
      }
    };
  }, [status.isError]);

  useEffect(() => {
    return () => {
      status.reset();
      clearTimeout(refSetTimeout.current);
    };
  }, []);

  return (
    <div className={style["topPanel"]}>
      <div className="flex  w-full items-center justify-between max-sm:order-2 max-sm:w-fit">
        <p className={style["currentPage"]}>Імпорт з Horoshop</p>
        <div className="flex flex-col items-center">
          <SubmitButton
            className="w-fit whitespace-nowrap px-[15px] max-sm:rounded-[6px] "
            type="button"
            disabled={
              status.isLoading ||
              (Object.values(counterSameCharsError).length > 0 &&
                Object.values(counterSameCharsError).every((item) => item > 1))
            }
            isUninitialized={status.isUninitialized}
            isLoading={status.isLoading}
            isSuccess={status.isSuccess}
            isError={status.isError}
            uninitializedText="Імпортувати"
            successText="Успішно імпортовано"
            errorText="Не вдалось імпортувати"
            errorMesage={
              status.error
                ? (status.error as unknown as { data: { error: string } }).data
                  ?.error
                : ""
            }
            onClick={() => {
              if (importSelectedPropertiesGroupId === -1) {
                setErrorNotSelectedPropertiesGroup(true)
              } else {
                const products: ImportExcel["products"] = [];
  
                workbookFile?.eachSheet((sheet, id) => {
                  const productKeys = sheet.getRow(1).values as string[];
  
                  sheet.eachRow((row, rowNumber) => {
                    if (rowNumber === 1) return;
  
                    const product: ImportExcel["products"][0]["product"] = {};
                    const characteristics: ImportExcel["products"][0]["characteristics"] =
                      [];
                    const variations: ImportExcel["products"][0]["variations"] =
                      [];
  
                    importSelectedColumn.forEach((column) => {
                      const value: string = (row.values as string[])[
                        column.index
                      ];
  
                      let convertValue: string | number | null;
  
                      if (column.payload?.type === "str") {
                        convertValue = value || "";
                      } else if (column.payload?.type === "num") {
                        convertValue = Number(value) || 0;
                      } else {
                        convertValue = value || null;
                      }
  
                      if (column.tag === "required") {
                        product[column.payload?.serverName] = convertValue;
                      } else if (column.tag === "characteristic") {
                        if (value) {
                          if (column?.payload?.id) {
                            characteristics.push({
                              id: column.payload.id,
                              name: column.payload.label,
                              value: String(value) || "",
                            });
                          } else {
                            characteristics.push({
                              name: productKeys[column.index],
                              value: String(value) || "",
                            });
                          }
                        }
                      } else if (column.tag === "variation") {
                        if (value) {
                          variations.push({
                            name: productKeys[column.index],
                            value: String(value) || "",
                          });
                        }
                      }
                    });
  
                    products.push({
                      product,
                      characteristics,
                      variations,
                    });
                  });
                });
  
                importExcel({
                  category_id: Number(selectCategoryId),
                  children_pictures_are_unique: childrenPicturesAreUnique,
                  do_not_upload_pictures: doNotUploadPictures,
                  products: products,
                  property_group_id: importSelectedPropertiesGroupId
                })
                  .unwrap()
                  .then(() => {
                    dispatch(
                      showModal({
                        modalName: "ImportSendSuccessModal",
                      }),
                    );
                  })
                  .catch(() => { });
              }
            }}
          />
        </div>
      </div>
      <div className="flex max-w-full items-center gap-[15px] max-sm:order-1 max-sm:flex-col max-sm:items-start">
        <CategorySelect
          onChange={(value) => {
            setSelectCategoryId(value);
          }}
          label="Категорія"
          labelPosition="inside"
          subMenuPosition="bottom"
          nullable
        />
        <SelectNoFormik
          adaptive
          currentValue={
            importSelectedPropertiesGroup
              ? {
                id: importSelectedPropertiesGroup.id,
                value: String(importSelectedPropertiesGroup.id),
                label: importSelectedPropertiesGroup.group,
              }
              : {
                id: -1,
                value: "-1",
                label: "Група властивостей",
              }
          }
          listValue={
            [
              ...(data || []).map((item) => ({
                id: item.id,
                value: String(item.id),
                label: item.group,
              }))
            ]
          }
          isError={errorNotSelectedPropertiesGroup}
          errorText={"Вибір категорії обов'язковий"}
          setCurrentValue={(value) => {
            setErrorNotSelectedPropertiesGroup(false)
            dispatch(clearByTagImportSelectedColumn("characteristic"));
            dispatch(importSelectedPropertiesGroupIdSet(value.id));
          }}
        />
        <div className="flex min-w-0 gap-[10px] max-lg:flex-col">
          <div className={style["hint"]}>
            <Checkbox
              checked={childrenPicturesAreUnique}
              onChange={() => {
                setChildrenPicturesAreUnique((state) => !state);
                setDoNotUploadPictures(false);
              }}
              label="Вигружати фото дочірніх товарів"
            />
            <div className={style["descr"]}>
              Не рекомендується, якщо вони такі ж, як і в батьківського
            </div>
          </div>
          <Checkbox
            checked={doNotUploadPictures}
            onChange={() => {
              setDoNotUploadPictures((state) => !state);
              setChildrenPicturesAreUnique(false);
            }}
            label="Не вигружати фото"
          />
        </div>
      </div>
    </div>
  );
}
