export function HintSvg() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.5" cy="9.5" r="8.70833" strokeWidth="1.58333" />
      <path d="M10.5267 10.9037H8.75244C8.75708 10.4862 8.78955 10.1267 8.84985 9.8252C8.9148 9.51904 9.0238 9.24304 9.17688 8.99719C9.33459 8.75134 9.54334 8.50781 9.8031 8.2666C10.0211 8.07178 10.2113 7.88623 10.3737 7.70996C10.536 7.53369 10.6636 7.35278 10.7563 7.16724C10.8491 6.97705 10.8955 6.76599 10.8955 6.53406C10.8955 6.26501 10.8538 6.04236 10.7703 5.86609C10.6914 5.68518 10.5708 5.54834 10.4084 5.45557C10.2507 5.36279 10.0513 5.31641 9.81006 5.31641C9.6106 5.31641 9.42505 5.36047 9.25342 5.44861C9.08179 5.5321 8.94031 5.66199 8.82898 5.83826C8.72229 6.01453 8.66663 6.24646 8.66199 6.53406H6.64417C6.65808 5.89856 6.8042 5.37439 7.08252 4.96155C7.36548 4.54407 7.74353 4.2356 8.21667 4.03613C8.68982 3.83203 9.22095 3.72998 9.81006 3.72998C10.4595 3.72998 11.0161 3.83667 11.48 4.05005C11.9438 4.25879 12.2987 4.56726 12.5446 4.97546C12.7904 5.37903 12.9133 5.87073 12.9133 6.45056C12.9133 6.85413 12.8345 7.21362 12.6768 7.52905C12.519 7.83984 12.3126 8.12976 12.0575 8.3988C11.8024 8.66785 11.5217 8.94617 11.2156 9.23376C10.9512 9.47034 10.7703 9.71851 10.6729 9.97827C10.5801 10.238 10.5314 10.5465 10.5267 10.9037ZM8.5437 13.0537C8.5437 12.7568 8.64575 12.511 8.84985 12.3162C9.05396 12.1167 9.32764 12.017 9.6709 12.017C10.0095 12.017 10.2809 12.1167 10.485 12.3162C10.6937 12.511 10.7981 12.7568 10.7981 13.0537C10.7981 13.3413 10.6937 13.5848 10.485 13.7843C10.2809 13.9838 10.0095 14.0835 9.6709 14.0835C9.32764 14.0835 9.05396 13.9838 8.84985 13.7843C8.64575 13.5848 8.5437 13.3413 8.5437 13.0537Z" />
    </svg>
  );
}
