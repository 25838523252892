import { ConnectedProductStock } from "@interfaces/stock";
import style from "./ProvidersItemMainHead.module.scss";
import { HelpSvg } from "./Help.svg";

export function ProvidersItemMainHead({
  productName,
}: {
  productName: ConnectedProductStock["name"];
}) {
  return (
    <div className={style["head"]}>
      <div className={style["colName"]}>
        <p className={style["text"]}>Назва</p>
        <HelpSvg />
        <div className={style["productName"]}>
          <p>{productName}</p>
        </div>
      </div>
      <div className={style["col"]}>Варіація</div>
      <div className={style["col"]}>Наявність</div>
    </div>
  );
}
