import { ListNotifications, TopPanel } from "components/systemNotifications";
import style from "./SystemNotificationsAllPage.module.scss";
import { Helmet } from "react-helmet";

export function SystemNotificationsAllPage() {
  return (
    <main className={style["layout"]}>
      <Helmet>
        <title>Системні сповіщення</title>
      </Helmet>
      <TopPanel />
      <ListNotifications />
    </main>
  );
}
