import {
  isTypeSystemNotification,
  SystemNotificationType,
} from "@interfaces/system-notifications";
import {
  useSystemNotificationAllQuery,
  useSystemNotificationOnReadMutation,
} from "@RTKQuery/system-notifications/api";
import { Spinner } from "@ui";
import { useParams } from "react-router-dom";
import style from "./NotificationsDetails.module.scss";
import { useEffect } from "react";

export function NotificationsDetails() {
  const { notificationId } = useParams();

  const { data, isFetching, isError, error } = useSystemNotificationAllQuery(
    void 0,
    {
      selectFromResult: ({ data, ...args }) => {
        return {
          data: data?.find((item) => item.id === Number(notificationId)),
          ...args,
        };
      },
    },
  );

  const [onRead] = useSystemNotificationOnReadMutation();

  useEffect(() => {
    if (data && !data.read) {
      onRead([data.id]);
    }
  }, [data]);

  if (isFetching) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner className="fill-[#ffffff] dark:fill-[#2c2d31]" />
      </div>
    );
  } else if (isError) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <p className="text-[22px] font-medium text-[#6A6A6A] max-sm:text-[18px]">
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else {
    if (
      data &&
      isTypeSystemNotification(SystemNotificationType.HOROSHOP_IMPORT, data)
    ) {
      return (
        <div className={style["flexContainer"]}>
          <div className={style["blockInfo"]}>
            <p className={style["label"]}>Інформація про товари</p>
            <div className={style["content"]}>
              <p className={style["added"]}>
                Додано нових товарів:{" "}
                <span className={style["value"]}>
                  {data.result.products?.added || 0}
                </span>
              </p>
              <p className={style["existing"]}>
                Кількість товарів що вже існують в системі:{" "}
                <span className={style["value"]}>
                  {data.result.products?.existing || 0}
                </span>
              </p>
              {data.result.products?.errors?.length !== 0 ? (
                <div className={style["errorBox"]}>
                  <p className={style["errorListLabel"]}>
                    Список помилок, які виникли при додаванні товарів
                  </p>
                  <ol className={style["errorList"]}>
                    {data.result.products?.errors?.map((item, i) => (
                      <li key={i} className={style["errorListItem"]}>
                        {item}
                      </li>
                    ))}
                  </ol>
                </div>
              ) : null}
            </div>
          </div>
          <div className={style["blockInfo"]}>
            <p className={style["label"]}>Інформація про зображення</p>
            <div className={style["content"]}>
              <p className={style["added"]}>
                Успішно отриманих зображень:{" "}
                <span className={style["value"]}>
                  {data.result.pictures?.added || 0}
                </span>
              </p>
              {data.result.pictures?.errors?.length !== 0 ? (
                <div className={style["errorBox"]}>
                  <p className={style["errorListLabel"]}>
                    Список помилок, які виникли при отриманні зображень
                  </p>
                  <ol className={style["errorList"]}>
                    {data.result.pictures?.errors?.map((item, i) => (
                      <li key={i} className={style["errorListItem"]}>
                        {" "}
                        <span>{item}</span>{" "}
                      </li>
                    ))}
                  </ol>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex h-[100vh] w-full items-center justify-center">
          <p className="text-[22px] font-medium text-[#6A6A6A] max-sm:text-[18px]">
            Додаткова інформація відсутня.
          </p>
        </div>
      );
    }
  }
}
