import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useEffect } from "react";
import { useCategoryUpdateMutation } from "@RTKQuery/categories/api";
import { useAppSelector } from "store/hooks";
import { SubmitButton, Input } from "@ui";

const ValidationSchema = Yup.object().shape({
  nameUA: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Назва UA - обов'язкове поле"),
  nameRU: Yup.string()
    .max(36, "Максимальна довжина рядка 36 символів")
    .required("Назва RU - обов'язкове поле"),
});

export function EditCategoryForm() {
  const [
    updateCategory,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useCategoryUpdateMutation({
    fixedCacheKey: "edit-category",
  });

  const edit_category = useAppSelector(
    (state) => state.productsPage.category.edit,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        nameUA: edit_category?.name["uk"] || "",
        nameRU: edit_category?.name["ru"] || "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        if (edit_category) {
          updateCategory({
            id: edit_category.id,
            name: {
              uk: values.nameUA.trim(),
              ru: values.nameRU.trim(),
            },
            parent_id: edit_category.parent_id,
          });
        }
      }}
    >
      <Form>
        <div className="mb-[40px]">
          <div className="flex w-full gap-[15px] max-sm:flex-col max-sm:gap-[5px]">
            <div className="w-[50%] max-sm:w-[100%]">
              <Input
                formikName="nameUA"
                label="Нова назва UA"
                inputType="text"
              />
            </div>
            <div className="w-[50%] max-sm:w-[100%]">
              <Input
                formikName="nameRU"
                label="Нова назва RU"
                inputType="text"
              />
            </div>
          </div>
        </div>
        <SubmitButton
          type="submit"
          disabled={isLoading}
          isUninitialized={isUninitialized}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          uninitializedText="Зберегти"
          successText="Успішно збережено"
          errorText="Не вдалось зберегти"
          errorMesage={
            error
              ? (error as unknown as { data: { error: string } }).data?.error
              : ""
          }
        />
      </Form>
    </Formik>
  );
}
