import { SortButton } from "./components";
import style from "./TableProductHead.module.scss";

export function TableProductHead({
  sortBy,
  setSortBy,
}: {
  sortBy: string;
  setSortBy: React.Dispatch<
    React.SetStateAction<
      | ""
      | "buying_price/asc"
      | "buying_price/desc"
      | "retail_price/asc"
      | "retail_price/desc"
      | "ua_name/desc"
      | "ua_name/asc"
    >
  >;
}) {
  return (
    <div className={style["head"]}>
      <div className={style["headColumn"]}>Артикул</div>
      <div className={style["headColumn"]}>
        <button
          className={style["sortButtonContainer"]}
          onClick={() => {
            setSortBy((state) => {
              if (state === "ua_name/asc") {
                return "ua_name/desc";
              } else if (state === "ua_name/desc") {
                return "";
              } else {
                return "ua_name/asc";
              }
            });
          }}
        >
          <p>Назва</p>
          <SortButton sort={sortBy} nameCol={"ua_name"} />
        </button>
      </div>
      <div className={style["headColumn"]}>
        <button
          className={style["sortButtonContainer"]}
          onClick={() => {
            setSortBy((state) => {
              if (state === "buying_price/asc") {
                return "buying_price/desc";
              } else if (state === "buying_price/desc") {
                return "";
              } else {
                return "buying_price/asc";
              }
            });
          }}
        >
          <p>Ціна купівлі</p>
          <SortButton sort={sortBy} nameCol={"buying_price"} />
        </button>
      </div>
      <div className={style["headColumn"]}>
        <button
          className={style["sortButtonContainer"]}
          onClick={() => {
            setSortBy((state) => {
              if (state === "retail_price/asc") {
                return "retail_price/desc";
              } else if (state === "retail_price/desc") {
                return "";
              } else {
                return "retail_price/asc";
              }
            });
          }}
        >
          <p>Роздрібна ціна</p>
          <SortButton sort={sortBy} nameCol={"retail_price"} />
        </button>
      </div>
      <div className={style["headColumn"]}>Фото</div>
      <div className={style["headColumn"]}></div>
    </div>
  );
}
