import { useDraggable } from "@dnd-kit/core";
import style from "./DraggableItem.module.scss";
import { ConnectedProductStock } from "@interfaces/stock";
import cn from "classnames";
import { DragSvg } from "./Drag.svg";
import { useEffect } from "react";

export function DraggableItem({ data }: { data: ConnectedProductStock }) {
  const { listeners, setNodeRef, transform, isDragging, setActivatorNodeRef } =
    useDraggable({
      id: data.id,
      data: data,
    });

  const styleDnd = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  useEffect(() => {
    document.body.classList.add(style["noDragging"]);
  }, []);

  useEffect(() => {
    if (isDragging) {
      document.body.style.cursor = "grabbing";
    } else {
      document.body.style.cursor = "";
    }
  }, [isDragging]);

  return (
    <div
      className={cn(
        style["dragItem"],
        data.connected_to && style["dragItem_connected"],
        isDragging && "z-50",
      )}
      ref={setNodeRef}
      style={styleDnd}
    >
      <div
        className={style["dragHendler"]}
        {...listeners}
        ref={setActivatorNodeRef}
      >
        <DragSvg />
      </div>
      <p className={style["variationText"]}>{data.variation}</p>
      <div className="w-[18px]"></div>
    </div>
  );
}
