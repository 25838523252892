import {
  HistoryBillingItem,
  HistoryBillingBody,
  HistoryBillingWrapper,
  HistoryBillingHead,
} from "./components";

export function HistoryBilling() {
  return (
    <HistoryBillingWrapper>
      <>
        <HistoryBillingHead />
        <HistoryBillingBody>
          {(data) => <HistoryBillingItem key={data.id} data={data} />}
        </HistoryBillingBody>
      </>
    </HistoryBillingWrapper>
  );
}
