import { ProductPropertyValue } from "@interfaces/product-characteristics";
import { ValueButtons, ValueCheckbox } from "./components";

import style from "./TableValuesItem.module.scss";

export function TableValuesItem({ value }: { value: ProductPropertyValue }) {
  return (
    <div className={style["item"]}>
      <ValueCheckbox value={value} />
      <p className={style["value"]}> {value.name["ua"]}</p>
      <p className={style["value"]}> {value.name["ru"]}</p>
      <ValueButtons value={value} />
    </div>
  );
}
