import { Button } from "@ui";
import { Link, useParams } from "react-router-dom";

import style from "./MarketplacesTabs.module.scss";

export function MarketplacesTabs() {
  const { marketplaceId } = useParams();

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  return (
    <div className={style["tabs"]}>
      <div className={style["buttonContainer"]}>
        <Link to={crm + "/unloading/0"}>
          <Button
            size="big"
            text="Horoshop"
            variant={Number(marketplaceId) === 0 ? "contained" : "outlined"}
            onClick={() => {}}
          />
        </Link>
      </div>
    </div>
  );
}
