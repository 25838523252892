import { Link } from "react-router-dom";
import { RegisterForm } from "@forms";

import style from "./RegisterPage.module.scss";
import { Helmet } from "react-helmet";

export function RegisterPage() {
  return (
    <main className={style["page"]}>
      <Helmet>
        <title>Реєстрація</title>
      </Helmet>
      <div className={style["limit"]}>
        <h3 className={style["title"]}>Реєстрація</h3>
        <RegisterForm />
        <p className="text-center">
          <Link
            to={
              process.env.NODE_ENV === "development"
                ? "/account/auth/login"
                : "/auth/login"
            }
            className={style["loginLink"]}
          >
            В мене вже є акаунт
          </Link>
        </p>
      </div>
    </main>
  );
}
