import { useState } from "react";
import {
  TableProductsWrapper,
  TableProductsBody,
  TableProductsHead,
  TableProductsItem,
} from "./components";

export function TableProducts() {
  const [sortBy, setSortBy] = useState<
    | "price/asc"
    | "price/desc"
    | "category/asc"
    | "category/desc"
    | "ua_name/desc"
    | "ua_name/asc"
    | ""
  >("");

  return (
    <TableProductsWrapper>
      <>
        <TableProductsHead sortBy={sortBy} setSortBy={setSortBy} />
        <TableProductsBody
          sortBy={sortBy.split("/")[0]}
          order={sortBy.split("/")[1]}
        >
          {(data) => <TableProductsItem key={data.id} data={data} />}
        </TableProductsBody>
      </>
    </TableProductsWrapper>
  );
}
