import { setActivatedChars, setActivatedVariations } from "@global-state/pages/product/slice";
import { ProductExtended, SelectableParameter } from "@interfaces/product";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "store/hooks";

export function EditMod({
  dataProductCharacterictics,
  children,
}: {
  children: (templateData: SelectableParameter[]) => JSX.Element;
  dataProductCharacterictics: ProductExtended["properties"];
}) {
  const selectebleData: SelectableParameter[] = useMemo<
    SelectableParameter[]
  >(() => {
    return dataProductCharacterictics.map((item) => {
      let countValueSelect = 0;

      return {
        id: item.id,
        multi_selection: item.multi_selection,
        required: item.required,
        name: item.name,
        values: item.values.map<SelectableParameter["values"][0]>((value) => {
          countValueSelect += Number(value.selected);

          return {
            id: value.id,
            name: value.value,
            selected: value.selected,
          };
        }),
        selected: countValueSelect === item.values.length,
      };
    });
  }, [dataProductCharacterictics]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActivatedChars(true));
    dispatch(setActivatedVariations(true));
  })

  return (
    <div className="mb-[20px]">
      {children(selectebleData)}
    </div>
  );
}
