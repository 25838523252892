import { ProductChildren, ProductExtended, ProductTable } from "@interfaces/product";
import { authApi } from "..";
import { ProductStock, ConnectedProduct, ProductProvider, ConnectedProductStock } from "@interfaces/stock";
import { Pagination } from "@interfaces/shared";

const stockApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        productStockOne: build.query<ProductChildren<ProductStock>, ProductTable["id"]>({
            query: (productId) => "stock/" + productId,
        }),
        productStockOneConnectedProducts: build.query<ConnectedProduct[], ProductTable["id"]>({
            query: (productId) => "stock/" + productId + "/easydrop",
            transformResponse(responce: any[], meta, arg) {
                return responce.map(item => ({
                    ...item,
                    stock: item.stock.map((i: any) => ({
                        ...i,
                        id: i.variation_id
                    }))
                })) as ConnectedProduct[]
            },
        }),
        productsProviders: build.query<Pagination<{ products: ProductProvider[] }>, string>({
            query: (search) => "stock/easydrop/products" + (search ? `?search=${search}` : ""),
            providesTags: (result, error, search) =>
                result
                  ? [
                    {
                      type: "ProductsProviders" as const,
                      id: search,
                    },
                    { type: "ProductsProviders", id: "PRODUCT-PROV-LIST" },
                  ]
                  : [{ type: "ProductsProviders", id: "PRODUCT-PROV-LIST" }],
        }),
        stockUpdate: build.mutation<ProductChildren<ProductStock>, Pick<ProductStock, "id" | "qty"> & { parentId: number }>({
            query: ({ id, qty }) => {
                return {
                    url: "stock/" + id,
                    method: "PUT",
                    body: { qty }
                }
            },
            async onQueryStarted({ parentId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        stockApi.util.upsertQueryData(
                            "productStockOne",
                            parentId,
                            data
                        ),
                    );
                } catch { }
            },
        }),
        productProviderDelete: build.mutation<ProductChildren<ProductStock>, {
            parent_id: ProductTable["id"]
            block_id: ConnectedProduct["block_id"],
        }>({
            query: (data) => {
                return {
                    url: `stock/${data.parent_id}?items=${data.block_id}`,
                    method: "DELETE"
                }
            },
            async onQueryStarted({ parent_id, block_id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        stockApi.util.upsertQueryData(
                            "productStockOne",
                            parent_id,
                            data
                        ),
                    );
                    dispatch(
                        stockApi.util.updateQueryData(
                            "productStockOneConnectedProducts",
                            parent_id,
                            (draft) => draft.filter(item => item.block_id !== block_id),
                        ),
                    );
                } catch { }
            },
            invalidatesTags: [{ type: "ProductsProviders", id: "PRODUCT-PROV-LIST" }],
        }),
        productProviderAdd: build.mutation<{
            stock: ProductChildren<ProductStock>,
            easydrop: ConnectedProduct[]
        }, {
            supplier_id: ProductProvider["supplier_id"],
            product_id: ProductProvider["id"],
            parent_id: ProductExtended["id"],
            searchText: string
        }>({
            query: ({ supplier_id, product_id, parent_id }) => {
                return {
                    url: `stock/${parent_id}/easydrop`,
                    method: "POST",
                    body: { supplier_id, product_id }
                }
            },
            async onQueryStarted({ parent_id, product_id, searchText }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        stockApi.util.upsertQueryData(
                            "productStockOne",
                            parent_id,
                            data.stock
                        ),
                    );
                    dispatch(
                        stockApi.util.upsertQueryData(
                            "productStockOneConnectedProducts",
                            parent_id,
                            data.easydrop.map(item => ({
                                ...item,
                                stock: item.stock.map((i: any) => ({
                                    ...i,
                                    id: i.variation_id
                                }))
                            })) as ConnectedProduct[]
                        ),
                    );
                    dispatch(
                        stockApi.util.updateQueryData(
                            "productsProviders",
                            searchText,
                            (draft) => {
                                draft.products = draft.products.filter(item => item.id !== product_id)
                            }
                        ),
                    );
                } catch { }
            },
        }),
        productProviderConnecting: build.mutation<{
            stock: ProductChildren<ProductStock>,
            easydrop: ConnectedProduct[]
        }, {
            parent_id: ProductExtended["id"],
            block_id: ConnectedProduct["block_id"],
            products: {
                id: ConnectedProductStock["id"],
                connected_to: ConnectedProductStock["connected_to"]
            }[]
        }>({
            query: ({ parent_id, ...body }) => {
                return {
                    url: `stock/${parent_id}/easydrop`,
                    method: "PUT",
                    body
                }
            },
            async onQueryStarted({ parent_id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        stockApi.util.upsertQueryData(
                            "productStockOne",
                            parent_id,
                            data.stock
                        ),
                    );
                    dispatch(
                        stockApi.util.upsertQueryData(
                            "productStockOneConnectedProducts",
                            parent_id,
                            data.easydrop.map(item => ({
                                ...item,
                                stock: item.stock.map((i: any) => ({
                                    ...i,
                                    id: i.variation_id
                                }))
                            })) as ConnectedProduct[]
                        ),
                    );
                } catch { }
            },
        })
    }),
    overrideExisting: false,
});

export const {
    useProductStockOneQuery,
    useProductStockOneConnectedProductsQuery,
    useLazyProductsProvidersQuery,
    useStockUpdateMutation,
    useProductProviderAddMutation,
    useProductProviderConnectingMutation,
    useProductProviderDeleteMutation
} = stockApi;