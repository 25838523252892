import {
  referenceBook_deleteValues_addData,
  referenceBook_deleteValues_deleteData,
  referenceBook_editValues_addData,
  referenceBook_editValues_deleteData,
} from "@global-state/pages/productProperties/slice";
import { ProductPropertyValue } from "@interfaces/product-characteristics";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function ValueCheckbox({ value }: { value: ProductPropertyValue }) {
  const delete_values_ids = useAppSelector(
    (state) => state.productPropertiesPage.delete_values,
  ).map((item) => item.id);
  const edit_values_ids = useAppSelector(
    (state) => state.productPropertiesPage.edit_values,
  ).map((item) => item.id);

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        delete_values_ids.includes(value.id) &&
        edit_values_ids.includes(value.id)
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            referenceBook_deleteValues_addData([
              {
                id: value.id,
                tag: "multiple",
              },
            ]),
          );
          dispatch(
            referenceBook_editValues_addData([
              {
                id: value.id,
                name: value.name,
                tag: "multiple",
              },
            ]),
          );
        } else {
          dispatch(
            referenceBook_deleteValues_deleteData({
              id: value.id,
              singleOnly: false,
            }),
          );
          dispatch(
            referenceBook_editValues_deleteData({
              id: value.id,
              singleOnly: false,
            }),
          );
        }
      }}
    />
  );
}
