import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./SearchTitle.module.scss";
import { SearchInput } from "./components";
import { setSearchTextProductProvider } from "@global-state/pages/stock/slice";

export function SearchTitle() {
  const searchText = useAppSelector(
    (state) => state.stockPage.searchTextProductProvider,
  );

  const dispatch = useAppDispatch();

  return (
    <div className={style["flexContainer"]}>
      <p className={style["title"]}>Додавання товару</p>
      <SearchInput
        value={searchText}
        setValue={(newValue) => {
          dispatch(setSearchTextProductProvider(newValue));
        }}
      />
    </div>
  );
}
