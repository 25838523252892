import { Button } from "@ui";
import style from "./ListValues.module.scss";
import { CrossSvg } from "./Cross.svg";
import cn from "classnames";

export function ListValues({
  listValue,
  onDelete,
}: {
  listValue: string[];
  onDelete: (value: string) => void;
}) {
  return (
    <div className={style["listValues"]}>
      {listValue.map((curentItem, index: number) => (
        <div key={index} className={cn(style["value"], "p-[5px]")}>
          <span>{curentItem}</span>
          <Button
            type="delete"
            icon={(className, fill) => (
              <CrossSvg className={cn(className, fill)} />
            )}
            onClick={() => {
              onDelete(curentItem);
            }}
          />
        </div>
      ))}
    </div>
  );
}
