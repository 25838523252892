import { useEffect, useState } from "react";
import { SpoilerButton } from "../buttons/SpoilerButton";
import cn from "classnames";

import style from "./Select.module.scss";
import { MdOutlineErrorOutline } from "react-icons/md";
import { Button } from "../buttons";

interface Value {
  id: number;
  value: string;
  label: string;
}

export function SelectNoFormik({
  currentValue,
  setCurrentValue,
  listValue,
  label,
  adaptive,
  disabled,
  isError,
  errorText,
  addItemButtonFunc,
  listPosition =  "bottom"
}: {
  currentValue: Value;
  setCurrentValue: (newValue: Value) => void;
  listValue: Value[];
  label?: JSX.Element | string;
  adaptive?: boolean;
  disabled?: boolean;
  isError?: boolean;
  errorText?: JSX.Element | string;
  addItemButtonFunc?: () => void;
  listPosition?: "top" | "bottom"
}) {
  const [showListValue, setShowListValue] = useState<boolean>(false);

  useEffect(() => {
    function eventAction(e: any) {
      if (!e.target.closest("[data-select]")) {
        setShowListValue(false);
      }
    }

    document.body.addEventListener("click", eventAction);

    return () => {
      document.body.removeEventListener("click", eventAction);
    };
  }, []);

  return (
    <div
      className={cn(
        adaptive ? style["adaptive"] : "",
        disabled && "opacity-45",
        isError && style["error"],
      )}
    >
      {label ? (
        typeof label === "string" ? (
          <p className={style["label"]}>{label}</p>
        ) : (
          label
        )
      ) : null}
      <div className="relative">
        <div
          data-select
          className={cn(
            style["container"],
            showListValue && "!border-input-main",
          )}
          onClick={() => {
            if (!disabled) {
              setShowListValue((state) => !state);
            }
          }}
        >
          <input
            className={style["input"]}
            type="text"
            value={currentValue.label}
            readOnly
          />
          <div className="flex items-center">
            <div
              className={cn(
                style["hint"],
                showListValue && "invisible opacity-0",
                isError && "block",
              )}
            >
              <MdOutlineErrorOutline />
              <div className={style["descr"]}>
                <p>{errorText}</p>
              </div>
            </div>
            <SpoilerButton isOpen={showListValue} />
          </div>
        </div>
        <div
          className={cn(style["listValues"], showListValue ? "flex" : "hidden", (listPosition === "top" && style["listValues_positionTop"]))}
        >
          {listValue.map((item) => (
            <div
              key={item.id}
              className={cn(
                style["value"],
                item.value === currentValue.value && style["value_active"],
              )}
              data-value={item.value}
              onClick={() => {
                setShowListValue(false);
                setCurrentValue(item);
              }}
            >
              {item.label}
            </div>
          ))}
          {addItemButtonFunc ? (
            <Button
              type="add"
              size="big"
              customStyle={{
                button: "h-[30px] shrink-0",
              }}
              onClick={() => {
                addItemButtonFunc();
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
