import { FaUser } from "react-icons/fa6";
import { LogoutButton } from "..";

import style from "./ProfileBurger.module.scss";
import { useCRMUserQuery } from "@RTKQuery/CRMUser/api";

export function ProfileBurger() {
  const { data } = useCRMUserQuery();

  return (
    <div className={style["profile"]}>
      <div className={style["info"]}>
        <div className={style["role"]}>{data?.role.title.toLowerCase()}</div>
        <div className={style["box"]}>
          <div className={style["avatar"]}>
            <FaUser className="h-full w-full fill-[#bcc7db]" />
          </div>
          <div
            className={style["username"]}
            title={data?.user.last_name + " " + data?.user.first_name}
          >
            {data?.user.last_name} {data?.user.first_name}
          </div>
        </div>
      </div>
      <div className={style["button"]}>
        <LogoutButton />
      </div>
    </div>
  );
}
