import { createSlice } from "@reduxjs/toolkit";
import { UnloadingPageData } from "./interface";

import { FileFormat, FormulaMarkup, ProductHoroshop, UnloadingLink } from "@interfaces/unloading";
import Category from "@interfaces/category";
import { SliceAction } from "store/store";

const initialState: UnloadingPageData = {
    unloadingInfoObject: {
        name: "",
        file_format: FileFormat.CSV,
        formula_markup: [
            {
                id: crypto.randomUUID(),
                from_price: 0,
                to_price: 0,
                mark_up: 0
            },
            {
                id: crypto.randomUUID(),
                from_price: 0,
                to_price: null,
                mark_up: 0
            }
        ]
    },
    selectUnloading: -1,
    selectProduct: [],
    selectProduct_Categories: {},
    formulaMarkupErrors: {},
    selectCategories: [],
    activeCategory: 0,
    isValidData: false
}

const unloadingPageSlice = createSlice({
    initialState,
    name: "unloadingPage",
    reducers: {
        setAuthenticUnloadingObject: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]>) => {
            state.unloadingInfoObject = action.payload;
        },
        switchUseMaxDropPrice: (state) => {
            state.unloadingInfoObject.use_max_drop_price = !state.unloadingInfoObject.use_max_drop_price;
        },
        switchUseRetailPrice: (state) => {
            state.unloadingInfoObject.use_retail_price = !state.unloadingInfoObject.use_retail_price;
        },
        selectFileFormat: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]["file_format"]>) => {
            state.unloadingInfoObject.file_format = action.payload;
        },
        switchUseFixedMarkup: (state) => {
            state.unloadingInfoObject.use_fixed_markup = !state.unloadingInfoObject.use_fixed_markup;
            state.unloadingInfoObject.use_percent_markup = false;
        },
        setFixedMarkup: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]["fixed_markup"]>) => {
            state.unloadingInfoObject.fixed_markup = action.payload;
        },
        switchUsePercentMarkup: (state) => {
            state.unloadingInfoObject.use_percent_markup = !state.unloadingInfoObject.use_percent_markup;
            state.unloadingInfoObject.use_fixed_markup = false;
        },
        setPercentMarkup: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]["percent_markup"]>) => {
            state.unloadingInfoObject.percent_markup = action.payload;
        },


        switchUseFormulaMarkup: (state) => {
            state.unloadingInfoObject.use_formula_markup = !state.unloadingInfoObject.use_formula_markup;
        },

        setFormulaMarkupToPrice: (state, action: SliceAction<{
            value: FormulaMarkup["to_price"],
            indexRange: number
        }>) => {
            if (state.unloadingInfoObject.formula_markup) {
                state.unloadingInfoObject.formula_markup[action.payload.indexRange].to_price = action.payload.value;
                if (state.unloadingInfoObject.formula_markup[action.payload.indexRange + 1] && action.payload.value) {
                    state.unloadingInfoObject.formula_markup[action.payload.indexRange + 1].from_price = action.payload.value;
                }
            }
        },
        setFormulaMarkupMarkUp: (state, action: SliceAction<{
            value: FormulaMarkup["mark_up"],
            indexRange: number
        }>) => {
            if (state.unloadingInfoObject.formula_markup) {
                state.unloadingInfoObject.formula_markup[action.payload.indexRange].mark_up = action.payload.value;
            }
        },
        addFormulaMarkupRange: (state) => {
            if (state.unloadingInfoObject.formula_markup) {
                state.unloadingInfoObject.formula_markup[state.unloadingInfoObject.formula_markup?.length - 1].to_price = 0;
                state.unloadingInfoObject.formula_markup.push({
                    id: crypto.randomUUID(),
                    from_price: 0,
                    to_price: null,
                    mark_up: 0
                })
            }
        },
        deleteFormulaMarkupRange: (state, action: SliceAction<number>) => {
            if (state.unloadingInfoObject.formula_markup) {
                state.unloadingInfoObject.formula_markup[action.payload + 1].from_price = state.unloadingInfoObject.formula_markup[action.payload - 1].to_price || 0;
                state.unloadingInfoObject.formula_markup.splice(action.payload, 1);
            }
        },

        addFormulaMarkupErrors: (state, action: SliceAction<{
            errorText: string,
            idRange: FormulaMarkup["id"]
        }>) => {
            state.formulaMarkupErrors[action.payload.idRange] = action.payload.errorText;
        },
        removeFormulaMarkupErrors: (state, action: SliceAction<FormulaMarkup["id"]>) => {
            state.formulaMarkupErrors[action.payload] = "";
        },


        setCategories: (state, action: SliceAction<Category["id"][]>) => {
            state.selectCategories = action.payload;
        },
        clearCategories: (state) => {
            state.selectCategories = [];
        },
        addCategories: (state, action: SliceAction<Category["id"]>) => {
            state.selectCategories.push(action.payload);
        },
        removeCategories: (state, action: SliceAction<Category["id"]>) => {
            state.selectCategories = state.selectCategories.filter(item => item !== action.payload);
        },

        setActiveCategory: (state, actions: SliceAction<Category["id"]>) => {
            state.activeCategory = actions.payload;
        },

        setUnloadingName: (state, action: SliceAction<UnloadingPageData["unloadingInfoObject"]["name"]>) => {
            state.unloadingInfoObject.name = action.payload;
        },
        setSelectProduct: (state, action: SliceAction<ProductHoroshop[]>) => {
            state.selectProduct = [];
            state.selectProduct_Categories = [];

            action.payload.forEach(item => {
                state.selectProduct.push(item.id);
                state.selectProduct_Categories[item.id] = item.categories?.map(item => item.name) || []
            })
        },


        clearSelectProduct: (state) => {
            state.selectProduct = [];
        },
        addSelectProduct: (state, action: SliceAction<ProductHoroshop>) => {
            state.selectProduct.push(action.payload.id);
            state.selectProduct_Categories[action.payload.id] = action.payload.categories?.map(item => item.name) || []
        },
        removeSelectProduct: (state, action: SliceAction<ProductHoroshop>) => {
            state.selectProduct = state.selectProduct.filter(item => item !== action.payload.id);
            delete state.selectProduct_Categories[action.payload.id];
        },

        setSelectUnloading: (
            state,
            action: SliceAction<UnloadingLink["id"]>,
        ) => {
            state.selectUnloading = action.payload;
        },
        clearSelectUnloading: (state) => {
            state.selectUnloading = -1;
        },


        validateAllData: (state) => {
            if (state.unloadingInfoObject.use_formula_markup) {
                state.unloadingInfoObject.formula_markup?.forEach((item) => {
                    if (item.to_price === null) {
                        state.formulaMarkupErrors[item.id] = ""
                    } else {
                        if (item.from_price >= item.to_price) {
                            state.formulaMarkupErrors[item.id] = `Значення повинно бути більшим за: ${item.from_price}`;
                        } else {
                            state.formulaMarkupErrors[item.id] = ""
                        }
                    }
                })

                if (Object.values(state.formulaMarkupErrors).every(item => !item)) {
                    state.isValidData = true;
                } else {
                    state.isValidData = false;
                }
            } else {
                state.isValidData = true;
            }
        },

        validateAllDataReset: (state) => {
            state.isValidData = false;
        },


        clearAllUnloadingData: (state) => {
            state.unloadingInfoObject = {
                name: "",
                file_format: FileFormat.CSV,
                formula_markup: [
                    {
                        id: crypto.randomUUID(),
                        from_price: 0,
                        to_price: 0,
                        mark_up: 0
                    },
                    {
                        id: crypto.randomUUID(),
                        from_price: 0,
                        to_price: null,
                        mark_up: 0
                    }
                ]
            }
            state.formulaMarkupErrors = {};
            state.selectProduct = [];
            state.selectUnloading = -1;
            state.isValidData = false;
            state.selectCategories = [];
            state.selectProduct_Categories = [];
            state.activeCategory = 0;
        }
    }
})

const { reducer, actions } = unloadingPageSlice;

export default reducer;

export const {
    setAuthenticUnloadingObject,
    switchUseMaxDropPrice,
    switchUseRetailPrice,
    selectFileFormat,
    switchUseFixedMarkup,
    switchUsePercentMarkup,
    setFixedMarkup,
    setPercentMarkup,
    switchUseFormulaMarkup,
    setFormulaMarkupToPrice,
    setFormulaMarkupMarkUp,
    addFormulaMarkupRange,
    deleteFormulaMarkupRange,

    addFormulaMarkupErrors,
    removeFormulaMarkupErrors,

    setCategories,
    clearCategories,
    addCategories,
    removeCategories,

    setActiveCategory,

    setUnloadingName,
    addSelectProduct,
    removeSelectProduct,
    setSelectProduct,
    clearSelectProduct,
    setSelectUnloading,
    clearSelectUnloading,
    clearAllUnloadingData,
    validateAllData,
    validateAllDataReset
} = actions;