import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useProductUpdateVariationsMutation } from "@RTKQuery/products/api";
import {
  BasicInputProps,
  Button,
  Input,
  MultipleInput,
  SubmitButton,
} from "@ui";
import { useAppSelector } from "store/hooks";
import { useEffect, useRef } from "react";

import style from "./AddVariationForm.module.scss";

export function AddVariationForm() {
  const [
    updateVariations,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useProductUpdateVariationsMutation({
    fixedCacheKey: "update-product-variations",
  });

  const authenticProductObject = useAppSelector(
    (state) => state.productPage.authenticProductObject,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  const listVariationsRef = useRef<string[]>([]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        friends: authenticProductObject?.variations.map((item) => ({
          name: item.name,
          isExists: true,
          values: item.values.map((item) => ({
            value: item,
            isExists: true,
          })),
        })) || [
          {
            name: "",
            isExists: true,
            values: [],
          },
        ],
      }}
      validationSchema={Yup.object().shape({
        friends: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .max(80, "Максимальна довжина рядка 80 символів")
              .test(
                "is-includes",
                "Таке значення уже існує",
                (value, context) =>
                  listVariationsRef.current.filter((item) => item === value)
                    .length === 1,
              )
              .required("Назва - обов'язкове поле"),
            values: Yup.array().of(
              Yup.object().shape({
                value: Yup.string().max(
                  80,
                  "Максимальна довжина рядка 80 символів",
                ),
              }),
            ),
          }),
        ),
      })}
      onSubmit={(values, actions) => {
        if (authenticProductObject) {
          updateVariations({
            product: authenticProductObject,
            variations: values.friends.map((item) => ({
              name: item.name.trim(),
              values: item.values.map((item) => item.value.trim()),
            })),
          })
            .unwrap()
            .then(() => {
              actions.resetForm();
            })
            .catch(() => {});
        }
      }}
    >
      {({ values }) => {
        listVariationsRef.current = values.friends.map((item) => item.name);
        return (
          <Form>
            <FieldArray name="friends">
              {({ remove, push, ...attr }) => {
                return (
                  <>
                    <div className="scroll mb-[40px] max-h-[380px] overflow-auto pr-[5px]">
                      {values.friends.length > 0 &&
                        values.friends.map((friend, index) => (
                          <div
                            key={index}
                            className="mb-[10px] flex gap-[15px]"
                          >
                            <div className={style["listVariation"]}>
                              <div className="w-[25%]">
                                <Input<BasicInputProps>
                                  formikName={`friends.${index}.name`}
                                  label="Назва"
                                  inputType="text"
                                  readOnly={friend.isExists}
                                  helperTextVisibility={!friend.isExists}
                                  className={style["customInput"]}
                                />
                              </div>
                              <div className="flex w-[75%] items-center gap-[20px]">
                                <div className="grow">
                                  <MultipleInput
                                    formikName={`friends.${index}.values`}
                                    label="Значення"
                                  />
                                </div>
                                {!friend.isExists ? (
                                  <div className="pt-[20px]">
                                    <Button
                                      type="delete"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        remove(index);
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="flex gap-[20px] max-sm:flex-col">
                      <div className="w-[60%] max-sm:h-[40px] max-sm:w-full">
                        <Button
                          type="add"
                          size="big"
                          variant="outlined"
                          onClick={(e) => {
                            e.preventDefault();

                            push({
                              name: "",
                              isExists: false,
                              values: [],
                            });
                          }}
                        />
                      </div>
                      <div className="w-[40%] max-sm:w-full">
                        <SubmitButton
                          type="submit"
                          disabled={isLoading}
                          isUninitialized={isUninitialized}
                          isLoading={isLoading}
                          isSuccess={isSuccess}
                          isError={isError}
                          uninitializedText="Зберегти"
                          successText="Успішно збережено"
                          errorText="Не вдалось зберегти"
                          errorMesage={
                            error
                              ? (
                                  error as unknown as {
                                    data: { error: string };
                                  }
                                ).data?.error
                              : ""
                          }
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            </FieldArray>
          </Form>
        );
      }}
    </Formik>
  );
}
