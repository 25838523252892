import cn from "classnames";
import style from "./InputNoFormik.module.scss";
import { RxCrossCircled } from "react-icons/rx";
import { useEffect, useState } from "react";

export function InputNoFormik({
  defaultValue,
  setValue,
  validate,
  label,
  placeholder,
  disabled,
  error,
  customStyle = {
    height: "",
  },
}: {
  defaultValue?: string;
  setValue: (value: string) => void;
  validate?: (value: string) => string;
  label?: string | JSX.Element;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  customStyle?: {
    height: string;
  };
}) {
  const [localValue, setLocalValue] = useState<string>("");

  return (
    <div className={cn(disabled && style["disabled"])}>
      {typeof label === "string" ? (
        <p className={style["label"]}>{label}</p>
      ) : (
        label
      )}
      <div
        className={cn(
          style["container"],
          error ? style["error_on"] : style["error_off"],
        )}
      >
        <input
          className={cn(style["input"], customStyle.height)}
          type="text"
          placeholder={placeholder || ""}
          disabled={disabled}
          value={localValue || defaultValue}
          onChange={(e) => {
            const value = validate ? validate(e.target.value) : e.target.value;

            setLocalValue(value);
            setValue(value);
          }}
        />
        <button
          disabled={disabled}
          className={cn(
            style["clearButton"],
            disabled && style["clearButton_disabled"],
          )}
        >
          {localValue ? (
            <RxCrossCircled
              className={style["icon"]}
              onClick={() => {
                setLocalValue("");
              }}
            />
          ) : null}
        </button>
      </div>
    </div>
  );
}
