import { showModal } from "@global-state/entity/modal-state/slice";
import { product_selected_variation_addData } from "@global-state/pages/product/slice";
import { Button } from "@ui";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";

export function VariationsActions({
  parent,
  variationId,
}: {
  parent?: boolean;
  variationId: number | undefined;
}) {
  const { categoryId, productId } = useParams();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  return (
    <>
      <Button
        type="edit"
        onClick={() => {
          if (parent) {
            navigate(crm + `/catalog/${categoryId}/product/${productId}`);
          } else {
            navigate(
              crm +
                `/catalog/${categoryId}/product/${productId}/variation/${variationId}`,
            );
          }
        }}
      />
      {!parent && (
        <Button
          type="delete"
          onClick={() => {
            dispatch(
              showModal({
                modalName: "DeleteVariationModal",
                mode: "single",
              }),
            );
            dispatch(
              product_selected_variation_addData({
                childIds: [
                  {
                    id: variationId || -1,
                    tag: "single",
                  },
                ],
                parentId: Number(productId || "-1"),
              }),
            );
          }}
        />
      )}
    </>
  );
}
