export function BalanceSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
    >
      <path d="M9.2931 15.9773C8.78771 15.9773 8.36654 15.9007 8.02961 15.7474C7.69268 15.5877 7.43998 15.3642 7.27152 15.0768C7.10305 14.783 7.01882 14.4349 7.01882 14.0325C7.01882 13.9431 7.02194 13.8568 7.02818 13.7738C7.04066 13.6844 7.05938 13.5982 7.08433 13.5151H6.62573V12.8062H7.43998C7.49614 12.7359 7.55853 12.672 7.62717 12.6146C7.6958 12.5507 7.76755 12.49 7.84243 12.4325C7.92354 12.375 8.00777 12.3208 8.09513 12.2697C8.18248 12.2122 8.27295 12.1611 8.36654 12.1164H6.62573V11.4074H9.7517C9.83282 11.3308 9.89209 11.2445 9.92953 11.1487C9.9732 11.0465 9.99504 10.9316 9.99504 10.8038C9.99504 10.6633 9.96072 10.5452 9.89209 10.4493C9.8297 10.3535 9.7361 10.2801 9.61131 10.229C9.48653 10.1779 9.33054 10.1523 9.14336 10.1523C8.97489 10.1523 8.80642 10.1683 8.63796 10.2002C8.47573 10.2322 8.30415 10.2769 8.1232 10.3344C7.94226 10.3919 7.74884 10.4653 7.54293 10.5547L7.12177 9.42422C7.44622 9.27732 7.78315 9.16235 8.13256 9.07932C8.48821 8.9899 8.86258 8.94519 9.25567 8.94519C9.69243 8.94519 10.0668 9.01864 10.3788 9.16554C10.697 9.30606 10.9434 9.51683 11.1181 9.79786C11.2928 10.0789 11.3802 10.4238 11.3802 10.8326C11.3802 10.9348 11.374 11.0338 11.3615 11.1296C11.349 11.2254 11.3303 11.318 11.3053 11.4074H11.7826V12.1164H10.9122C10.8436 12.193 10.7656 12.2665 10.6783 12.3367C10.5971 12.4006 10.5098 12.4613 10.4162 12.5188C10.3289 12.5698 10.2353 12.6209 10.1354 12.672C10.0418 12.7168 9.94513 12.7615 9.84529 12.8062H11.7826V13.5151H8.5818C8.51941 13.5854 8.47261 13.6652 8.44142 13.7547C8.41646 13.8377 8.40398 13.9303 8.40398 14.0325C8.40398 14.1858 8.4383 14.3199 8.50693 14.4349C8.5818 14.5435 8.69099 14.6297 8.8345 14.6935C8.98425 14.751 9.16519 14.7798 9.37734 14.7798C9.71427 14.7798 10.045 14.7415 10.3694 14.6648C10.6939 14.5818 11.0183 14.4732 11.3428 14.3391V15.5462C11.0745 15.6804 10.7656 15.7857 10.4162 15.8624C10.0668 15.939 9.69243 15.9773 9.2931 15.9773Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.52886 1.20984H11.4769C12.7442 1.20984 12.0482 3.16009 11.5351 4.3497L10.9512 5.71394L10.8723 5.89794C11.4254 5.87041 11.9724 6.0275 12.431 6.34566C14.1467 7.95579 15.5392 9.89432 16.5301 12.0523C16.9161 12.8502 17.0693 13.7456 16.9711 14.6307C16.8532 16.8867 15.0885 18.6845 12.8881 18.7902H5.1176C2.91665 18.6877 1.14931 16.8918 1.02892 14.6354C0.930696 13.7503 1.08387 12.8549 1.47002 12.0569C2.46201 9.8969 3.85641 7.95661 5.57469 6.34566C6.03338 6.0275 6.58027 5.87041 7.13333 5.89794L7.04191 5.68581L6.47058 4.3497C5.95978 3.16009 5.26043 1.20984 6.52886 1.20984Z"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M10.9602 6.77691C11.4456 6.77691 11.8392 6.38335 11.8392 5.89789C11.8392 5.41242 11.4456 5.01887 10.9602 5.01887V6.77691ZM7.22118 5.01887C6.73574 5.01887 6.34219 5.41242 6.34219 5.89789C6.34219 6.38335 6.73574 6.77691 7.22118 6.77691V5.01887ZM14.1004 5.48754C14.521 5.24521 14.6657 4.70776 14.4233 4.28709C14.1809 3.86643 13.6435 3.72186 13.2229 3.96419L14.1004 5.48754ZM11.0391 5.71388L11.2087 6.57639L11.2112 6.57588L11.0391 5.71388ZM7.12977 5.68575L7.31178 4.82573L7.30381 4.82411L7.12977 5.68575ZM4.94499 3.95651C4.52009 3.72169 3.98528 3.87578 3.75046 4.30068C3.51564 4.72558 3.66972 5.26039 4.09463 5.49522L4.94499 3.95651ZM10.9602 5.01887H7.22118V6.77691H10.9602V5.01887ZM13.2229 3.96419C12.488 4.38748 11.6927 4.68699 10.8669 4.85188L11.2112 6.57588C12.2254 6.37336 13.2009 6.00575 14.1004 5.48754L13.2229 3.96419ZM10.8695 4.85138C9.69346 5.08264 8.48463 5.0739 7.31178 4.82573L6.94782 6.54573C8.35231 6.84291 9.80023 6.85334 11.2087 6.57639L10.8695 4.85138ZM7.30381 4.82411C6.48019 4.65784 5.68507 4.36552 4.94499 3.95651L4.09463 5.49522C4.99101 5.9906 5.95549 6.34542 6.95583 6.54739L7.30381 4.82411Z" />
    </svg>
  );
}
