import { hideModal } from "@global-state/entity/modal-state/slice";
import { clearFunctionalityData } from "@global-state/pages/settings.additionalServices/slice";
import { FunctionalitiesTariff } from "@interfaces/functionalities";
import { useFunctionalitiesActivateMutation } from "@RTKQuery/functionalities/api";
import { Radio, SubmitButton } from "@ui";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./ServicesActivateModal.module.scss";

const MODAL_NAME = "ServicesActivateModal";

export function ServicesActivateModal() {
  const [
    activate,
    { isSuccess, isError, isLoading, isUninitialized, reset, error },
  ] = useFunctionalitiesActivateMutation();

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);
  const setFunctionalityData = useAppSelector(
    (state) => state.settingsAdditionalServicesPage.functionalityData,
  );

  const [selectTariff, setSelectTariff] =
    useState<FunctionalitiesTariff["id"]>(-1);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (setFunctionalityData) {
      setSelectTariff(setFunctionalityData.tariffs[0].id);
    }
  }, [setFunctionalityData]);

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    dispatch(clearFunctionalityData());
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Оберіть тариф"
    >
      <>
        <div className={style["gridContainer"]}>
          <div className={style["head"]}>
            <div className={style["colHead"]}></div>
            <div className={style["colHead"]}>Опис</div>
            <div className={style["colHead"]}>Обмеження</div>
            <div className={style["colHead"]}>Ціна</div>
          </div>
          {setFunctionalityData?.tariffs.map((item) => (
            <div key={item.id} className={style["row"]}>
              <div className="justify-self-center">
                <Radio
                  checked={item.id === selectTariff}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectTariff(item.id);
                    } else {
                      setSelectTariff(-1);
                    }
                  }}
                />
              </div>
              <div className={style["cell"]}>
                <p className="truncate">{item.description}</p>
              </div>
              <div className={style["cell"]}>
                <p className="truncate">{item.limit}</p>
              </div>
              <div className={style["priceBox"]}>
                <div className={style["price"]}>${item.daily_cost}/день</div>
              </div>
            </div>
          ))}
        </div>
        <div className="mx-auto w-[225px] max-sm:w-full">
          <SubmitButton
            type="button"
            disabled={isLoading}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Підключити"
            successText="Успіно підключено"
            errorText="Не вдалось підключити"
            errorMesage={
              error
                ? (
                    error as unknown as {
                      data: { error: string };
                    }
                  ).data?.error
                : ""
            }
            onClick={() => {
              activate({
                functionalityId: setFunctionalityData?.id || -1,
                tariffId: selectTariff,
              });
            }}
          />
        </div>
      </>
    </CRMModalLayout>
  );
}
