import { ConnectedProductStock } from "@interfaces/stock";
import style from "./ProvidersItemMainBody.module.scss";

export function ProvidersItemMainBody({
  data,
  children,
}: {
  data: ConnectedProductStock[];
  children: (data: ConnectedProductStock) => JSX.Element;
}) {
  return (
    <div className={style["body"]}>
      <div className={style["scrollContainer"]}>
        {data.length === 0 ? (
          <p className={style["empty"]}>Жодного співпадіння</p>
        ) : null}
        {data.map((item) => children(item))}
      </div>
    </div>
  );
}
