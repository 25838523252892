import { useEffect, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";
import cn from "classnames";
import style from "./SelectTemplate.module.scss";
import { SelectNoFormik, SubmitButton } from "@ui";
import { useProductPropertiesGroupTreeAllQuery } from "@RTKQuery/product-properties/api";
import { useAppDispatch } from "store/hooks";
import { setCombinationsSelected, setProperties } from "@global-state/pages/product/slice";

interface Value {
  id: number;
  value: string;
  label: string;
}

const defaultValue = {
  id: -1,
  value: "null",
  label: "Групу не вибрано",
};

export function SelectTemplate({
  handlerTrigger,
  result,
}: {
  handlerTrigger: (id: number) => void;
  result: {
    isError: boolean;
    isSuccess: boolean;
    isFetching: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  };
}) {
  const [selectValue, setSelectValue] = useState<Value>(defaultValue);

  const dispatch = useAppDispatch();

  const { data: dataTemplates = [] } = useProductPropertiesGroupTreeAllQuery(undefined, {
    selectFromResult: ({ data, ...args }) => ({
      data: data?.map<Value>((item) => ({
        id: item.id,
        value: String(item.id),
        label: item.group,
      })),
      ...args,
    }),
  });

  useEffect(() => {
    handlerTrigger(selectValue.id);
  }, [selectValue]);

  useEffect(() => {
    setSelectValue(JSON.parse(window.localStorage.getItem("productPage_active_groupProperty") || "null")?.groupProperty || defaultValue)
  }, [])

  return (
    <div className={style["selectTemplate"]}>
      <div className="w-[50%] max-sm:w-full">
        <SelectNoFormik
          label="Доступні групи властивостей"
          listPosition="top"
          currentValue={selectValue}
          listValue={[defaultValue, ...dataTemplates]}
          setCurrentValue={(newValue) => {
            window.localStorage.setItem("productPage_active_groupProperty", JSON.stringify({
              groupProperty: newValue,
              chars: {},
              variations: {}
            }));
            dispatch(setProperties({}));
            dispatch(setCombinationsSelected([]));
            
            setSelectValue(newValue)
          }}
        />
      </div>
      <div
        className={cn(
          style["buttonContainer"],
          selectValue.id === -1 && "!hidden",
        )}
      >
        <SubmitButton
          className={style["customSubmitButton"]}
          type="button"
          isUninitialized={false}
          isError={result.isError && !result.isFetching}
          isSuccess={result.isSuccess && !result.isFetching}
          isLoading={result.isFetching}
          successText="Дані отримано"
          errorText={
            result.error
              ? (result.error as unknown as { data: { error: string } }).data?.error
              : "Непередбачувана помилка"
          }
          uninitializedText=""
          errorMesage=""
        />
      </div>
    </div>
  );
}
