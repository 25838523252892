import { useParams } from "react-router-dom";
import style from "./TopPanel.module.scss";
import { BackButton, SubmitButton } from "@ui";
import {
  useSystemNotificationAllQuery,
  useSystemNotificationOnReadMutation,
} from "@RTKQuery/system-notifications/api";
import {
  isTypeSystemNotification,
  SystemNotificationType,
} from "@interfaces/system-notifications";
import { Helmet } from "react-helmet";
import { useRef, useEffect } from "react";

export function TopPanel() {
  const { notificationId } = useParams();

  const { data } = useSystemNotificationAllQuery();

  const [
    onRead,
    { isError, isLoading, isSuccess, isUninitialized, error, reset },
  ] = useSystemNotificationOnReadMutation();

  const currentNotification = data?.find(
    (item) => item.id === Number(notificationId),
  );

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isError || isSuccess) {
      refSetTimeout.current = setTimeout(() => {
        reset();
      }, 5000);
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    return () => {
      reset();
      clearTimeout(refSetTimeout.current);
    };
  }, []);

  if (notificationId && currentNotification) {
    return (
      <div className={style["topPanel"]}>
        <BackButton url="./.." />
        {isTypeSystemNotification(
          SystemNotificationType.HOROSHOP_IMPORT,
          currentNotification,
        ) ? (
          <>
            <Helmet>
              <title>Деталі хорошоп імпорту</title>
            </Helmet>
            <p className={style["currentPage"]}>Деталі хорошоп імпорту</p>
          </>
        ) : null}
      </div>
    );
  } else {
    return (
      <div className={style["topPanel"]}>
        <div className="flex w-full items-start justify-between">
          <p className={style["currentPage"]}>Системні сповіщення</p>
          <div>
            <SubmitButton
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              errorText="Помилка"
              successText="Прочитано"
              uninitializedText="Прочитати всі сповіщення"
              type="button"
              className="h-[40px] w-[240px]"
              errorMesage={
                error
                  ? (error as unknown as { data: { error: string } }).data
                      ?.error
                  : ""
              }
              onClick={() => {
                onRead(
                  data?.filter((item) => !item.read).map((item) => item.id) ||
                    [],
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
