import { UpdatePropertyGroupForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { referenceBook_editGroup_clearData } from "@global-state/pages/productProperties/slice";
import { useProductPropertiesGroupUpdateMutation } from "@RTKQuery/product-properties/api";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";

const MODAL_NAME = "UpdatePropertiesGroupModal";

export function UpdatePropertiesGroupModal() {
  const [_, { isSuccess, reset }] = useProductPropertiesGroupUpdateMutation({
    fixedCacheKey: "edit-book-group",
  });

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        dispatch(referenceBook_editGroup_clearData());
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess) {
      dispatch(referenceBook_editGroup_clearData());
    }
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Відредагувати групу"
    >
      <UpdatePropertyGroupForm />
    </CRMModalLayout>
  );
}
