import { useContext } from "react";
import { variationsContext } from "../../CoreVariations";
import style from "./ListVariationsItem.module.scss";
import { SelectableParameter } from "@interfaces/product";
import { Checkbox } from "@ui";
import { ListVariationsValues } from "../ListVariationsValues";
import { useAppSelector } from "store/hooks";

export function ListVariationsItem({ data }: { data: SelectableParameter }) {
  const context = useContext(variationsContext);

  const errorsVariations = useAppSelector(state => state.productPage.errorsVariations);

  return (
    <>
      <div className={style["itemName"]}>
        {data.multi_selection ? (
          <Checkbox
            alignLabel="left"
            label={<p title={data.name} className={style["customLabel"]}>{data.name}</p>}
            checked={data.selected}
            onChange={(e) => {
              context?.switchChekedParameter(data.id, e.target.checked);
            }}
          />
        ) : (
          <p title={data.name} className={style["customLabel"]}>{data.name}</p>
        )}
      </div>
      <ListVariationsValues data={data.values} parent={data} />
      {
        errorsVariations[data.id]
        ? <p className={style["errorText"]}>Потрібно обрати мінімум одне значення</p>
        : null
      }
    </>
  );
}
