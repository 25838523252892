import {
  product_delete_clearData,
  product_delete_setData,
} from "@global-state/pages/products/slice";
import { ProductTable } from "@interfaces/product";
import { useProductsAllQuery } from "@RTKQuery/products/api";
import { Checkbox } from "@ui";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";

export function AllProductsCheckbox() {
  const { categoryId } = useParams();
  const [queryParams] = useSearchParams();

  const { data } = useProductsAllQuery({
    category_id: +(categoryId || "0"),
    limit: +(queryParams.get("limit") || "20"),
    offset: +(queryParams.get("offset") || "0"),
    search: queryParams.get("search") || undefined,
  });

  const delete_books = useAppSelector(
    (state) => state.productsPage.product.delete,
  );

  const dispatch = useAppDispatch();

  return (
    <Checkbox
      checked={
        data?.products.length ===
        delete_books.filter((item) => item.tag === "multiple").length
      }
      onChange={(e) => {
        if (e.target.checked) {
          dispatch(
            product_delete_setData(
              data?.products.map((product) => ({
                id: product.id,
                tag: "multiple",
              })) || [],
            ),
          );
        } else {
          dispatch(product_delete_clearData());
        }
      }}
    />
  );
}
