import { useAppSelector } from "../../../../../store/hooks";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { useEffect } from "react";
import { SubmitButton, Input } from "@ui";
import { useProductPropertyValuesUpdateMutation } from "@RTKQuery/product-properties/api";

const ValidationSchema = Yup.object().shape({
  friends: Yup.array().of(
    Yup.object().shape({
      nameUA: Yup.string()
        .max(36, "Максимальна довжина рядка 36 символів")
        .required("Назва UA - обов'язкове поле"),
      nameRU: Yup.string()
        .max(36, "Максимальна довжина рядка 36 символів")
        .required("Назва RU - обов'язкове поле"),
    }),
  ),
});

export function UpdatePropertyValueForm() {
  const { propertyId } = useParams();

  const [
    updateProductPropertyValues,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useProductPropertyValuesUpdateMutation({
    fixedCacheKey: "edit-book-value",
  });

  const activeModal = useAppSelector(
    (state) => state.modalState["UpdatePropertyValueModal"],
  );

  const edit_values = useAppSelector(
    (state) => state.productPropertiesPage.edit_values,
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        friends: edit_values
          .filter((item) => item.tag === activeModal?.mode)
          .map((item) => ({
            id: item.id,
            nameUA: item.name["ua"],
            nameRU: item.name["ru"],
          })),
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values, actions) => {
        updateProductPropertyValues({
          property_id: Number(propertyId),
          listProductPropertyValues: values.friends.map((item) => ({
            id: item.id,
            name: {
              ua: item.nameUA.trim(),
              ru: item.nameRU.trim(),
            },
          })),
        });
      }}
    >
      {({ values }) => (
        <Form>
          <div className="scroll mb-[20px] max-h-[300px] overflow-auto px-[10px]">
            {edit_values.filter((item) => item.tag === activeModal?.mode)
              .length > 0 &&
              edit_values
                .filter((item) => item.tag === activeModal?.mode)
                .map((friend, index) => (
                  <div
                    key={friend.id}
                    className="mb-[20px] flex gap-[15px] max-sm:mb-[30px] max-sm:flex-col max-sm:gap-[10px]"
                  >
                    <div className="w-[50%] max-sm:w-[100%]">
                      <Input
                        formikName={`friends.${index}.nameUA`}
                        label="Нова назва UA"
                        inputType="text"
                      />
                    </div>
                    <div className="w-[50%] max-sm:w-[100%]">
                      <Input
                        formikName={`friends.${index}.nameRU`}
                        label="Нова назва RU"
                        inputType="text"
                      />
                    </div>
                  </div>
                ))}
          </div>
          <SubmitButton
            type="submit"
            disabled={isLoading}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Зберегти"
            successText="Успішно збережено"
            errorText="Не вдалось зберегти"
            errorMesage={
              error
                ? (error as unknown as { data: { error: string } }).data?.error
                : ""
            }
          />
        </Form>
      )}
    </Formik>
  );
}
