import { useParams } from "react-router-dom";
import style from "./VariationsColumnsWrapper.module.scss";
import { useProductStockOneQuery } from "@RTKQuery/stock/api";
import { DndContext, DragEndEvent, closestCorners } from "@dnd-kit/core";
import { useAppDispatch } from "store/hooks";
import { setProviderProductStockItemConnected } from "@global-state/pages/stock/slice";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";

export function VariationsColumnsWrapper({
  children,
}: {
  children: JSX.Element;
}) {
  const { productId } = useParams();

  const { data } = useProductStockOneQuery(Number(productId));

  const dispatch = useAppDispatch();

  return (
    <DndContext
      onDragEnd={handleDragEnd}
      collisionDetection={closestCorners}
      modifiers={[restrictToFirstScrollableAncestor]}
    >
      <div
        className={style["wrapper"]}
        style={
          {
            "--col-count": (data?.children.length || 0) + 1,
          } as React.CSSProperties
        }
      >
        <div className={style["scrollContainer"]}>{children}</div>
      </div>
    </DndContext>
  );

  function handleDragEnd(event: DragEndEvent) {
    const { over, active } = event;

    if (over && active) {
      dispatch(
        setProviderProductStockItemConnected({
          idStockItem: Number(active.id),
          connectedTo: over.id === "disConnect" ? null : Number(over.id),
        }),
      );
    }
  }
}
