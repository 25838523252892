import { FaUser } from "react-icons/fa6";

import style from "./ProfileButton.module.scss";
import { LogoutButton } from "..";
import { useCRMUserQuery } from "@RTKQuery/CRMUser/api";

export function ProfileButton() {
  const { data } = useCRMUserQuery();

  return (
    <div className={style["userInfo"]}>
      <div className={style["menuAvatar"]}>
        <FaUser className="h-full w-full fill-[#bcc7db]" />
      </div>
      <div className={style["hoverHelper"]}>
        <div className={style["box"]}>
          <div className={style["role"]}>{data?.role.title.toLowerCase()}</div>
          <div className="px-[25px] pb-[25px]">
            <div className={style["info"]}>
              <div className={style["avatar"]}>
                <FaUser className="h-full w-full fill-[#bcc7db]" />
              </div>
              <div className={style["text"]}>
                <p className={style["userName"]}>
                  {data?.user.last_name} {data?.user.first_name}
                </p>
                <p title={data?.user.email} className={style["email"]}>
                  {data?.user.email}
                </p>
              </div>
            </div>
            <LogoutButton />
          </div>
        </div>
      </div>
      <div className={style["arrow"]}></div>
    </div>
  );
}
