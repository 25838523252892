import { Link, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Balance,
  Burger,
  ProfileBurger,
  ProfileButton,
  SubMenu,
  ThemeSwitch,
} from "../../../../components/menu";
import {
  AnalyticsSvg,
  CatalogSvg,
  ChatsSvg,
  BalanceSvg,
  SalesSvg,
  SettingsSvg,
  BellSvg,
} from "../../../../components/menu/svg/main";
import {
  ProductsSvg,
  ProductsTemplateSvg,
  ReferencesSvg,
  StockSvg,
  UnloadingSvg,
} from "../../../../components/menu/svg/catalog";
import cn from "classnames";
import style from "./CRMMainLayout.module.scss";
import { useAppDispatch } from "store/hooks";
import { hideAllModal } from "@global-state/entity/modal-state/slice";
import { useCRMUserQuery } from "@RTKQuery/CRMUser/api";
import { Helmet } from "react-helmet";
import { useLazySystemNotificationAllQuery } from "@RTKQuery/system-notifications/api";
import { CRMPaymentRequired } from "@modals";

export function CRMMainLayout() {
  const [currentPage, setCurrentPage] = useState<string>("");

  const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(hideAllModal());
  }, [location]);

  return (
    <div className={style["container"]}>
      <Helmet>
        <title>LevelUp</title>
      </Helmet>
      <div className={style["limit"]}>
        <nav className={cn(style["navigate"], "max-sm:!hidden")}>
          <div className={style["section"]}>
            <Link to={`${crm}/category`}>
              <img
                src="/images/logoMain.png"
                alt="logo"
                className={style["logo"]}
              />
            </Link>
            <Balance />
            <div className={style["liksBox"]}>
              <SalesLinks crm={crm} />
              <ChatsLinks crm={crm} />
              <CatalogLinks crm={crm} />
            </div>
          </div>
          <div className={style["divider"]}></div>
          <div className={style["section"]}>
            <div className={style["liksBox"]}>
              <BalanceLink crm={crm} />
              <AnalyticsLinks crm={crm} />
              <SettingsLinks crm={crm} />
              <SystemNotificationsLink crm={crm} />
            </div>
            <div className={style["divider"]}></div>
            <ThemeSwitch />
            <div className={style["divider"]}></div>
            <ProfileButton />
          </div>
        </nav>
        <div className={style["backdrop"]}></div>
        <nav className={cn(style["navigateMobile"], "max-sm:!flex")}>
          <div className="flex items-center gap-[20px]">
            <Link to={`${crm}/category`}>
              <img
                src="/images/logoMain.png"
                alt="logo"
                className={style["logo"]}
              />
            </Link>
            <div className={style["currentPage"]}>{currentPage}</div>
          </div>
          <div className="flex items-center gap-[17px]">
            <Balance />
            <Burger>
              <>
                <div className="mb-[40px] flex flex-col gap-[10px]">
                  <SalesLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <ChatsLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <CatalogLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <BalanceLink crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <AnalyticsLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <SettingsLinks crm={crm} setCurrentPage={setCurrentPage} />
                  <div className={style["dividerMobile"]}></div>
                  <SystemNotificationsLink
                    crm={crm}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
                <div className="mb-[22px] flex items-center justify-between px-[20px]">
                  <p className="text-[16px] font-medium text-[#6A6A6A]">
                    Змінити тему
                  </p>
                  <ThemeSwitch />
                </div>
                <ProfileBurger />
              </>
            </Burger>
          </div>
        </nav>
      </div>
      <Outlet />
      <>
        <CRMPaymentRequired />
      </>
    </div>
  );
}

function CatalogLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { data } = useCRMUserQuery(void 0, {
    selectFromResult: ({ data, ...args }) => ({
      data: {
        isActiveUnloading: Boolean(
          data?.settings.active_functionalities.find((itemId) => itemId === 1),
        ),
      },
      ...args,
    }),
  });

  return (
    <SubMenu
      icon={<CatalogSvg />}
      name="Каталог"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Товари",
          link: `${crm}/catalog`,
          icon: <ProductsSvg />,
          compareIndex: process.env.NODE_ENV === "development" ? 14 : 8,
        },
        {
          text: "Наявність товарів",
          link: `${crm}/stock`,
          icon: <StockSvg />,
          compareIndex: process.env.NODE_ENV === "development" ? 12 : 6,
        },
        {
          text: "Властивості товарів",
          link: `${crm}/product-properties`,
          icon: <ReferencesSvg />,
          compareIndex: process.env.NODE_ENV === "development" ? 21 : 15,
        },
        ...(() => {
          if (data.isActiveUnloading) {
            return [
              {
                text: "Вигрузки",
                link: `${crm}/unloading/0`,
                icon: <UnloadingSvg />,
                compareIndex: process.env.NODE_ENV === "development" ? 16 : 8,
              },
            ];
          } else {
            return [];
          }
        })(),
      ]}
    />
  );
}

function SalesLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<SalesSvg />}
      name="Продажі"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Замовлення",
          link: `${crm}/orders`,
          icon: <SalesSvg />,
        },
        {
          text: "База покупців",
          link: `${crm}/customers`,
          icon: <SalesSvg />,
        },
      ]}
    />
  );
}

function ChatsLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<ChatsSvg />}
      name="Чати"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Клієнти",
          link: `${crm}/clients`,
          icon: <ChatsSvg />,
        },
        {
          text: "Постачальники",
          link: `${crm}/suppliers`,
          icon: <ChatsSvg />,
        },
      ]}
    />
  );
}

function BalanceLink({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<BalanceSvg />}
      name="Баланс"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Баланс",
          link: `${crm}/billing`,
          icon: <BalanceSvg />,
        },
      ]}
    />
  );
}

function SystemNotificationsLink({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [triger, { data }] = useLazySystemNotificationAllQuery({
    selectFromResult: ({ data, ...args }) => {
      return {
        data: data?.filter((item) => !item.read),
        ...args,
      };
    },
  });

  const location = useLocation();

  useEffect(() => {
    triger();
  }, [location]);

  return (
    <SubMenu
      icon={<BellSvg />}
      name="Системні сповіщення"
      setCurrentPage={setCurrentPage}
      countNotifications={data?.length}
      links={[
        {
          text: "Системні сповіщення",
          link: `${crm}/system-notifications`,
          icon: <SettingsSvg />,
          compareIndex: process.env.NODE_ENV === "development" ? 27 : 21,
        },
      ]}
    />
  );
}

function AnalyticsLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<AnalyticsSvg />}
      name="Аналітика"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Товари",
          link: `${crm}/analytics/products`,
          icon: <AnalyticsSvg />,
        },
        {
          text: "Замовлення",
          link: `${crm}/analytics/order`,
          icon: <AnalyticsSvg />,
        },
        {
          text: "Менеджери",
          link: `${crm}/analytics/managers`,
          icon: <AnalyticsSvg />,
        },
        {
          text: "Постачальники",
          link: `${crm}/analytics/suppliers`,
          icon: <AnalyticsSvg />,
        },
      ]}
    />
  );
}

function SettingsLinks({
  crm,
  setCurrentPage,
}: {
  crm: string;
  setCurrentPage?: React.Dispatch<React.SetStateAction<string>>;
}) {
  return (
    <SubMenu
      icon={<SettingsSvg />}
      name="Налаштування"
      setCurrentPage={setCurrentPage}
      links={[
        {
          text: "Додатковий функціонал",
          link: `${crm}/settings/additional-services`,
          icon: <SettingsSvg />,
        },
        {
          text: "Основні",
          link: `${crm}/settings`,
          icon: <SettingsSvg />,
        },
        {
          text: "Замовлення",
          link: `${crm}/settings/order`,
          icon: <SettingsSvg />,
        },
        {
          text: "Джерела",
          link: `${crm}/settings/sources`,
          icon: <SettingsSvg />,
        },
        {
          text: "Фінанси",
          link: `${crm}/settings/finance`,
          icon: <SettingsSvg />,
        },
        {
          text: "Ролі",
          link: `${crm}/settings/roles`,
          icon: <SettingsSvg />,
        },
      ]}
    />
  );
}
