import { Button } from "@ui";
import style from "./ListSelectedValues.module.scss";
import { CrossSvg } from "./Cross.svg";
import cn from "classnames";

export function ListSelectedValues<T>({
  listValue,
  onDelete,
  keyForLabel,
}: {
  listValue: T[];
  keyForLabel: keyof T;
  onDelete: (value: T) => void;
}) {
  return (
    <div className={style["listValues"]}>
      {listValue.map((curentItem, index: number) => (
        <div key={index} className={cn(style["value"], "p-[5px]")}>
          <span>{String(curentItem[keyForLabel])}</span>
          <Button
            type="delete"
            icon={(className, fill) => (
              <CrossSvg className={cn(className, fill)} />
            )}
            onClick={() => {
              onDelete(curentItem);
            }}
          />
        </div>
      ))}
    </div>
  );
}
