import { useParams } from "react-router-dom";
import style from "./UnloadingOnePage.module.scss";
import { useLazyUnloadingOneQuery, useUnloadingOneQuery } from "@RTKQuery/unloading/api";
import { useAppDispatch } from "store/hooks";
import { useEffect } from "react";
import {
  clearAllUnloadingData,
  setAuthenticUnloadingObject,
} from "@global-state/pages/unloading/slice";
import {
  LongSettings,
  ProductsCategories,
  ProductsManager,
  QuickSettings,
  TopPanel,
} from "components/catalog/unloading";
import cn from "classnames";
import {
  RemoveHoroshopCategoriesModal,
  SetHoroshopCategoriesModal,
} from "@modals";
import { Spinner } from "@ui";

export function UnloadingOnePage({ mode }: { mode: "create" | "update" }) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllUnloadingData());
    };
  }, []);

  return (
    <main className={style["layout"]}>
      <TopPanel />
      <div className={cn(style["scrollContainer"], "scroll")}>
        <div className={style["flexContainer"]}>
          <QuickSettings />
          <LongSettings />
        </div>
        {
          mode === "update"
            ? <div className={style["flexContainer"]}>
              <ProductsCategories />
              <ProductsManager />
            </div>
            : null
        }
      </div>
      <SetHoroshopCategoriesModal />
      <RemoveHoroshopCategoriesModal />
    </main>
  );
}

export function CreateUnloading({ children }: { children: (mode: "create" | "update") => JSX.Element }) {
  return children("create");
} 

export function EditUnloading({ children }: { children: (mode: "create" | "update") => JSX.Element }) {
  const { unloadingId } = useParams();
  const [trigger, { data, isFetching, isError, error }] = useLazyUnloadingOneQuery();

  const dispatch = useAppDispatch();

  useEffect(() => {
    trigger(Number(unloadingId || "-1"));
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(setAuthenticUnloadingObject(data));
    }
  }, [data]);

  if (isFetching) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner className="fill-[#FFFFFF] dark:fill-[#2C2D31]" />
      </div>
    );
  } else if (isError) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <p className="text-[22px] font-medium text-[#6A6A6A] max-sm:text-[18px]">
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else {
    return children("update");;
  }
}
