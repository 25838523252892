import { BillingHistoryRecord } from "@interfaces/billing";
import style from "./HistoryBillingItem.module.scss";
import { formatDate } from "store/hooks";

export function HistoryBillingItem({ data }: { data: BillingHistoryRecord }) {
  return (
    <div className={style["gridRow"]}>
      <div className={style["dateCell"]}>{formatDate(data.change_date)}</div>
      {data.transaction_type === "top-up" ? (
        <>
          <div className={style["balanceUp"]}>Надходження</div>
          <div className={style["amountUp"]}>$ {data.amount}</div>
        </>
      ) : (
        <>
          <div className={style["balanceDown"]}>Списання</div>
          <div className={style["amountDown"]}>$ {data.amount}</div>
        </>
      )}
      <div className={style["dateCell"]}>{data.description}</div>
    </div>
  );
}
