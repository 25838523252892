import { useEffect, useRef, useState } from "react";
import cn from "classnames";
import style from "./CreateTenantModal.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CreateTenantForm } from "@forms";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { useTenantCreateMutation } from "@RTKQuery/account/api";

const MODAL_NAME = "CreateTenantModal";

export function CreateTenantModal() {
  const [_, { isSuccess, reset }] = useTenantCreateMutation({
    fixedCacheKey: "create_tenant",
  });

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess && activeModal?.visibility) {
      refSetTimeout.current = setTimeout(() => {
        hiddenModal();
      }, 700);
    }
  }, [isSuccess, activeModal]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);
    reset();

    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <div
      className={cn(style["backdrop"], activeModal?.visibility && "flex")}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          hiddenModal();
        }
      }}
    >
      <div className={style["modalContent"]}>
        <p className={style["title"]}>Нова CRM</p>
        <p className={style["message"]}>
          Створіть адресу входу в KeyCRM для вашої компанії
        </p>
        <CreateTenantForm />
      </div>
    </div>
  );
}
