import { useRef } from "react";
import style from "./ScaleImage.module.scss";

export function ScaleImage({
  children,
  imageUrl,
}: {
  children: JSX.Element;
  imageUrl: string;
}) {
  const scaleRef = useRef<HTMLDivElement | null>(null);
  const timerRef = useRef<NodeJS.Timeout>();

  return (
    <div
      className={style["hoverWrapper"]}
      onMouseEnter={() => {
        timerRef.current = setTimeout(() => {
          scaleRef.current?.classList.add("!block");
        }, 300);
      }}
      onMouseLeave={() => {
        clearTimeout(timerRef.current);
        scaleRef.current?.classList.remove("!block");
      }}
    >
      {children}
      <div ref={scaleRef} className={style["scaleImage"]}>
        <img src={imageUrl} alt="scale" />
      </div>
    </div>
  );
}
