import { useNavigate } from "react-router-dom";
import { ProductTable } from "@interfaces/product";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";
import style from "./ProductButtons.module.scss";
import { showModal } from "@global-state/entity/modal-state/slice";
import { product_delete_addData } from "@global-state/pages/products/slice";

export function ProductButtons({ product }: { product: ProductTable }) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={style["buttons"]}>
      <div data-notlink>
        <Button
          type="delete"
          onClick={() => {
            dispatch(
              product_delete_addData([
                {
                  id: product.id,
                  tag: "single",
                },
              ]),
            );
            dispatch(
              showModal({
                modalName: "DeleteProductModal",
                mode: "single",
              }),
            );
          }}
        />
      </div>
      <div data-notlink>
        <Button
          type="edit"
          onClick={() => {
            navigate(`product/${product.id}`);
          }}
        />
      </div>
    </div>
  );
}
