import { Button, Spinner } from "@ui";
import style from "./ListNotifications.module.scss";
import { formatDate } from "store/hooks";
import cn from "classnames";
import { useSystemNotificationAllQuery } from "@RTKQuery/system-notifications/api";
import { SystemNotification } from "@interfaces/system-notifications";
import { Link } from "react-router-dom";

export function ListNotifications() {
  const { data, isFetching, isError, error } = useSystemNotificationAllQuery();

  if (isFetching) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner className="fill-[#ffffff] dark:fill-[#2c2d31]" />
      </div>
    );
  } else if (isError) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <p className="text-[22px] font-medium text-[#6A6A6A] max-sm:text-[18px]">
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else if (!data || data.length === 0) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <p className="text-[22px] font-medium text-[#6A6A6A] max-sm:text-[18px]">
          Не знайдено жодного сповіщення.
        </p>
      </div>
    );
  } else {
    return (
      <div className={style["scrollWrapper"]}>
        <div className={style["list"]}>
          {data.map((item) => (
            <ListNotificationsItem key={item.id} data={item} />
          ))}
        </div>
      </div>
    );
  }
}

function ListNotificationsItem({ data }: { data: SystemNotification }) {
  return (
    <div className={style["item"]}>
      <div className="flex justify-between">
        <div
          className={cn(style["activeStatus_left"], data.read && "opacity-0")}
        >
          Нове
        </div>
        <div className={cn(style["activeStatus"])}>
          {formatDate(data.created_at)}
        </div>
      </div>
      <div className="flex h-full items-end justify-between gap-[10px] p-[30px] pb-[40px] pt-0">
        <div>
          <p className={style["title"]}>{data.name}</p>
          <p className={style["description"]}>{data.message}</p>
        </div>
        <Link to={"./" + data.id}>
          <Button
            className="h-[40px] w-[180px]"
            variant="outlined"
            size="big"
            text="Детальніше"
          />
        </Link>
      </div>
    </div>
  );
}
