import { ProductStock } from "@interfaces/stock";
import { useAppSelector } from "store/hooks";
import cn from "classnames";
import style from "./ConnectedVariationsColItem.module.scss";
import { useDroppable } from "@dnd-kit/core";
import { DraggableItem } from "../../../DraggableItem";

export function ConnectedVariationsColItem({ data }: { data: ProductStock }) {
  const providerStockList = useAppSelector(
    (state) => state.stockPage.activeConnectedProduct?.stock,
  );

  const connectedElem = providerStockList?.find(
    (item) => item.connected_to === data.id,
  );

  const { setNodeRef, isOver } = useDroppable({
    id: data.id,
  });

  return (
    <div
      ref={setNodeRef}
      className={cn(style["item"], isOver && "bg-[#D7E2F4] dark:bg-[#3E4146]")}
    >
      {connectedElem ? (
        <DraggableItem data={connectedElem} />
      ) : (
        <div className={style["item_disConnected"]}>
          Перетягніть варіацію сюди
        </div>
      )}
    </div>
  );
}
