export function DeleteIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        d="M5.58618 6.33325V10.3333"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.24854 6.33325V10.3333"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.59253 3.66675H12.2422"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.92383 3.66675H6.91745H10.9111V11.0001C10.9111 12.1047 10.0171 13.0001 8.91427 13.0001H4.92064C3.81783 13.0001 2.92383 12.1047 2.92383 11.0001V3.66675Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.92065 2.33333C4.92065 1.59695 5.51666 1 6.25186 1H7.58307C8.3183 1 8.91428 1.59695 8.91428 2.33333V3.66667H4.92065V2.33333Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
