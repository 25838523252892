import { useParams, useSearchParams } from "react-router-dom";
import style from "./ParentProduct.module.scss";
import { useProductStockOneQuery } from "@RTKQuery/stock/api";
import { Button } from "@ui";

export function ParentProduct() {
  const { productId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();

  const { data } = useProductStockOneQuery(Number(productId));

  return (
    <div className={style["container"]}>
      <div className={style["image"]}>
        <img
          src={data?.product.picture || undefined}
          alt={data?.product.ua_name}
        />
      </div>
      <div className={style["box"]}>
        <p className={style["name"]}>{data?.product.ua_name}</p>
        <div className={style["providers"]}>
          <Button
            size="big"
            text="EasyDrop"
            variant={!queryParams.get("system") ? "contained" : "outlined"}
            customStyle={{
              button: "px-[45px] h-[38px] rounded-[10px]",
            }}
            onClick={() => {
              setQueryParams();
            }}
          />
        </div>
      </div>
    </div>
  );
}
