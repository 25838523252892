import { ConnectedProductStock } from "@interfaces/stock";
import style from "./ProvidersItemMainItem.module.scss";

export function ProvidersItemMainItem({
  data,
}: {
  data: ConnectedProductStock;
}) {
  return (
    <div className={style["item"]}>
      <div className="cursor-not-allowed"></div>
      <div title={data.variation} className={style["col"]}>
        <p className="line-clamp-2">{data.variation}</p>
      </div>
      <div title={data.qty + " шт"} className={style["col"]}>
        <p className="truncate">{data.qty} шт</p>
      </div>
    </div>
  );
}
