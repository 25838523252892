import { Link, useParams } from "react-router-dom";
import style from "./TopPanel.module.scss";
import { Button } from "@ui";

export function TopPanel() {
  const { marketplaceId } = useParams();

  const crm =
    process.env.NODE_ENV === "development"
      ? `/${window.localStorage.getItem("subdomain")}`
      : "";

  return (
    <div className={style["topPanel"]}>
      <p className={style["currentPage"]}>Вигрузки</p>
      <div className={style["search"]}></div>
      <div className={style["button"]}>
        <Link to={crm + `/unloading/${marketplaceId}/link/add`}>
          <Button
            type="add"
            size="big"
            text="Додати нову вигрузку"
            onClick={() => {}}
            className={style["custom"]}
          />
        </Link>
      </div>
    </div>
  );
}
