import { Outlet } from "react-router-dom";

import style from "./AccountAuthLayout.module.scss";

export function AccountAuthLayout() {
  return (
    <div className={style["container"]}>
      <div className={style["imageBox"]}>
        <div className={style["logo"]}>
          <div className={style["imageLimit"]}>
            <img
              src="/images/logoMain.png"
              alt="logo"
              className={style["image"]}
            />
          </div>
          <p className={style["label"]}>LevelUp</p>
        </div>
        <p className={style["copy"]}>© LevelUp 2024</p>
      </div>
      <div className={style["contentBox"]}>
        <Outlet />
      </div>
    </div>
  );
}
