import { useParams } from "react-router-dom";
import { AllValuesCheckbox } from "./components";
import style from "./TableValuesHead.module.scss";
import { useProductPropertyValuesAllQuery } from "@RTKQuery/product-properties/api";

export function TableValuesHead() {
  const { propertyId } = useParams();
  const { data } = useProductPropertyValuesAllQuery(Number(propertyId || "-1"));

  return (
    <div className={style["head"]}>
      <AllValuesCheckbox values={data} />
      <div className={style["label"]}>Значення UA</div>
      <div className={style["label"]}>Значення RU</div>
      <div></div>
    </div>
  );
}
