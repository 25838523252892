import cn from "classnames";
import { Props } from "../../../Button";
import { BigAddIcon, DeleteIcon, EditIcon } from "../../icons";
import style from "./BigConteined.module.scss";

export function BigConteined({
  onClick,
  disabled,
  text,
  type,
  icon,
  alignIcon = "left",
  color = "default",
  customStyle,
  darkAlternative,
  className,
  ...args
}: Omit<Props, "size" | "variant">) {
  return (
    <button
      {...args}
      type="button"
      onClick={onClick}
      className={cn(
        style["button"],
        disabled ? style["disabled_on"] : style["disabled_off"],
        !disabled && darkAlternative && style["disabled_off_alternative"],
        color === "success" && style["success"],
        color === "error" && style["error"],
        className,
        customStyle?.button,
      )}
      disabled={disabled}
    >
      {text && alignIcon === "right" ? (
        <span className={cn(style["text"], customStyle?.text)}>{text}</span>
      ) : null}
      {icon || type ? (
        <span className={style["icon"]}>
          {icon
            ? icon(
                "!h-[12px] !w-[12px] max-sm:!h-[9px] max-sm:!w-[9px]",
                style["svg_fill"],
              )
            : null}
          {type === "add" ? (
            <BigAddIcon className="h-[14px] w-[14px] max-sm:h-[12px] max-sm:w-[12px]" />
          ) : null}
          {type === "delete" ? (
            <DeleteIcon className="h-[14px] w-[13px] max-sm:h-[14px] max-sm:w-[13px]" />
          ) : null}
          {type === "edit" ? (
            <EditIcon className="h-[14px] w-[14px] max-sm:h-[13px] max-sm:w-[13px]" />
          ) : null}
        </span>
      ) : null}
      {text && alignIcon === "left" ? (
        <span className={cn(style["text"], customStyle?.text)}>{text}</span>
      ) : null}
    </button>
  );
}
