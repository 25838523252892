export function SalesSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
    >
      <path
        d="M19.5 7.875L17.9217 15.7668C17.7269 16.7404 17.6296 17.2272 17.3754 17.5914C17.1512 17.9124 16.8427 18.1653 16.4839 18.3221C16.0769 18.5 15.5805 18.5 14.5876 18.5H7.41233C6.41946 18.5 5.92301 18.5 5.51611 18.3221C5.15728 18.1653 4.84876 17.9124 4.62462 17.5914C4.37044 17.2272 4.27308 16.7404 4.07835 15.7668L2.5 7.875M19.5 7.875H17.375M19.5 7.875H20.5625M2.5 7.875H1.4375M2.5 7.875H4.625M17.375 7.875H4.625M17.375 7.875L14.1875 1.5M4.625 7.875L7.8125 1.5M7.8125 11.0625V14.25M11 11.0625V14.25M14.1875 11.0625V14.25"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
