import { HintSvg } from "./Hint.svg";
import style from "./TitleModal.module.scss";

export function TitleModal() {
  return (
    <div className={style["container"]}>
      <p className={style["title"]}>Зв'язати варіації</p>
      <div className={style["hint"]}>
        <HintSvg />
        <div className={style["descr"]}>
          <p>
            <span>Щоб об’єднати варіації постачальника зі своїми,</span> <br />{" "}
            будь ласка, перетягніть їх у відповідну комірку.
          </p>
        </div>
      </div>
    </div>
  );
}
