import { useEffect, useState } from "react";
import { useField } from "formik";
import cn from "classnames";
import style from "./Branch.module.scss";
import Category from "@interfaces/category";
import { SpoilerButton } from "components/common/UI/buttons/SpoilerButton";

export function Branch({
  data,
  currentCategory,
  changeValue,
  initialCategoryId
}: {
  data: Category;
  currentCategory: {
    value: string;
    label: string;
  };
  changeValue(data: { value: string; label: string }): void;
  initialCategoryId?: number;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (data.id === initialCategoryId) {
      changeValue({
        label: data.name["uk"],
        value: String(data.id),
      });
    }
  }, [initialCategoryId]);

  return (
    <div className={cn(style["treeBranch"])}>
      <div
        className={cn(
          style["value"],
          data.id === +currentCategory.value && style["value_active"],
        )}
      >
        <p
          title={data.name["uk"]}
          onClick={() =>
            changeValue({
              label: data.name["uk"],
              value: String(data.id),
            })
          }
          className={style["text"]}
        >
          {data.name["uk"]}
        </p>
        <div className="flex items-center" data-select>
          {data.children.length > 0 && (
            <SpoilerButton
              className={style["spoilerButtonCustom"]}
              isOpen={isOpen}
              onClick={() => setIsOpen((state) => !state)}
            />
          )}
        </div>
      </div>
      <div className={style["divider"]}></div>
      {data.children.length > 0 && (
        <div
          className={cn(
            style["listValues"],
            isOpen ? "ml-[10px] h-fit" : "h-0",
          )}
        >
          {data.children.map((item) => (
            <Branch
              key={item.id}
              currentCategory={currentCategory}
              data={item}
              changeValue={changeValue}
              initialCategoryId={initialCategoryId}
            />
          ))}
        </div>
      )}
    </div>
  );
}
