import { ProductProvider } from "@interfaces/stock";
import style from "./VariationsItem.module.scss";

export function VariationsItem({
  data,
}: {
  data: ProductProvider["children"][0];
}) {
  return (
    <div className={style["item"]}>
      <div className={style["properties"]}>
        {data.variations.map((item, index) => (
          <div key={index} className="flex justify-between gap-[5px]">
            <p title={item.name} className={style["propertyName"]}>
              {item.name}
            </p>
            <p title={item.value} className={style["propertyValue"]}>
              {item.value}
            </p>
          </div>
        ))}
      </div>
      <div title={String(data.quantity)} className={style["quantity"]}>
        {data.quantity} шт
      </div>
    </div>
  );
}
