import { useParams } from "react-router-dom";
import style from "./TablePropertiesBody.module.scss";
import { Spinner } from "@ui";
import { useProductPropertiesGroupTreeAllQuery } from "@RTKQuery/product-properties/api";
import { ProductPropertiesGroup, ProductProperty } from "@interfaces/product-characteristics";

export function TablePropertiesBody({
  children,
}: {
  children: (book: ProductProperty, group: ProductPropertiesGroup) => JSX.Element;
}) {
  const { group } = useParams();

  const { data, isFetching, isError, error } = useProductPropertiesGroupTreeAllQuery();

  const groupItem: ProductPropertiesGroup | undefined = data?.find((item) => {
    return item.id === Number(group || "-1");
  });

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else if (!groupItem || groupItem.items.length === 0) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Не додано жодного довідника.</p>
      </div>
    );
  } else {
    return <>{groupItem.items.map((item) => children(item, groupItem))}</>;
  }
}
