import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import style from "./LoginForm.module.scss";
import { useEffect } from "react";
import { BasicInputProps, Input, PasswordInputProps, SubmitButton } from "@ui";
import { useLoginMutation } from "@RTKQuery/account/api";

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Невірний формат email")
    .required("E-mail - обов'язкове поле"),
  password: Yup.string().required("Пароль - обов'язкове поле"),
});

export function LoginForm() {
  const [
    login,
    { isUninitialized, isSuccess, isLoading, isError, error, reset },
  ] = useLoginMutation();

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        reset();
      }, 1700);
    }
  }, [isError]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        login({
          email: values.email.trim(),
          password: values.password.trim(),
        });
      }}
    >
      <Form>
        <div className={style["inputsBox"]}>
          <Input<BasicInputProps>
            formikName="email"
            label="E-mail"
            inputType="email"
          />
          <Input<PasswordInputProps>
            formikName="password"
            label="Пароль"
            inputType="password"
          />
          <div className={style["forgotPasswordLink"]}>
            <Link to={"../forgot-password"}>
              <span className={style["textLink"]}>Забули пароль?</span>
            </Link>
          </div>
        </div>
        <div className={style["submitButtonBox"]}>
          <SubmitButton
            type="submit"
            disabled={isLoading}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Вхід"
            successText="Успішно"
            errorText="Не вдалось увійти"
            errorMesage={
              error
                ? (error as unknown as { data: { error: string } }).data?.error
                : ""
            }
          />
        </div>
      </Form>
    </Formik>
  );
}
