import { ListServices, TopPanel } from "components/settings/additionalServices";
import style from "./AdditionalServicesPage.module.scss";
import { ServicesActivateModal, ServicesDeactivateModal } from "@modals";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { clearAllSettingsAdditionalServicesData } from "@global-state/pages/settings.additionalServices/slice";
import { Helmet } from "react-helmet";

export function AdditionalServicesPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllSettingsAdditionalServicesData());
    };
  }, []);

  return (
    <main className={style["layout"]}>
      <Helmet>
        <title>Додатковий функціонал</title>
      </Helmet>
      <TopPanel />
      <ListServices />
      <>
        <ServicesActivateModal />
        <ServicesDeactivateModal />
      </>
    </main>
  );
}
