interface Unloading {
  id: number;
  name: string;
  file_format: FileFormat;
}

export interface UnloadingLink extends Unloading {
  link: string;
}

export interface UnloadingExtended extends Unloading {
  use_max_drop_price: boolean;
  use_retail_price: boolean;
  use_fixed_markup: boolean;
  use_percent_markup: boolean;
  use_formula_markup: boolean;
  fixed_markup: number | null;
  percent_markup: number | null;
  formula_markup: FormulaMarkup[] | null;
}

export type MarketplaceId = number;

export enum FileFormat {
  CSV = "csv",
  XLSX = "xlsx",
  XML = "xml",
  YML = "yml",
}

export interface FormulaMarkup {
  id: string;
  mark_up: number;
  to_price: number | null;
  from_price: number;
}

export interface ProductHoroshop {
  id: number;
  sku: string;
  ua_name: string;
  buying_price: number;
  picture: string;
  added: boolean;
  categories: HoroshopCategory[];
}

export interface HoroshopCategory {
  id: number;
  name: string;
}
