import { useState } from "react";
import style from "./BasicInformationWrapper.module.scss";
import cn from "classnames";
import { SpoilerButton } from "@ui";

export function BasicInformationWrapper({
  children,
}: {
  children: JSX.Element;
}) {
  const [isShow, setIsShow] = useState<boolean>(false);

  return (
    <div className={style["basicInfo"]}>
      <div
        className={style["flexContainer"]}
        onClick={() => {
          setIsShow((state) => !state);
        }}
      >
        <div className={style["label"]}>Основна інформація</div>
        <SpoilerButton
          className={style["spoilerButtonCustom"]}
          isOpen={isShow}
        />
      </div>
      <div className={cn(style["content"], isShow && "!block")}>{children}</div>
    </div>
  );
}
