import { useContext } from "react";
import { variationsContext } from "../../CoreVariations";
import cn from "classnames";
import style from "./ListVariationsValuesItem.module.scss";
import { SelectableParameter } from "@interfaces/product";
import { Checkbox, Radio } from "@ui";

export function ListVariationsValuesItem({
  data,
  parent,
}: {
  data: SelectableParameter["values"][0];
  parent: SelectableParameter;
}) {
  const context = useContext(variationsContext);

  if (parent.multi_selection) {
    return (
      <div className={style["limit"]}>
        <Checkbox
          label={(isChecked) => (
            <p
              className={cn(
                style["customLabel"],
                isChecked && style["checked"],
              )}
            >
              {data.name}
            </p>
          )}
          checked={data.selected || false}
          onChange={(e) => {
            context?.switchChekedParameterValue(
              parent.id,
              data.id,
              e.target.checked,
            );
          }}
        />
      </div>
    );
  } else {
    return (
      <div className={style["limit"]}>
        <Radio
          label={(isChecked) => (
            <p
              className={cn(
                style["customLabel"],
                isChecked && style["checked"],
              )}
            >
              {data.name}
            </p>
          )}
          checked={data.selected || false}
          onChange={(e) => {
            context?.switchChekedParameterValue(
              parent.id,
              data.id,
              e.target.checked,
            );
          }}
        />
      </div>
    );
  }
}
