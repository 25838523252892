import { Link } from "react-router-dom";
import { BackSvg } from "./Back.svg";

import style from "./BackButton.module.scss";

export function BackButton({ url }: { url?: string }) {
  return (
    <Link to={url || "."} className={style["backButton"]}>
      <BackSvg />
      <p className={style["mobileText"]}>Назад</p>
    </Link>
  );
}
