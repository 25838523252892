import { ProductProvider } from "@interfaces/stock";
import style from "./VariationsList.module.scss";

export function VariationsList({
  data,
  children,
}: {
  data: ProductProvider["children"];
  children: (
    data: ProductProvider["children"][0],
    index: number,
  ) => JSX.Element;
}) {
  return (
    <div className={style["scrollContainer"]}>
      {data.map((item, index) => children(item, index))}
    </div>
  );
}
