import style from "./HistoryBillingWrapper.module.scss";

export function HistoryBillingWrapper({ children }: { children: JSX.Element }) {
  return (
    <div className={style["container"]}>
      <div className={style["title"]}>
        <div className="hidden w-full rounded-t-[6px] bg-white max-sm:block dark:bg-[#2c2d31]"></div>
        <div className="bg-white max-sm:flex-shrink-0 dark:bg-[#2c2d31]">
          <div className={style["label"]}>Історія балансу</div>
        </div>
        <div
          style={{ width: "calc(100% + 1px)" }}
          className="hidden rounded-t-[6px] bg-white max-sm:block dark:bg-[#2c2d31]"
        ></div>
      </div>
      <div className={style["content"]}>
        <div className={style["gridContainer"]}>{children}</div>
      </div>
    </div>
  );
}
