import { ProductButtons } from "./components";
import cn from "classnames";
import style from "./TableProductItem.module.scss";
import { ProductTable } from "@interfaces/product";
import { ScaleImage } from "components/common/ScaleImage";
import { useNavigate } from "react-router-dom";

export function TableProductItem({ data }: { data: ProductTable }) {
  const navigate = useNavigate();

  return (
    <div
      onClick={(e: any) => {
        navigate(`product/${data.id}`);
      }}
      className={style["item"]}
    >
      <div
        className={cn(style["bodyColumn"], "justify-self-start")}
        title={data.sku}
      >
        <p className="max-w-full truncate">{data.sku}</p>
      </div>
      <div
        className={cn(style["bodyColumn"], "justify-self-start")}
        title={data.ua_name}
      >
        <p className="max-w-full truncate">{data.ua_name}</p>
      </div>
      <div
        className={cn(style["bodyColumn"])}
        title={String(data.buying_price)}
      >
        <p className="max-w-full truncate">{data.buying_price} грн.</p>
      </div>
      <div
        className={cn(style["bodyColumn"])}
        title={String(data.retail_price)}
      >
        <p className="max-w-full truncate">{data.retail_price} грн.</p>
      </div>
      <div className={style["bodyColumn"]}>
        <div className={style["imageLimit"]}>
          <ScaleImage imageUrl={data.picture}>
            <img
              src={data.picture}
              alt={data.ua_name}
              className={style["img"]}
            />
          </ScaleImage>
        </div>
      </div>
      <div className={cn(style["bodyColumn"], "px-0")}>
        <ProductButtons product={data} />
      </div>
    </div>
  );
}
