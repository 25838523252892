import { ProductCheckbox } from "./components";
import cn from "classnames";
import style from "./TableProductsItem.module.scss";
import { ProductHoroshop } from "@interfaces/unloading";
import { ScaleImage } from "components/common/ScaleImage";
import { IoStar, IoStarOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import { useCategoriesToUnloadingAddMutation, useCategoriesToUnloadingDeleteMutation } from "@RTKQuery/unloading/api";
import { clearCategories } from "@global-state/pages/unloading/slice";
import { useEffect } from "react";

export function TableProductsItem({ data }: { data: ProductHoroshop }) {
  return (
    <div className={style["item"]}>
      <div className={style["headColumn"]}>
        <Star data={data} />
      </div>
      <div
        className={cn(style["bodyColumn"], "justify-self-start pl-[10px]")}
        title={data.ua_name}
      >
        <p className="max-w-full truncate">{data.ua_name}</p>
      </div>
      <div
        className={cn(style["bodyColumn"], "justify-self-start")}
        title={data.sku}
      >
        <p className="max-w-full truncate">{data.sku}</p>
      </div>
      <div className={style["bodyColumn"]}>
        <div className={style["imageLimit"]}>
          <ScaleImage imageUrl={data.picture}>
            <img
              src={data.picture}
              alt={data.ua_name}
              className={style["img"]}
            />
          </ScaleImage>
        </div>
      </div>
      <div
        className={cn(style["bodyColumn"])}
        title={String(data.buying_price)}
      >
        <p className="max-w-full truncate">{data.buying_price} грн.</p>
      </div>
      <div
        className={cn(style["bodyColumn"], "justify-self-start")}
        title={String(data.categories?.map((item) => item.name).join(", "))}
      >
        <p className="line-clamp-2">
          {data.categories?.map((item) => item.name).join(", ")}
        </p>
      </div>
      <div className={style["bodyColumn"]}>
        <ProductCheckbox product={data} />
      </div>
    </div>
  );
}


function Star({ data }: { data: ProductHoroshop }) {
  const { unloadingId } = useParams();

  const dispatch = useAppDispatch();

  const [categoriesToUnloadingAdd, categoriesToUnloadingAddStatus] = useCategoriesToUnloadingAddMutation();
  const [categoriesToUnloadingDelete, categoriesToUnloadingDeleteStatus] = useCategoriesToUnloadingDeleteMutation();

  useEffect(() => {
    if (categoriesToUnloadingAddStatus.isError || categoriesToUnloadingAddStatus.isSuccess) {
      const timer = setTimeout(() => {
        categoriesToUnloadingAddStatus.reset();
        dispatch(clearCategories())
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
    if (categoriesToUnloadingDeleteStatus.isError || categoriesToUnloadingDeleteStatus.isSuccess) {
      const timer = setTimeout(() => {
        categoriesToUnloadingDeleteStatus.reset();
        dispatch(clearCategories())
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [categoriesToUnloadingAddStatus, categoriesToUnloadingDeleteStatus])

  return (
    <div
      className={style["mainStar"]}
      onClick={() => {
        if (data.added) {
          categoriesToUnloadingDelete({
            categories: [],
            unloading_id: Number(unloadingId),
            products: [data.id]
          })
        } else {
          categoriesToUnloadingAdd({
            categories: [],
            unloading_id: Number(unloadingId),
            products: [data.id]
          })
        }
      }}
    >
      {
        data.added
          ? <>
            <IoStar className={cn(style["icon"])} />
            <div className={style["descr"]}>Додано у вигрузку</div>
          </>
          : <>
            <IoStarOutline className={cn(style["icon"])} />
            <div className={style["descr"]}>Не додано у вигрузку</div>
          </>
      }
    </div>
  )
}