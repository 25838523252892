import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActions.module.scss";
import { SubmitButton } from "@ui";
import cn from "classnames";
import { useCategoriesToUnloadingAddMutation, useCategoriesToUnloadingDeleteMutation } from "@RTKQuery/unloading/api";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { clearCategories } from "@global-state/pages/unloading/slice";

export function MultipleActions() {
  const { unloadingId } = useParams();

  const selectCategories = useAppSelector(
    (state) => state.unloadingPage.selectCategories,
  );

  const dispatch = useAppDispatch();

  const [categoriesToUnloadingAdd, categoriesToUnloadingAddStatus] = useCategoriesToUnloadingAddMutation();
  const [categoriesToUnloadingDelete, categoriesToUnloadingDeleteStatus] = useCategoriesToUnloadingDeleteMutation();

  useEffect(() => {
    if (categoriesToUnloadingAddStatus.isError || categoriesToUnloadingAddStatus.isSuccess) {
      const timer = setTimeout(() => {
        categoriesToUnloadingAddStatus.reset();
        dispatch(clearCategories())
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
    if (categoriesToUnloadingDeleteStatus.isError || categoriesToUnloadingDeleteStatus.isSuccess) {
      const timer = setTimeout(() => {
        categoriesToUnloadingDeleteStatus.reset();
        dispatch(clearCategories())
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [categoriesToUnloadingAddStatus, categoriesToUnloadingDeleteStatus])
  return (
    <div
      className={cn(
        style["multipleActions"],
        (selectCategories.length > 0) && "!h-[100px]",
      )}
    >
      <div className={style["button"]}>
        <SubmitButton
          className="px-[30px] max-sm:px-[20px]"
          isUninitialized={categoriesToUnloadingDeleteStatus.isUninitialized}
          isLoading={categoriesToUnloadingDeleteStatus.isLoading}
          isSuccess={categoriesToUnloadingDeleteStatus.isSuccess}
          isError={categoriesToUnloadingDeleteStatus.isError}
          errorText="Помилка"
          successText="Успішно видалено"
          uninitializedText="Видалити з вигрузки"
          type="button"
          errorMesage={
            categoriesToUnloadingDeleteStatus.error
              ? (categoriesToUnloadingDeleteStatus.error as unknown as { data: { error: string } }).data
                ?.error
              : ""
          }
          onClick={() => {
            categoriesToUnloadingDelete({
              categories: selectCategories,
              unloading_id: Number(unloadingId),
              products: []
            })
          }}
        />
      </div>
      <div className={style["button"]}>
        <SubmitButton
          className="px-[30px] max-sm:px-[20px]"
          isUninitialized={categoriesToUnloadingAddStatus.isUninitialized}
          isLoading={categoriesToUnloadingAddStatus.isLoading}
          isSuccess={categoriesToUnloadingAddStatus.isSuccess}
          isError={categoriesToUnloadingAddStatus.isError}
          errorText="Помилка"
          successText="Успішно додано"
          uninitializedText="Додати у вигрузку"
          type="button"
          errorMesage={
            categoriesToUnloadingAddStatus.error
              ? (categoriesToUnloadingAddStatus.error as unknown as { data: { error: string } }).data
                ?.error
              : ""
          }
          onClick={() => {
            categoriesToUnloadingAdd({
              categories: selectCategories,
              unloading_id: Number(unloadingId),
              products: []
            })
          }}
        />
      </div>
    </div>
  );
}
