import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import cn from "classnames";
import style from "./MenuCategoriesBranch.module.scss";
import Category from "@interfaces/category";
import { useAppDispatch } from "store/hooks";
import { Button, SpoilerButton } from "@ui";
import { showModal } from "@global-state/entity/modal-state/slice";
import {
  category_add_setData,
  category_delete_setData,
  category_edit_setData,
} from "@global-state/pages/products/slice";

export function MenuCategoriesBranch({
  data,
  treeIds,
  actions = true,
  addCategoryBtn = true,
  removeCategoryBtn = true,
  editCategoryBtn = true,
  showBranchCategoryBtn = true,
  pageName,
}: {
  data: Category;
  treeIds: string;
  actions?: boolean;
  addCategoryBtn?: boolean;
  removeCategoryBtn?: boolean;
  editCategoryBtn?: boolean;
  showBranchCategoryBtn?: boolean;
  pageName: string;
}) {
  const openDefault = window.localStorage
    .getItem(`treeIdsCategory${capitalizeFirstLetter(pageName)}`)
    ?.split("/")
    .includes(String(data.id));

  const [isOpen, setIsOpen] = useState<boolean>(!!openDefault);

  const { categoryId } = useParams();
  const [queryParams] = useSearchParams();

  const dispatch = useAppDispatch();

  if (categoryId && data.id === +categoryId) {
    window.localStorage.setItem(
      `category${capitalizeFirstLetter(pageName)}Page`,
      data.id + "",
    );
  }

  useEffect(() => {
    if (categoryId && data.id === +categoryId) {
      window.localStorage.setItem(
        `treeIdsCategory${capitalizeFirstLetter(pageName)}`,
        treeIds.replace(String(data.id), ""),
      );
    }
  }, [categoryId]);

  return (
    <div className={style["branchTree"]}>
      <div className="min-w-fit border-b border-[#EFEFF9] py-[8px] dark:border-[#242527]">
        <div
          className={cn(
            style["branch"],
            categoryId && data.id === +categoryId && style["branch_active"],
          )}
        >
          <Link
            className={style["link"]}
            to={
              "../" +
              data.id +
              "?limit=" +
              (queryParams.get("limit") || "1") +
              "&offset=0"
            }
          >
            <p
              title={data.name["uk"]}
              className={cn(style["nameCategory"], actions && "!max-w-full")}
            >
              {data.name["uk"]}
            </p>
          </Link>
          {actions && (
            <div className={style["actions"]}>
              {addCategoryBtn && (
                <Button
                  type="add"
                  onClick={() => {
                    dispatch(category_add_setData(data.id));
                    dispatch(
                      showModal({
                        modalName: "AddCategoryModal",
                      }),
                    );
                  }}
                />
              )}
              {editCategoryBtn && (
                <Button
                  type="edit"
                  onClick={() => {
                    dispatch(category_edit_setData(data));
                    dispatch(
                      showModal({
                        modalName: "EditCategoryModal",
                      }),
                    );
                  }}
                />
              )}
              {removeCategoryBtn && (
                <Button
                  type="delete"
                  onClick={() => {
                    dispatch(category_delete_setData(data));
                    dispatch(
                      showModal({
                        modalName: "DeleteCategoryModal",
                      }),
                    );
                  }}
                />
              )}
              {showBranchCategoryBtn && (
                <>
                  {data.children.length > 0 && (
                    <SpoilerButton
                      isOpen={isOpen}
                      onClick={() => setIsOpen((state) => !state)}
                      className={style["customSpoilerButton"]}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className={style["divider"]}></div>
      {isOpen && data.children.length > 0 && (
        <div className="ml-[10px] flex flex-col gap-[5px]">
          {data.children.map((item) => (
            <MenuCategoriesBranch
              key={item.id}
              data={item}
              treeIds={treeIds + "/" + item.id}
              addCategoryBtn={addCategoryBtn}
              removeCategoryBtn={removeCategoryBtn}
              editCategoryBtn={editCategoryBtn}
              showBranchCategoryBtn={showBranchCategoryBtn}
              pageName={pageName}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
