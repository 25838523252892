import { useField, useFormikContext } from "formik";
import { useState } from "react";
import { Button } from "../../../buttons/Button";
import cn from "classnames";

import style from "./MultipleInput.module.scss";
import { CrossSvg } from "./Cross.svg";

export function MultipleInput({
  formikName,
  label,
  helperText,
  placeholder,
}: {
  formikName: string;
  label: JSX.Element | string;
  helperText?: (text: string) => JSX.Element;
  placeholder?: string;
}) {
  const { setFieldValue } = useFormikContext<Record<string, any>>();
  const [field, meta] = useField(formikName);

  const [valueInput, setValueInput] = useState<string>("");
  const [error, setError] = useState<string>("");

  return (
    <div>
      {typeof label === "string" ? (
        <p className={style["label"]}>{label}</p>
      ) : (
        label
      )}
      <input
        className={cn(
          style["input"],
          (meta.touched && meta.error) || error
            ? style["error_on"]
            : style["error_off"],
        )}
        type="text"
        placeholder={placeholder || ""}
        value={valueInput}
        onChange={(e) => {
          setValueInput(e.target.value);
        }}
        onBlur={() => {
          if (valueInput) {
            if (field.value.some((item: any) => item.value === valueInput)) {
              setError("Таке значення уже існує");
            } else {
              setValueInput("");
              setError("");
              setFieldValue(field.name, [
                ...field.value,
                {
                  value: valueInput,
                  isExists: false,
                },
              ]);
            }
          } else {
            setError("");
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (valueInput) {
              if (field.value.some((item: any) => item.value === valueInput)) {
                setError("Таке значення уже існує");
              } else {
                setValueInput("");
                setError("");
                setFieldValue(field.name, [
                  ...field.value,
                  {
                    value: valueInput,
                    isExists: false,
                  },
                ]);
              }
            } else {
              setError("");
            }
          }
        }}
      />
      <div className={style["listValues"]}>
        {field.value.map(
          (item: { value: string; isExists: boolean }, index: number) => (
            <div
              key={index}
              className={cn(style["value"], !item.isExists && "p-[5px]")}
            >
              <span>{item.value}</span>
              {!item.isExists ? (
                <Button
                  type="delete"
                  icon={(className, fill) => (
                    <CrossSvg className={cn(className, fill)} />
                  )}
                  onClick={() => {
                    setFieldValue(
                      field.name,
                      field.value.filter(
                        (obj: any) => obj.value !== item.value,
                      ),
                    );
                  }}
                />
              ) : null}
            </div>
          ),
        )}
      </div>
      {(meta.touched && meta.error) || error ? (
        helperText ? (
          helperText(meta.error || error || "")
        ) : (
          <p className={style["errorMessage"]}>{meta.error || error || ""}</p>
        )
      ) : null}
    </div>
  );
}
