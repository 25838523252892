import cn from "classnames";
import { RiLoader5Fill } from "react-icons/ri";
import style from "./LogoutButton.module.scss";
import {
  useCRMUserQuery,
  useLazyCRMUserQuery,
  useLogoutCRMMutation,
} from "@RTKQuery/CRMUser/api";
import { useEffect } from "react";

export function LogoutButton() {
  const [logout, { isUninitialized, isLoading, isError, reset }] =
    useLogoutCRMMutation();

  const { data } = useCRMUserQuery();

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        reset();
      }, 1700);
    }
  }, [isError]);

  return (
    <button
      className={cn(
        style["buttonLogout"],
        isUninitialized && style["buttonLogout_uninitialized"],
        isLoading && style["buttonLogout_loaded"],
        isError && style["buttonLogout_error"],
      )}
      disabled={isLoading}
      onClick={() => {
        logout({ role: data?.role.name || "" });
      }}
    >
      {isLoading ? (
        <div className="w-[15px]">
          <RiLoader5Fill className="h-full w-full animate-spin" />
        </div>
      ) : (
        <span>
          {isUninitialized ? "Вийти" : isError ? "Не вдалось вийти" : ""}
        </span>
      )}
    </button>
  );
}
