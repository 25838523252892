import {
  addCategories,
  clearCategories,
  removeCategories,
  setActiveCategory,
  setCategories,
} from "@global-state/pages/unloading/slice";
import Category from "@interfaces/category";
import { useCategoriesAllQuery } from "@RTKQuery/categories/api";
import { Checkbox } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./Branch.module.scss";
import cn from "classnames";

export function Branch({ data }: { data: Category }) {
  const { data: allCategoriesId = [] } = useCategoriesAllQuery(undefined, {
    selectFromResult: ({ data, ...args }) => ({
      data: extractId(data || []),
      ...args,
    }),
  });
  const { selectCategories, activeCategory } = useAppSelector(
    (state) => state.unloadingPage,
  );

  const dispatch = useAppDispatch();

  return (
    <div>
      <div className={style["parentBranch"]}>
        <p
          className={cn(style["name"], (activeCategory === data.id) && style["name_active"])}
          title={data.name["uk"]}
          onClick={() => {
            dispatch(setActiveCategory(data.id))
          }}
        >
          {data.name["uk"]}
        </p>
        <Checkbox
          checked={
            data.id === -1
              ? selectCategories.length === allCategoriesId.length
              : selectCategories.includes(data.id)
          }
          onChange={(e) => {
            if (e.target.checked) {
              if (data.id === -1) {
                dispatch(setCategories(allCategoriesId));
              } else {
                dispatch(addCategories(data.id));
              }
            } else {
              if (data.id === -1) {
                dispatch(clearCategories());
              } else {
                dispatch(removeCategories(data.id));
              }
            }
          }}
        />
      </div>
      <div className={style["childBranch"]}>
        {data.children.map((item) => (
          <Branch key={item.id} data={item} />
        ))}
      </div>
    </div>
  );

  function extractId(data: Category[]): number[] {
    const arrId: number[] = [];

    arrId.push(...data.map((item) => item.id));
    data.forEach((item) => arrId.push(...extractId(item.children)));

    return arrId;
  }
}
