import { ConnectedProductStock } from "@interfaces/stock";
import style from "./ProvidersItemFooterList.module.scss";

export function ProvidersItemFooterList({
  data,
  children,
}: {
  data: ConnectedProductStock[];
  children: (data: ConnectedProductStock) => JSX.Element;
}) {
  return (
    <div className="h-full min-h-0">
      <div className={style["list"]}>{data.map((item) => children(item))}</div>
    </div>
  );
}
