import { ConnectedProduct, ConnectedProductStock } from "@interfaces/stock";
import { Button } from "@ui";
import style from "./ProvidersItemHeader.module.scss";
import { useAppDispatch } from "store/hooks";
import { showModal } from "@global-state/entity/modal-state/slice";
import { setDeleteProductProviderId } from "@global-state/pages/stock/slice";

export function ProvidersItemHeader({
  data,
}: {
  data: Pick<ConnectedProduct, "block_id" | "supplier"> &
    Pick<ConnectedProductStock, "sku">;
}) {
  const dispatch = useAppDispatch();

  return (
    <div className={style["header"]}>
      <div className={style["name"]}>
        <p
          title={data.sku}
          className="min-w-[5ch] max-w-full self-end truncate text-[10px]"
        >
          {data.sku}
        </p>
        <p
          title={data.supplier.name}
          className="min-w-[10ch] max-w-full grow truncate text-center"
        >
          {data.supplier.name}
        </p>
      </div>
      <Button
        customStyle={{
          button: style["customButton"],
        }}
        size="small"
        type="delete"
        onClick={() => {
          dispatch(setDeleteProductProviderId(data.block_id));
          dispatch(
            showModal({
              modalName: "DeleteProductProviderModal",
            }),
          );
        }}
      />
    </div>
  );
}
