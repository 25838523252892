import { authApi } from "..";
import { ProductProperty, ProductPropertiesGroup, ProductPropertyValue } from "@interfaces/product-characteristics";

const productPropertiesApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        productPropertiesGroupTreeAll: build.query<ProductPropertiesGroup[], void>({
            query: () => "properties",
        }),
        productPropertiesOneGroupValuesAll: build.query<{
            id: ProductPropertiesGroup["id"],
            name: ProductPropertiesGroup["group"],
            properties: (ProductProperty & { values: ProductPropertyValue[] })[]
        }, ProductPropertiesGroup["id"]>({
            query: (group_id) => `properties/group/${group_id}`,
        }),
        productPropertiesGroupCreate: build.mutation<ProductPropertiesGroup[], { name: ProductPropertiesGroup["group"] }[]>({
            query: (body) => {
                return {
                    url: "properties/group",
                    method: "POST",
                    body
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        productPropertiesApi.util.upsertQueryData(
                            "productPropertiesGroupTreeAll",
                            undefined,
                            data,
                        ),
                    );
                } catch { }
            }
        }),
        productPropertiesGroupUpdate: build.mutation<{
            id: ProductPropertiesGroup["id"],
            name: ProductPropertiesGroup["group"]
        }[], {
            id: ProductPropertiesGroup["id"],
            name: ProductPropertiesGroup["group"]
        }[]>({
            query: (body) => {
                return {
                    url: "properties/group",
                    method: "PUT",
                    body
                }
            },
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled

                    dispatch(
                        productPropertiesApi.util.updateQueryData(
                            "productPropertiesGroupTreeAll",
                            undefined,
                            (draft) => {
                                data.forEach(item => {
                                    draft[draft.findIndex(i => i.id === item.id)].group = item.name;
                                })
                            }
                        ),
                    );
                } catch { }
            }
        }),
        productPropertiesGroupDelete: build.mutation<ProductPropertiesGroup[], ProductPropertiesGroup["id"][]>({
            query: (query) => {
                const listItem = query
                    .map((id) => `items=${id}`)
                    .join("&");

                return {
                    url: `properties/group?${listItem}`,
                    method: "DELETE",
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        productPropertiesApi.util.upsertQueryData(
                            "productPropertiesGroupTreeAll",
                            undefined,
                            data,
                        ),
                    );
                } catch { }
            }
        }),
        productPropertyCreate: build.mutation<
            ProductPropertiesGroup[],
            { group_id: ProductPropertiesGroup["id"] } & Omit<ProductProperty, "id">
        >({
            query: ({ group_id, ...body }) => {
                return {
                    url: `properties/${group_id}`,
                    method: "POST",
                    body
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        productPropertiesApi.util.upsertQueryData(
                            "productPropertiesGroupTreeAll",
                            undefined,
                            data,
                        ),
                    );
                } catch { }
            }
        }),
        productPropertyUpdate: build.mutation<
            ProductPropertiesGroup[],
            ({ group_id: ProductPropertiesGroup["id"] } & ProductProperty)[]
        >({
            query: (body) => {
                return {
                    url: `properties`,
                    method: "PUT",
                    body
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        productPropertiesApi.util.upsertQueryData(
                            "productPropertiesGroupTreeAll",
                            undefined,
                            data,
                        ),
                    );
                } catch { }
            }
        }),
        productPropertyDelete: build.mutation<ProductPropertiesGroup[], {
            group_id: ProductPropertiesGroup["id"]
            items: ProductProperty["id"][]
        }>({
            query: ({ group_id, items }) => {
                const listItem = items
                    .map((id) => `items=${id}`)
                    .join("&");

                return {
                    url: `properties?group_id=${group_id}&${listItem}`,
                    method: "DELETE",
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        productPropertiesApi.util.upsertQueryData(
                            "productPropertiesGroupTreeAll",
                            undefined,
                            data,
                        ),
                    );
                } catch { }
            }
        }),
        productPropertyValuesAll: build.query<ProductPropertyValue[], ProductProperty["id"]>({
            query: (property_id) => `properties/values?property_id=${property_id}`,
        }),
        productPropertyValuesCreate: build.mutation<ProductPropertyValue[], {
            property_id: ProductProperty["id"],
            listNames: Pick<ProductPropertyValue, "name">[];
        }>({
            query: ({ property_id, listNames }) => {
                return {
                    url: `properties/values/${property_id}`,
                    method: "POST",
                    body: listNames
                }
            },
            async onQueryStarted({ property_id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        productPropertiesApi.util.upsertQueryData(
                            "productPropertyValuesAll",
                            property_id,
                            data,
                        ),
                    );
                } catch { }
            }
        }),
        productPropertyValuesUpdate: build.mutation<ProductPropertyValue[], {
            property_id: ProductProperty["id"],
            listProductPropertyValues: ProductPropertyValue[];
        }>({
            query: ({ property_id, listProductPropertyValues }) => {
                return {
                    url: `properties/values/${property_id}`,
                    method: "PUT",
                    body: listProductPropertyValues
                }
            },
            async onQueryStarted({ property_id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        productPropertiesApi.util.upsertQueryData(
                            "productPropertyValuesAll",
                            property_id,
                            data,
                        ),
                    );
                } catch { }
            }
        }),
        productPropertyValuesDelete: build.mutation<ProductPropertyValue[], {
            property_id: ProductPropertiesGroup["id"]
            items: ProductProperty["id"][]
        }>({
            query: ({ property_id, items }) => {
                const listItem = items
                    .map((id) => `items=${id}`)
                    .join("&");

                return {
                    url: `properties/values/${property_id}?${listItem}`,
                    method: "DELETE",
                };
            },
            async onQueryStarted({ property_id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        productPropertiesApi.util.upsertQueryData(
                            "productPropertyValuesAll",
                            property_id,
                            data,
                        ),
                    );
                } catch { }
            }
        }),
    }),
    overrideExisting: false,
});

export const {
    useProductPropertiesGroupTreeAllQuery,
    useLazyProductPropertiesOneGroupValuesAllQuery,

    useProductPropertiesGroupCreateMutation,
    useProductPropertiesGroupUpdateMutation,
    useProductPropertiesGroupDeleteMutation,

    useProductPropertyCreateMutation,
    useProductPropertyUpdateMutation,
    useProductPropertyDeleteMutation,

    useProductPropertyValuesAllQuery,
    useProductPropertyValuesCreateMutation,
    useProductPropertyValuesUpdateMutation,
    useProductPropertyValuesDeleteMutation
} = productPropertiesApi;