import cn from "classnames";

import style from "./SortButton.module.scss";

export function SortButton({
  sort,
  nameCol,
}: {
  sort: string;
  nameCol: string;
}) {
  return (
    <div className={style["sortButton"]}>
      <svg
        className={cn(
          style["asc"],
          sort === nameCol + "/asc" && style["asc_active"],
        )}
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <rect width="10" height="10" rx="3" fill="#D9D9D9" />
        <path d="M5 2L7.59808 6.5H2.40192L5 2Z" fill="#8F8F8F" />
      </svg>
      <svg
        className={cn(
          style["desc"],
          sort === nameCol + "/desc" && style["asc_active"],
        )}
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
      >
        <rect width="10" height="10" rx="3" fill="#D9D9D9" />
        <path d="M5 8L7.59808 3.5H2.40192L5 8Z" fill="#8F8F8F" />
      </svg>
    </div>
  );
}
