import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { useParams, useSearchParams } from "react-router-dom";
import { SubmitButton } from "@ui";
import { useUnloadingsHoroshopCategoriesRemoveMutation } from "@RTKQuery/unloading/api";
import { ListValues } from "components/common/UI/HoroshopCategorySelect/components";
import { useHoroshopCategoriesAllQuery } from "@RTKQuery/horoshop-categories/api";

const MODAL_NAME = "RemoveHoroshopCategoriesModal";

export function RemoveHoroshopCategoriesModal() {
  const { unloadingId } = useParams();
  const [searchParams] = useSearchParams();

  const [listValue, setListValue] = useState<string[]>([]);

  const [
    deleteHoroshopCategories,
    { isLoading, isError, isUninitialized, isSuccess, reset, error },
  ] = useUnloadingsHoroshopCategoriesRemoveMutation();

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const selectProduct = useAppSelector(
    (state) => state.unloadingPage.selectProduct,
  );
  const selectCategories = useAppSelector(
    (state) => state.unloadingPage.selectCategories,
  );
  const selectHoroshopCategories = useAppSelector(
    (state) => state.unloadingPage.selectProduct_Categories,
  );

  const { data: listHints } = useHoroshopCategoriesAllQuery();

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    setListValue([]);
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Видаліть непотрібні категорії"
    >
      <>
        <div className="mb-[10px]">
          <ListValues
            listValue={Array.from(
              new Set(Object.values(selectHoroshopCategories).flat()),
            ).filter((item) => !listValue.includes(item))}
            onDelete={(curentItem) => {
              setListValue((state) => [...state, curentItem]);
            }}
          />
        </div>
        <SubmitButton
          type="button"
          disabled={isLoading}
          isUninitialized={isUninitialized}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          uninitializedText="Зберегти"
          successText="Успішно збережено"
          errorText="Не вдалось зберегти"
          errorMesage={
            error
              ? (
                  error as unknown as {
                    data: { error: string };
                  }
                ).data?.error
              : ""
          }
          onClick={() => {
            deleteHoroshopCategories({
              limit: Number(searchParams.get("limit") || "20"),
              offset: Number(searchParams.get("offset") || "0"),
              search: searchParams.get("search") || undefined,
              unloading_id: Number(unloadingId || ""),
              product_ids: selectProduct,
              product_categories: selectCategories,
              horoshop_category_ids:
                listHints
                  ?.filter((item) => listValue.includes(item.name))
                  .map((item) => item.id) || [],
            });
          }}
        />
      </>
    </CRMModalLayout>
  );
}
