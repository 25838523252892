export function EditIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M5.80021 1.70373H3.12162C2.33207 1.70373 1.93729 1.70373 1.63572 1.85764C1.37045 1.99302 1.15478 2.20903 1.01963 2.47472C0.865967 2.77677 0.865967 3.17218 0.865967 3.96299V10.7407C0.865967 11.5316 0.865967 11.927 1.01963 12.229C1.15478 12.4947 1.37045 12.7107 1.63572 12.8461C1.93729 13 2.33207 13 3.12162 13H9.88858C10.6781 13 11.0729 13 11.3745 12.8461C11.6397 12.7107 11.8554 12.4947 11.9906 12.229C12.1442 11.927 12.1442 11.5316 12.1442 10.7407V7.70488M8.97222 2.76276L10.9659 4.75968M5.63294 6.10737L10.3193 1.41358C10.8698 0.862141 11.7625 0.862141 12.313 1.41358C12.8636 1.96501 12.8636 2.85906 12.313 3.41049L7.47602 8.25514C6.93917 8.79292 6.67075 9.06177 6.36497 9.27555C6.09358 9.4654 5.80084 9.62256 5.49288 9.74399C5.14586 9.88082 4.77373 9.95601 4.02947 10.1065L3.68553 10.1759L3.71898 9.94139C3.83734 9.11161 3.89652 8.69669 4.03113 8.3093C4.15059 7.96554 4.31377 7.63872 4.51664 7.33676C4.74527 6.99646 5.04117 6.70007 5.63294 6.10737Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
