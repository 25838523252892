import { useSearchParams } from "react-router-dom";

import style from "./TopPanel.module.scss";
import { Input, SearchInputProps } from "@ui";

export function TopPanel() {
  const [_, setQueryParams] = useSearchParams();

  return (
    <div className={style["topPanel"]}>
      <p className={style["currentPage"]}>Наявність</p>
      <div className={style["search"]}>
        <Input<SearchInputProps>
          inputType="search"
          placeholder="Пошук..."
          onSearch={(value) => {
            setQueryParams((params) =>
              Object.assign(
                {
                  limit: params.get("limit") || "20",
                  offset: params.get("offset") || "0",
                },
                value && {
                  search: value,
                },
              ),
            );
          }}
        />
      </div>
    </div>
  );
}
