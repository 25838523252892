import { useProductAddPicturesMutation } from "@RTKQuery/products/api";
import { Button, SubmitButton } from "@ui";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./AddPicturesModal.module.scss";
import {
  allFilesModal,
  clearAllFilesModal,
  ProductImagesModal,
} from "components/catalog/product/ProductImagesModal/ProductImagesModal";
import { setPicuressModal } from "@global-state/pages/product/slice";
import { hideModal } from "@global-state/entity/modal-state/slice";
import { product_selected_variation_clearData } from "@global-state/pages/product/slice";

const MODAL_NAME = "AddPicturesModal";

export function AddPicturesModal() {
  const [
    addProductsPictures,
    { isLoading, isError, error, isUninitialized, isSuccess, reset },
  ] = useProductAddPicturesMutation({
    fixedCacheKey: "add-product-pictures",
  });

  const picturesModal = useAppSelector(
    (state) => state.productPage.picturesModal,
  );

  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const product_variation_selected_item = useAppSelector(
    (state) => state.productPage.selected_variation,
  );

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        dispatch(product_selected_variation_clearData());
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    clearAllFilesModal();
    dispatch(setPicuressModal([]));
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      title="Додати фото"
      className="!px-[60px]"
    >
      <>
        <ProductImagesModal />
        <div className={style["buttons"]}>
          <div className="w-[50%]">
            <Button
              text="Скасувати"
              variant="outlined"
              size="big"
              onClick={() => {
                hiddenModal();
              }}
            />
          </div>
          <div className="w-[50%]">
            <SubmitButton
              type="submit"
              disabled={isLoading}
              isUninitialized={isUninitialized}
              isLoading={isLoading}
              isSuccess={isSuccess}
              isError={isError}
              uninitializedText="Підтвердити"
              successText="Успішно видалено"
              errorText="Не вдалось видалити"
              errorMesage={""}
              onClick={() => {
                addProductsPictures({
                  payload: {
                    products: product_variation_selected_item.childIds.map(
                      (item) => item.id,
                    ),
                    parent_id: product_variation_selected_item.parentId,
                  },
                  pictures: {
                    files: allFilesModal,
                    positions: picturesModal.map((item) => item.id),
                  },
                });
              }}
            />
          </div>
        </div>
        <p className={style["errorMessage"]}>
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : ""}
        </p>
      </>
    </CRMModalLayout>
  );
}
