import { useAppSelector } from "store/hooks";
import {
  TableProductWrapper,
  TableProductBody,
  TableProductHead,
  TableProductItem,
} from "./components";

export function TableProduct() {
  const importData = useAppSelector((state) => state.productsPage.importData);

  return (
    <TableProductWrapper colCount={importData[0]?.length || 0}>
      <>
        <TableProductHead colNames={importData[0]} />
        <TableProductBody rows={importData}>
          {(data, index) => (
            <TableProductItem
              key={index}
              colCount={importData[0]?.length || 0}
              data={data}
            />
          )}
        </TableProductBody>
      </>
    </TableProductWrapper>
  );
}
