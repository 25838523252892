import style from "./TableProductHead.module.scss";
import { Checkbox, SelectNoFormik } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  addImportSelectedColumn,
  removeImportSelectedColumn,
} from "@global-state/pages/products/slice";
import { useEffect, useState } from "react";
import { showModal } from "@global-state/entity/modal-state/slice";
import { useProductPropertiesGroupTreeAllQuery } from "@RTKQuery/product-properties/api";

export function TableProductHead({
  colNames,
}: {
  colNames: (string | null)[];
}) {
  const importSelectedColumn = useAppSelector(
    (state) => state.productsPage.importSelectedColumn,
  );

  const dispatch = useAppDispatch();

  return (
    <div className={style["head"]}>
      {colNames?.map((name, i) => {
        const select = importSelectedColumn.find((item) => item.index === i);

        return (
          <div key={i} className={style["headColumn"]}>
            {select?.tag !== "required" ? (
              <div className="flex min-w-[200px] flex-col gap-[10px] max-sm:min-w-[150px]">
                <Checkbox
                  checked={!!select && select.tag === "variation"}
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch(removeImportSelectedColumn(i));
                      dispatch(
                        addImportSelectedColumn({
                          index: i,
                          tag: "variation",
                        }),
                      );
                    } else {
                      dispatch(removeImportSelectedColumn(i));
                    }
                  }}
                  label={"Варіація"}
                />
                <CharHelper selectData={select} colIndex={i} />
              </div>
            ) : null}
            <p>
              {name}
              {select?.tag === "required" ? "*" : ""}
            </p>
          </div>
        );
      })}
    </div>
  );
}

function CharHelper({
  selectData,
  colIndex,
}: {
  selectData:
  | {
    index: number;
    tag: "required" | "characteristic" | "variation";
    payload?: any;
  }
  | undefined;
  colIndex: number;
}) {
  const [isActive, setIsActive] = useState<boolean>(false);

  const { data } = useProductPropertiesGroupTreeAllQuery();

  const { counterSameCharsError, importSelectedPropertiesGroupId } = useAppSelector(
    (state) => state.productsPage
  );

  const activeGroupcharacteristics = data
  ?.find(group => group.id === importSelectedPropertiesGroupId)?.items
  .filter(item => item.type === "characteristic");

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectData?.tag === "variation") {
      setIsActive(false);
    }
  }, [selectData]);

  return (
    <div className="flex items-center gap-[10px]">
      <Checkbox
        checked={isActive}
        onChange={(e) => {
          dispatch(removeImportSelectedColumn(colIndex));
          if (e.target.checked) {
            setIsActive(true);
            dispatch(
              addImportSelectedColumn({
                index: colIndex,
                tag: "characteristic",
              }),
            );
          } else {
            setIsActive(false);
          }
        }}
      />
      <SelectNoFormik
        adaptive
        isError={counterSameCharsError[selectData?.payload?.id] > 1}
        errorText={
          <>
            Ця характеристика зіставлена з кількома стовпцями. <br /> Вкажіть
            різні характеристики для стовпців
          </>
        }
        addItemButtonFunc={() => {
          dispatch(
            showModal({
              modalName: "CreatePropertyModal",
              mode: "import",
            }),
          );
        }}
        disabled={!isActive}
        currentValue={
          selectData && selectData?.payload?.id && selectData.tag === "characteristic"
            ? {
              id: selectData.payload.id,
              value: String(selectData.payload.id),
              label: selectData.payload.label,
            }
            : {
              id: -1,
              value: "-1",
              label: "Автоматично",
            }
        }
        listValue={
          [
            ...(activeGroupcharacteristics || []).map((item) => ({
              id: item.id,
              value: String(item.id),
              label: item.name,
            })),
            {
              id: -1,
              value: "-1",
              label: "Автоматично",
            }
          ]
        }
        setCurrentValue={(value) => {
          dispatch(removeImportSelectedColumn(colIndex));
          if (value.value === "-1") {
            dispatch(
              addImportSelectedColumn({
                index: colIndex,
                tag: "characteristic",
              }),
            );
          } else {
            dispatch(
              addImportSelectedColumn({
                index: colIndex,
                tag: "characteristic",
                payload: {
                  id: value.id,
                  label: value.label,
                },
              }),
            );
          }
        }}
      />
    </div>
  );
}
