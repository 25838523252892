export function DotsSvg({ className }: { className?: string }) {
  return (
    <svg
      className={className + " !w-[15px]"}
      width="15"
      height="3"
      viewBox="0 0 16 3"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1.5" cy="1.5" r="1.5" />
      <circle cx="7.5" cy="1.5" r="1.5" />
      <circle cx="13.5" cy="1.5" r="1.5" />
    </svg>
  );
}
