import { PaginationLimitSelect } from "components/common/PaginationLimitSelect";
import { PaginationPagesButton } from "components/common/PaginationPagesButton";
import { useParams, useSearchParams } from "react-router-dom";
import style from "./Pagination.module.scss";
import { useProductsAllHoroshopQuery } from "@RTKQuery/unloading/api";
import { useAppSelector } from "store/hooks";

export function Pagination() {
  const { unloadingId } = useParams();
  const [queryParams, setQueryParams] = useSearchParams();

  const { id } = useAppSelector(
    (state) => state.unloadingPage.unloadingInfoObject,
  );

  const activeCategory = useAppSelector(
    (state) => state.unloadingPage.activeCategory,
  );

  const{ data } = useProductsAllHoroshopQuery({
    categoriesIds: [activeCategory],
    unloadingId: Number(unloadingId) || 0,
    limit: +(queryParams.get("limit") || "20"),
    offset: +(queryParams.get("offset") || "0"),
    search: queryParams.get("search") || undefined,
  });

  const activePage =
    (+(queryParams.get("offset") || "0") +
      +(queryParams.get("limit") || "20")) /
    +(queryParams.get("limit") || "20");

  return (
    <div className={style["pagination"]}>
      <PaginationLimitSelect
        isActive={(limit) => {
          return Number(queryParams.get("limit") || "20") === limit;
        }}
        limitList={[10, 20, 50, 100]}
        onClick={(limit) => {
          setQueryParams((params) =>
            Object.assign(
              {
                offset: "0",
                limit: String(limit),
              },
              queryParams.get("search") && {
                search: params.get("search") || "",
              },
            ),
          );
        }}
      />
      {data && data.products.length > 0 ? (
        <PaginationPagesButton
          count={data.total_pages || 1}
          activePage={activePage - 1}
          onClickPage={(page) => {
            setQueryParams((params) =>
              Object.assign(
                {
                  limit: params.get("limit") || "20",
                  offset: `${+(params.get("limit") || "20") * page}`,
                },
                queryParams.get("search") && {
                  search: params.get("search") || "",
                },
              ),
            );
          }}
        />
      ) : null}
    </div>
  );
}
