import { setPropertiesGroupId } from "@global-state/pages/product/slice";
import { SelectableParameter } from "@interfaces/product";
import { useLazyProductPropertiesOneGroupValuesAllQuery } from "@RTKQuery/product-properties/api";
import { SelectTemplate } from "components/catalog/product";
import { useState } from "react";
import { useAppDispatch } from "store/hooks";

export function CreateMod({
  children,
}: {
  children: (propertyChars: SelectableParameter[], propertyVariations: SelectableParameter[]) => JSX.Element;
}) {
  const [propertyChars, setPropertyChars] = useState<SelectableParameter[]>([]);
  const [propertyVariations, setPropertyVariations] = useState<SelectableParameter[]>([]);

  const [trigger, { isError, isSuccess, isFetching, error }] =
    useLazyProductPropertiesOneGroupValuesAllQuery();

  const dispatch = useAppDispatch();

  function handlerTrigger(id: number) {
    if (id >= 0) {
      trigger(id)
        .unwrap()
        .then((response) => {
          dispatch(setPropertiesGroupId(id));
          setPropertyChars(
            response.properties
              .filter(item => item.type === "characteristic")
              .map((item) => ({
                ...item,
                selected: false,
                values: item.values.map((value) => ({
                  ...value,
                  name: value.name.ua,
                  selected: false,
                })),
              })),
          );
          setPropertyVariations(
            response.properties
              .filter(item => item.type === "variation")
              .map((item) => ({
                ...item,
                selected: false,
                values: item.values.map((value) => ({
                  ...value,
                  name: value.name.ua,
                  selected: false,
                })),
              })),
          );
        })
        .catch(() => { });
    } else {
      setPropertyChars([]);
      setPropertyVariations([]);
    }
  }

  return (
    <div className="mb-[20px]">
        <SelectTemplate
          handlerTrigger={handlerTrigger}
          result={{
            isError,
            isSuccess,
            isFetching,
            error,
          }}
        />
        {children(propertyChars, propertyVariations)}
    </div>
  );
}
