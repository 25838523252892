import { hideModal } from "@global-state/entity/modal-state/slice";
import { CRMModalLayout } from "components/modals/CRMModalLayout";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  ConnectedVariationsCol,
  ProviderProductVariationsCol,
  SaveButton,
  TitleModal,
  UserProductVariationsCol,
  VariationsColumnsWrapper,
} from "./components";
import style from "./ConnectedProductStockModal.module.scss";
import { useProductProviderConnectingMutation } from "@RTKQuery/stock/api";
import { useEffect, useRef } from "react";
import { clearActiveProviderProductStock } from "@global-state/pages/stock/slice";

const MODAL_NAME = "ConnectedProductStockModal";

export function ConnectedProductStockModal() {
  const activeModal = useAppSelector((state) => state.modalState[MODAL_NAME]);

  const [_, { isSuccess, isError, reset }] =
    useProductProviderConnectingMutation({
      fixedCacheKey: "productProvider_connect",
    });

  const dispatch = useAppDispatch();

  const refSetTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isSuccess) {
      if (!activeModal?.visibility) {
        dispatch(clearActiveProviderProductStock());
        reset();
      } else {
        refSetTimeout.current = setTimeout(() => {
          hiddenModal();
        }, 700);
      }
    }
  }, [isSuccess, activeModal]);

  useEffect(() => {
    const timer = setTimeout(() => {
      reset();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [isError]);

  function hiddenModal() {
    clearTimeout(refSetTimeout.current);

    if (!isSuccess) {
      dispatch(clearActiveProviderProductStock());
    }
    dispatch(hideModal(MODAL_NAME));
  }

  return (
    <CRMModalLayout
      isShow={activeModal?.visibility}
      hiddenModal={hiddenModal}
      className="h-[80vh] max-w-[1170px] px-[60px] py-[40px] max-lg:px-[15px] max-sm:h-[95vh] max-sm:pt-0"
    >
      <div className="flex h-full flex-col">
        <TitleModal />
        <VariationsColumnsWrapper>
          <>
            <div className={style["gridContainer"]}>
              <UserProductVariationsCol />
              <ConnectedVariationsCol />
            </div>
            <ProviderProductVariationsCol />
          </>
        </VariationsColumnsWrapper>
        <SaveButton />
      </div>
    </CRMModalLayout>
  );
}
