import { LoginCRMForm } from "@forms";
import style from "./LoginCRMPage.module.scss";
import { Helmet } from "react-helmet";

export function LoginCRMPage() {
  return (
    <main className={style["page"]}>
      <Helmet>
        <title>Вхід до CRM</title>
      </Helmet>
      <div className={style["limit"]}>
        <h3 className={style["title"]}>Вхід до CRM</h3>
        <LoginCRMForm />
      </div>
    </main>
  );
}
