import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActions.module.scss";
import { Button } from "@ui";
import { showModal } from "@global-state/entity/modal-state/slice";
import cn from "classnames";

export function MultipleActions() {
  const delete_product_variation = useAppSelector(
    (state) => state.productPage.selected_variation,
  );

  const dispatch = useAppDispatch();

  return (
    <div
      className={cn(
        style["multipleActions"],
        delete_product_variation.childIds.some(
          (item) => item.tag === "multiple",
        ) && "h-[50px]",
      )}
    >
      <div className={style["button"]}>
        <Button
          type="delete"
          size="big"
          text="Видалити"
          onClick={() => {
            dispatch(
              showModal({
                modalName: "DeleteVariationModal",
                mode: "multiple",
              }),
            );
          }}
          className="px-[30px] max-sm:px-[20px]"
        />
      </div>
      <div className={style["button"]}>
        <Button
          type="add"
          size="big"
          text="Додати фото"
          variant="outlined"
          onClick={() => {
            dispatch(
              showModal({
                modalName: "AddPicturesModal",
              }),
            );
          }}
          className="px-[30px] max-sm:px-[20px]"
        />
      </div>
    </div>
  );
}
