import { TableProduct, TopPanel } from "components/catalog/import";
import style from "./ProductImportPage.module.scss";
import { useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { clearImportData } from "@global-state/pages/products/slice";
import {
  CreatePropertiesGroupModal,
  CreatePropertyModal,
  ImportSendSuccessModal,
} from "@modals";
import { Helmet } from "react-helmet";

export function ProductImportPage() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearImportData());
    };
  }, []);

  return (
    <main className={style["layout"]}>
      <Helmet>
        <title>Імпорт товарів</title>
      </Helmet>
      <TopPanel />
      <div className={style["tableBox"]}>
        <TableProduct />
      </div>
      <>
        <CreatePropertyModal />
        <CreatePropertiesGroupModal />
        <ImportSendSuccessModal />
      </>
    </main>
  );
}
