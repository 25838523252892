import { Form, Formik } from "formik";
import style from "./DeleteTenantForm.module.scss";
import { useAppSelector } from "store/hooks";
import { SubmitButton, Input } from "@ui";
import { useTenantDeleteMutation } from "@RTKQuery/account/api";

export function DeleteTenantForm() {
  const [
    tenantDelete,
    { isUninitialized, isLoading, isError, isSuccess, error },
  ] = useTenantDeleteMutation({
    fixedCacheKey: "delete_tenant",
  });

  const tenant_delete = useAppSelector((state) => state.tenantPage.delete);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
      }}
      onSubmit={(values, actions) => {
        tenantDelete(tenant_delete?.uuid || "")
          .unwrap()
          .then(() => {
            actions.resetForm();
          })
          .catch(() => {});
      }}
    >
      {({ values }) => (
        <Form>
          <div className="mb-[40px]">
            <Input
              formikName="name"
              label={
                <p className={style["inputLabel"]}>
                  Для підтвердження введіть:{" "}
                  <span className={style["subdomain"]}>
                    {tenant_delete?.subdomain}
                  </span>
                </p>
              }
              inputType="text"
            />
          </div>
          <SubmitButton
            type="submit"
            disabled={isLoading || values.name !== tenant_delete?.subdomain}
            isUninitialized={isUninitialized}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isError={isError}
            uninitializedText="Видалити"
            successText="Успішно видалено"
            errorText="Не вдалось видалити"
            errorMesage={
              error
                ? (error as unknown as { data: { error: string } }).data?.error
                : ""
            }
          />
        </Form>
      )}
    </Formik>
  );
}
