import { NotificationsDetails, TopPanel } from "components/systemNotifications";
import style from "./SystemNotificationsOnePage.module.scss";
import { Helmet } from "react-helmet";

export function SystemNotificationsOnePage() {
  return (
    <main className={style["layout"]}>
      <Helmet>
        <title>Системні сповіщення</title>
      </Helmet>
      <TopPanel />
      <NotificationsDetails />
    </main>
  );
}
