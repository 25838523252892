import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

import modalState from "../slices/entity/modal-state/slice";
import balancePage from "../slices/pages/balance/slice";
import productPage from "../slices/pages/product/slice";
import unloadingPage from "../slices/pages/unloading/slice";
import productPropertiesPage from "../slices/pages/productProperties/slice";
import tenantPage from "../slices/pages/tenant/slice";
import productsPage from "../slices/pages/products/slice";
import stockPage from "../slices/pages/stock/slice";
import settingsAdditionalServicesPage from "../slices/pages/settings.additionalServices/slice";
import global from "../slices/global/slice";
import { authApi, logApi } from "../querySlices";

export const store = configureStore({
  reducer: {
    modalState,
    balancePage,
    productPage,
    unloadingPage,
    productPropertiesPage,
    tenantPage,
    productsPage,
    stockPage,
    settingsAdditionalServicesPage,
    global,
    [authApi.reducerPath]: authApi.reducer,
    [logApi.reducerPath]: logApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware).concat(logApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export type SliceAction<T> = {
  payload: T;
  type: string;
};
export type PartialExcept<T, K extends keyof T = keyof T> = Partial<
  Omit<T, K>
> &
  Pick<T, K>;
