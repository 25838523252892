import style from "./TopPanel.module.scss";

export function TopPanel() {
  return (
    <div className={style["topPanel"]}>
      <div className={style["container"]}>
        <p className={style["currentPage"]}>Баланс</p>
      </div>
    </div>
  );
}
