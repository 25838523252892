import style from "./TableProductsProvidersWrapper.module.scss";

export function TableProductsProvidersWrapper({
  children,
}: {
  children: JSX.Element;
}) {
  return (
    <div className={style["scrollContainer"]}>
      <div className={style["gridContainer"]}>{children}</div>
    </div>
  );
}
