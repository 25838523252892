export function SaveSvg({ className }: { className: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M8.77769 11L3.2222 10.9999M8.77769 11L9.22385 10.9999C9.8449 10.9999 10.1555 10.9999 10.3929 10.8789C10.6019 10.7724 10.7724 10.6023 10.8789 10.3932C10.9999 10.1558 10.9999 9.8449 10.9999 9.22385V4.45527C10.9999 4.20592 10.9999 4.08064 10.9733 3.96166C10.9496 3.85552 10.9106 3.75359 10.8572 3.65883C10.798 3.55372 10.7149 3.46139 10.5515 3.27985L9.02086 1.57911C8.83153 1.36874 8.73558 1.26211 8.62036 1.18554C8.51675 1.11667 8.40225 1.06588 8.28175 1.03493C8.14575 1 7.99981 1 7.70826 1H2.77787C2.15559 1 1.84422 1 1.60655 1.1211C1.39748 1.22763 1.22763 1.39748 1.1211 1.60655C1 1.84422 1 2.15559 1 2.77787V9.22224C1 9.84451 1 10.1552 1.1211 10.3929C1.22763 10.6019 1.39748 10.7724 1.60655 10.8789C1.84399 10.9999 2.15498 10.9999 2.77604 10.9999H3.2222M8.77769 11V8.88708C8.77769 8.26603 8.77769 7.95503 8.65669 7.71759C8.55019 7.50854 8.37975 7.33865 8.1707 7.23215C7.93303 7.11104 7.62232 7.11104 7.00005 7.11104H5.00007C4.37779 7.11104 4.06642 7.11104 3.82875 7.23215C3.61968 7.33865 3.44982 7.50854 3.3433 7.71759C3.2222 7.95526 3.2222 8.26664 3.2222 8.88891V10.9999M7.66659 3.2222H4.3333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
