import { useParams } from "react-router-dom";
import style from "./TopPanel.module.scss";
import { useAppDispatch } from "store/hooks";
import { Button } from "@ui";
import { showModal } from "@global-state/entity/modal-state/slice";
import { CurrentPage } from "../CurrentPage";

export function TopPanel() {
  const dispatch = useAppDispatch();
  const { propertyId } = useParams();

  return (
    <div className={style["topPanel"]}>
      <CurrentPage />
      <Button
        type="add"
        size="big"
        className="px-[10px] h-[43px] w-fit max-sm:h-[40px]"
        text={!propertyId ? "Додати властивість" : "Додати значення"}
        onClick={() => {
          if (!propertyId) {
            dispatch(showModal({ modalName: "CreatePropertyModal" }));
          } else {
            dispatch(showModal({ modalName: "CreatePropertyValueModal" }));
          }
        }}
      />
    </div>
  );
}
