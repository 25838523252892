import { useParams } from "react-router-dom";
import style from "./CurrentPage.module.scss";
import { BackButton } from "@ui";
import { Helmet } from "react-helmet";
import { useProductPropertiesGroupTreeAllQuery } from "@RTKQuery/product-properties/api";

export function CurrentPage() {
  const { data } = useProductPropertiesGroupTreeAllQuery();

  const { group: groupId, propertyId } = useParams();

  const group = data?.find((item) => item.id === Number(groupId));

  const property = group?.items?.find((item) => item.id === Number(propertyId));

  if (property) {
    return (
      <div className={style["container"]}>
        <Helmet>
          <title>{"Властивість: " + property?.name}</title>
        </Helmet>
        <BackButton url={"./" + groupId} />
        <div className={style["currentPage"]}>
          <p className=" truncate">{property?.name}</p>{" "}
        </div>
      </div>
    );
  } else {
    return (
      <div className={style["container"]}>
        <Helmet>
          <title>Властивості товарів</title>
        </Helmet>
        <p className={style["currentPage"]}>Властивості товарів</p>
      </div>
    );
  }
}
