import { redirect } from "react-router-dom";

import {
  LoginPage,
  RegisterPage,
  VerifyPage,
  ForgotPasswordPage,
  ResetPasswordPage,
} from "../../pages";
import { AccountAuthLayout } from "../../pages/layout/account";

const rootPath = process.env.NODE_ENV === "development" ? "/account" : "";

const routes = [
  {
    path: "login",
    element: <LoginPage />,
  },
  {
    path: "register",
    element: <RegisterPage />,
  },
  {
    path: "verify",
    element: <VerifyPage />,
    loader: () => {
      if (!window.localStorage.getItem("service_token")) {
        return redirect(rootPath + "/auth/register");
      }

      return null;
    },
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "reset-password/:token",
    element: <ResetPasswordPage />,
  },
];

export const accountAuthRouterConfig = [
  {
    path: "*",
    loader: () => {
      return redirect(rootPath + "/auth/login");
    },
  },
  {
    path: process.env.NODE_ENV === "development" ? "account/auth" : "auth",
    element: <AccountAuthLayout />,
    loader: ({ request }: { request: any }) => {
      const pathname = new URL(request.url).pathname;

      if (
        pathname === rootPath + "/auth" ||
        pathname === rootPath + "/auth/" ||
        pathname === rootPath ||
        pathname === rootPath + "/"
      ) {
        return redirect(rootPath + "/auth/login");
      }

      return null;
    },
    children: routes,
  },
];
