import { ProductStock } from "@interfaces/stock";
import style from "./UserProductVariationsColItem.module.scss";
import cn from "classnames";
import { useAppSelector } from "store/hooks";

export function UserProductVariationsColItem({ data }: { data: ProductStock }) {
  const providerStockList = useAppSelector(
    (state) => state.stockPage.activeConnectedProduct?.stock,
  );

  return (
    <div
      className={cn(
        style["item"],
        providerStockList?.some((item) => item.connected_to === data.id) &&
          style["item_connected"],
      )}
    >
      {data.variations?.map((item) => item.name + ": " + item.value + "; ")}
    </div>
  );
}
