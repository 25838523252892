import { HoroshopCategory } from "@interfaces/unloading";
import {
  useHoroshopCategoryUpdateMutation,
  useHoroshopCategoryDeleteMutation,
} from "@RTKQuery/horoshop-categories/api";
import { Button } from "components/common/UI/buttons";
import { Checkbox } from "components/common/UI/Checkbox";
import { useState } from "react";
import style from "./ItemHints.module.scss";

export function ItemHints({
  data,
  listValue,
  setListValue,
}: {
  data: HoroshopCategory;
  listValue: string[];
  setListValue: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const [updateHoroshopCategory] = useHoroshopCategoryUpdateMutation();
  const [deleteHoroshopCategory] = useHoroshopCategoryDeleteMutation();

  const [editMode, setEditMode] = useState<string>("");

  return (
    <div className={style["itemHints"]}>
      <div className="flex gap-[13px]">
        {editMode ? (
          <input
            type="text"
            className={style["editModeInput"]}
            defaultValue={data.name}
            value={editMode}
            onChange={(e) => {
              setEditMode(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                if (editMode) {
                  updateHoroshopCategory({
                    id: data.id,
                    name: editMode,
                  }).unwrap()
                  .then(() => {
                    setListValue(state => state.map(item => {
                      if (item === data.name) {
                         return editMode
                      } else {
                        return item;
                      }
                    }))
                  })
                  .catch(() => {})
                  setEditMode("");
                }
              }
            }}
            onBlur={() => {
              if (editMode) {
                updateHoroshopCategory({
                  id: data.id,
                  name: editMode,
                }).unwrap()
                .then(() => {
                  setListValue(state => state.map(item => {
                    if (item === data.name) {
                       return editMode
                    } else {
                      return item;
                    }
                  }))
                })
                .catch(() => {})
                setEditMode("");
              }
            }}
          />
        ) : (
          <>
            <Checkbox
              checked={listValue.includes(data.name)}
              onChange={(e) => {
                if (e.target.checked) {
                  setListValue((state) => [...state, data.name]);
                } else {
                  setListValue((state) =>
                    state.filter((item) => item !== data.name),
                  );
                }
              }}
            />
            <p>{data.name}</p>
          </>
        )}
      </div>
      <div className="flex gap-[5px]">
        <Button
          type="edit"
          onClick={() => {
            if (editMode) {
              setEditMode("");
            } else {
              setEditMode(data.name);
            }
          }}
        />
        <Button
          type="delete"
          onClick={() => {
            deleteHoroshopCategory(data)
            .unwrap()
            .then(() => {
              setListValue(state => state.filter(item => item !== data.name))
            })
            .catch(() => {})
          }}
        />
      </div>
    </div>
  );
}
