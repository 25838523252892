import { createSlice } from "@reduxjs/toolkit";
import { ProductPropertiesPageData } from "./interface";

import { SliceAction } from "store/store";
import { ProductPropertiesGroup, ProductProperty, ProductPropertyValue } from "@interfaces/product-characteristics";

const initialState: ProductPropertiesPageData = {
    delete_properties: [],
    edit_property: null,

    delete_group: -1,
    edit_group: null,

    delete_values: [],
    edit_values: [],
}

const productPropertiesSlice = createSlice({
    initialState,
    name: "productPropertiesPage",
    reducers: {
        referenceBook_deleteProperties_addData: (
            state,
            action: SliceAction<
                { id: ProductProperty["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.delete_properties = [
                ...state.delete_properties,
                ...action.payload,
            ];
        },
        referenceBook_deleteProperties_setData: (
            state,
            action: SliceAction<
                { id: ProductProperty["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.delete_properties = action.payload;
        },
        referenceBook_deleteProperties_deleteData: (
            state,
            action: SliceAction<{ id: ProductProperty["id"]; singleOnly: boolean }>,
        ) => {
            state.delete_properties =
                state.delete_properties.filter((item) => {
                    if (action.payload.singleOnly) {
                        return item.id !== action.payload.id || item.tag !== "single";
                    } else {
                        return item.id !== action.payload.id;
                    }
                });
        },
        referenceBook_deleteProperties_clearData: (state) => {
            state.delete_properties = [];
        },


        referenceBook_editProperty_setData: (
            state,
            action: SliceAction<ProductPropertiesPageData["edit_property"]>,
        ) => {
            state.edit_property = action.payload;
        },
        referenceBook_editProperty_clearData: (state) => {
            state.edit_property = null;
        },

        referenceBook_deleteGroup_setData: (
            state,
            action: SliceAction<ProductPropertiesGroup["id"]>,
        ) => {
            state.delete_group = action.payload;
        },
        referenceBook_deleteGroup_clearData: (state) => {
            state.delete_group = -1;
        },

        referenceBook_editGroup_setData: (
            state,
            action: SliceAction<Omit<ProductPropertiesGroup, "items">>,
        ) => {
            state.edit_group = action.payload;
        },
        referenceBook_editGroup_clearData: (state) => {
            state.edit_group = null;
        },

        referenceBook_deleteValues_addData: (
            state,
            action: SliceAction<{ id: number; tag: "single" | "multiple" }[]>,
        ) => {
            state.delete_values = [
                ...state.delete_values,
                ...action.payload,
            ];
        },
        referenceBook_deleteValues_setData: (
            state,
            action: SliceAction<{ id: number; tag: "single" | "multiple" }[]>,
        ) => {
            state.delete_values = action.payload;
        },
        referenceBook_deleteValues_deleteData: (
            state,
            action: SliceAction<{ id: number; singleOnly: boolean }>,
        ) => {
            state.delete_values =
                state.delete_values.filter((item) => {
                    if (action.payload.singleOnly) {
                        return item.id !== action.payload.id || item.tag !== "single";
                    } else {
                        return item.id !== action.payload.id;
                    }
                });
        },
        referenceBook_deleteValues_clearData: (state) => {
            state.delete_values = [];
        },

        referenceBook_editValues_addData: (
            state,
            action: SliceAction<
                (ProductPropertyValue & {
                    tag: "single" | "multiple";
                })[]
            >,
        ) => {
            state.edit_values = [
                ...state.edit_values,
                ...action.payload,
            ];
        },
        referenceBook_editValues_setData: (
            state,
            action: SliceAction<
                (ProductPropertyValue & {
                    tag: "single" | "multiple";
                })[]
            >,
        ) => {
            state.edit_values = action.payload;
        },
        referenceBook_editValues_deleteData: (
            state,
            action: SliceAction<{ id: number; singleOnly: boolean }>,
        ) => {
            state.edit_values =
                state.edit_values.filter((item) => {
                    if (action.payload.singleOnly) {
                        return item.id !== action.payload.id || item.tag !== "single";
                    } else {
                        return item.id !== action.payload.id;
                    }
                });
        },
        referenceBook_editValues_clearData: (state) => {
            state.edit_values = [];
        },

        referenceBook_clearAllData: (state) => {
            state.delete_properties = [];
            state.edit_property = null;

            state.delete_group = -1;
            state.edit_group = null;

            state.delete_values = [];
            state.edit_values = [];
        }
    }
})

const { actions, reducer } = productPropertiesSlice;

export default reducer;

export const {
    referenceBook_deleteProperties_addData,
    referenceBook_deleteProperties_setData,
    referenceBook_deleteProperties_deleteData,
    referenceBook_deleteProperties_clearData,

    referenceBook_editProperty_setData,
    referenceBook_editProperty_clearData,

    referenceBook_deleteGroup_setData,
    referenceBook_deleteGroup_clearData,

    referenceBook_editGroup_setData,
    referenceBook_editGroup_clearData,

    referenceBook_deleteValues_addData,
    referenceBook_deleteValues_setData,
    referenceBook_deleteValues_deleteData,
    referenceBook_deleteValues_clearData,

    referenceBook_editValues_addData,
    referenceBook_editValues_setData,
    referenceBook_editValues_deleteData,
    referenceBook_editValues_clearData,

    referenceBook_clearAllData
} = actions;