import { useState } from "react";
import { DarkSvg, LightSvg } from ".";

import style from "./ThemeSwitch.module.scss";

export function ThemeSwitch() {
  const [currentTheme, setCurrentTheme] = useState<"dark" | "light">(
    localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
      ? "dark"
      : "light",
  );

  return (
    <div
      className={style["switch"]}
      onClick={() => {
        if (currentTheme === "light") {
          document.documentElement.classList.add("dark");
          window.localStorage.setItem("theme", "dark");
          setCurrentTheme("dark");
        } else {
          document.documentElement.classList.remove("dark");
          window.localStorage.setItem("theme", "light");
          setCurrentTheme("light");
        }
      }}
    >
      <div className={style["core"]}>
        {currentTheme === "dark" ? <DarkSvg /> : <LightSvg />}
      </div>
    </div>
  );
}
