import { useBalanceQuery } from "@RTKQuery/balance/api";
import style from "./CurrentBalance.module.scss";
import { TopUpBalanceForm } from "@forms";

export function CurrentBalance() {
  const { data } = useBalanceQuery();

  return (
    <div className={style["container"]}>
      <div className={style["label"]}>Загальний баланс</div>
      <div className={style["content"]}>
        <p className={style["balance"]}>$ {formatNumber(data?.balance || 0)}</p>
        <div className={style["mobileDivider"]}></div>
        <TopUpBalanceForm />
      </div>
    </div>
  );
}

function formatNumber(num: number) {
  let [integerPart, decimalPart] = num.toString().split(".");

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  if (decimalPart) {
    decimalPart = parseFloat("0." + decimalPart)
      .toFixed(2)
      .split(".")[1];
  } else {
    decimalPart = "00";
  }

  return integerPart + "." + decimalPart;
}
