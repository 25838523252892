import { authApi } from "..";
import { Functionalities, FunctionalitiesTariff } from "@interfaces/functionalities";
import { CRMUserApi } from "@RTKQuery/CRMUser/api";

const functionalitiesApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        functionalitiesAll: build.query<Functionalities[], void>({
            query: () => "functionalities"
        }),
        functionalitiesActivate: build.mutation<Functionalities[], {
            functionalityId: Functionalities["id"],
            tariffId: FunctionalitiesTariff["id"]
        }>({
            query: (body) => {
                return {
                    url: "functionalities/activate",
                    method: "POST",
                    body: {
                        id: body.functionalityId,
                        tariff_id: body.tariffId
                    }
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        functionalitiesApi.util.upsertQueryData(
                            "functionalitiesAll",
                            undefined,
                            data,
                        ),
                    );
                    dispatch(
                        CRMUserApi.util.updateQueryData(
                            "CRMUser",
                            undefined,
                            (draft) => {
                                const active_functionalities: Functionalities["id"][] = [];

                                data.forEach(item => {
                                    if (item.status === "active") {
                                        active_functionalities.push(item.id)
                                    } 
                                })

                                draft.settings.active_functionalities = active_functionalities;
                            },
                        ),
                    );
                } catch { }
            },
        }),
        functionalitiesDeactivate: build.mutation<Functionalities[], Functionalities["id"]>({
            query: (id) => {
                return {
                    url: "functionalities/deactivate?items=" + id,
                    method: "DELETE",
                }
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        functionalitiesApi.util.upsertQueryData(
                            "functionalitiesAll",
                            undefined,
                            data,
                        ),
                    );
                    dispatch(
                        CRMUserApi.util.updateQueryData(
                            "CRMUser",
                            undefined,
                            (draft) => {
                                const active_functionalities: Functionalities["id"][] = [];

                                data.forEach(item => {
                                    if (item.status === "active") {
                                        active_functionalities.push(item.id)
                                    } 
                                })

                                draft.settings.active_functionalities = active_functionalities;
                            },
                        ),
                    );
                } catch { }
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useFunctionalitiesAllQuery,
    useFunctionalitiesActivateMutation,
    useFunctionalitiesDeactivateMutation
} = functionalitiesApi;
