import { createSlice } from "@reduxjs/toolkit";

import { ProductsPageData } from "./interface";
import { ProductExtended, ProductTable } from "@interfaces/product";
import Category from "@interfaces/category";
import { SliceAction } from "store/store";
import { ProductPropertiesGroup } from "@interfaces/product-characteristics";

const initialState: ProductsPageData = {
    category: {
        add: -1,
        delete: null,
        edit: null,
    },

    product: {
        delete: [],
    },

    importData: [],
    importRequiredColumn: [
        {
            name: "Артикул",
            type: "str"
        },
        {
            name: "Родительский артикул",
            type: "str"
        },
        {
            name: "Название модификации (UA)",
            type: "str"
        },
        {
            name: "Название модификации (RU)",
            type: "str"
        },
        {
            name: "Название (UA)",
            type: "str"
        },
        {
            name: "Название (RU)",
            type: "str"
        },
        {
            name: "Раздел",
            type: "str"
        },
        {
            name: "Цена",
            type: "num"
        },
        {
            name: "Старая цена",
            type: "num"
        },
        {
            name: "Дополнительные разделы",
            type: "any"
        },
        {
            name: "Фото",
            type: "str"
        },
        {
            name: ["Количество", "Кількість"], // Саме перший елемент в масиві буде ключем для відправки даних на сервер
            type: "num"
        },
        {
            name: "Описание товара (UA)",
            type: "str"
        },
        {
            name: "Описание товара (RU)",
            type: "str"
        },
    ],
    importSelectedColumn: [],
    counterSameCharsError: {},
    importSelectedPropertiesGroupId: -1 
}

const productsSlice = createSlice({
    initialState,
    name: "productsPage",
    reducers: {
        category_add_setData: (
            state,
            action: SliceAction<Category["id"] | null>,
        ) => {
            state.category.add = action.payload;
        },
        category_delete_setData: (state, action: SliceAction<Category>) => {
            state.category.delete = action.payload;
        },
        category_edit_setData: (state, action: SliceAction<Category>) => {
            state.category.edit = action.payload;
        },

        product_delete_addData: (
            state,
            action: SliceAction<
                { id: ProductTable["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.product.delete = [...state.product.delete, ...action.payload];
        },
        product_delete_setData: (
            state,
            action: SliceAction<
                { id: ProductTable["id"]; tag: "single" | "multiple" }[]
            >,
        ) => {
            state.product.delete = action.payload;
        },
        product_delete_deleteData: (
            state,
            action: SliceAction<{ id: ProductTable["id"]; singleOnly: boolean }>,
        ) => {
            state.product.delete = state.product.delete.filter((item) => {
                if (action.payload.singleOnly) {
                    return item.id !== action.payload.id || item.tag !== "single";
                } else {
                    return item.id !== action.payload.id;
                }
            });
        },
        product_delete_clearData: (state) => {
            state.product.delete = [];
        },


        setImportData: (state, action: SliceAction<ProductsPageData["importData"]>) => {
            state.importData = action.payload;
        },

        addImportSelectedColumn: (state, action: SliceAction<ProductsPageData["importSelectedColumn"][0]>) => {
            if (action.payload.tag === "characteristic") {
                if (action.payload?.payload?.id) {
                    state.counterSameCharsError[action.payload.payload.id] = (state.counterSameCharsError[action.payload.payload.id] || 0) + 1;
                }
            }
            state.importSelectedColumn.push(action.payload);
        },

        removeImportSelectedColumn: (state, action: SliceAction<ProductsPageData["importSelectedColumn"][0]["index"]>) => {
            state.importSelectedColumn = state.importSelectedColumn.filter(item => {
                if (item.tag === "characteristic") {
                    if ((item.index === action.payload) && item.payload) {
                        state.counterSameCharsError[item.payload.id] = state.counterSameCharsError[item.payload.id] - 1;
                        if (!state.counterSameCharsError[item.payload.id]) {
                            delete state.counterSameCharsError[item.payload.id];
                        }
                    }
                }

                return item.index !== action.payload;
            });
        },
        clearByTagImportSelectedColumn: (state, action: SliceAction<"characteristic" | "variation">) => {
            if (action.payload === "characteristic") {
                state.counterSameCharsError = {};
                state.importSelectedColumn = state.importSelectedColumn.map(item => {
                    if (item.tag === "characteristic") {
                        return {
                            index: item.index,
                            tag: item.tag
                        }
                    } else {
                        return item;
                    }
                })
            } else {
                state.importSelectedColumn = state.importSelectedColumn.filter(item => item.tag !== "variation");
            }
        },

        importSelectedPropertiesGroupIdSet: (state, action: SliceAction<ProductPropertiesGroup["id"]>) => {
            state.importSelectedPropertiesGroupId = action.payload;
        },

        importSelectedPropertiesGroupIdClear: (state) => {
            state.importSelectedPropertiesGroupId = -1;
        },

        clearImportData: (state) => {
            state.importData = [];
            state.importSelectedColumn = [];
            state.counterSameCharsError = {};
            state.importSelectedPropertiesGroupId = -1;
        },

        products_clearAllData: (state) => {
            state.category = {
                add: -1,
                delete: null,
                edit: null,
            };

            state.product = {
                delete: [],

            };
        },

        
    }
})

const { reducer, actions } = productsSlice;

export default reducer;

export const {
    category_add_setData,
    category_delete_setData,
    category_edit_setData,

    product_delete_addData,
    product_delete_setData,
    product_delete_deleteData,
    product_delete_clearData,

    setImportData,
    addImportSelectedColumn,
    removeImportSelectedColumn,
    clearByTagImportSelectedColumn,

    importSelectedPropertiesGroupIdSet,
    importSelectedPropertiesGroupIdClear,

    clearImportData,

    products_clearAllData
} = actions;