export function LightSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <path
        d="M9.49994 1.43994V2.3355M9.49994 16.6644V17.5599M2.3355 9.49994H1.43994M4.40792 4.40792L3.67883 3.67883M14.592 4.40792L15.3211 3.67883M4.40792 14.5957L3.67883 15.3211M14.592 14.5957L15.3211 15.3211M17.5599 9.49994H16.6644M13.0822 9.49994C13.0822 11.4783 11.4783 13.0822 9.49994 13.0822C7.52153 13.0822 5.91772 11.4783 5.91772 9.49994C5.91772 7.52153 7.52153 5.91772 9.49994 5.91772C11.4783 5.91772 13.0822 7.52153 13.0822 9.49994Z"
        strokeWidth="1.86"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
