import { useEffect, useState } from "react";

import { RxCrossCircled } from "react-icons/rx";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { useField, useFormikContext } from "formik";
import { PasswordInputProps } from "../..";
import cn from "classnames";

import style from "./Password.module.scss";

export function PasswordInput({
  formikName,
  label,
  helperText,
  passwordRef,
  placeholder,
}: PasswordInputProps) {
  const { setFieldValue, values } = useFormikContext<Record<string, any>>();
  const [field, meta] = useField(formikName);

  const [visiblePass, setVisiblePass] = useState<boolean>(false);

  useEffect(() => {
    if (passwordRef) {
      passwordRef.current = field.value;
    }
  }, [field.value]);

  return (
    <div>
      {typeof label === "string" ? (
        <p className={style["label"]}>{label}</p>
      ) : (
        label
      )}
      <div
        className={cn(
          style["container"],
          meta.touched && meta.error ? style["error_on"] : style["error_off"],
        )}
      >
        <input
          {...field}
          className={style["input"]}
          type={visiblePass ? "text" : "password"}
          placeholder={placeholder || ""}
        />
        <div className={style["buttons"]}>
          {values[formikName] ? (
            <RxCrossCircled
              className={style["icon"]}
              onClick={(e) => {
                setFieldValue(formikName, "");
              }}
            />
          ) : null}
          <div className={style["visibleButton"]}>
            {visiblePass ? (
              <IoEyeOutline
                className={style["icon"]}
                onClick={() => setVisiblePass(false)}
              />
            ) : (
              <IoEyeOffOutline
                className={style["icon"]}
                onClick={() => setVisiblePass(true)}
              />
            )}
          </div>
        </div>
      </div>
      {meta.touched && meta.error ? (
        helperText ? (
          helperText(meta.error || "")
        ) : (
          <p className={style["errorMessage"]}>{meta.error || ""}</p>
        )
      ) : null}
    </div>
  );
}
