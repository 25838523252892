import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./MultipleActionsValues.module.scss";
import { Button } from "@ui";
import { showModal } from "@global-state/entity/modal-state/slice";
import cn from "classnames";

export function MultipleActionsValues() {
  const delete_values = useAppSelector(
    (state) => state.productPropertiesPage.delete_values,
  );
  const edit_values = useAppSelector(
    (state) => state.productPropertiesPage.edit_values,
  );

  const dispatch = useAppDispatch();

  return (
    <div
      className={cn(
        style["multipleActions"],
        (delete_values.some((item) => item.tag === "multiple") ||
          edit_values.some((item) => item.tag === "multiple")) &&
          "h-[50px]",
      )}
    >
      <div className={style["button"]}>
        <Button
          type="delete"
          size="big"
          text="Видалити"
          onClick={() => {
            dispatch(
              showModal({
                modalName: "DeletePropertyValueModal",
                mode: "multiple",
              }),
            );
          }}
        />
      </div>
      <div className={style["button"]}>
        <Button
          type="edit"
          size="big"
          variant="outlined"
          text="Редагувати"
          onClick={() => {
            dispatch(
              showModal({
                modalName: "UpdatePropertyValueModal",
                mode: "multiple",
              }),
            );
          }}
        />
      </div>
    </div>
  );
}
