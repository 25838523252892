import { generateCombinations } from "@global-state/pages/product/slice";
import { authApi } from "..";
import {
  ProductChildren,
  ProductExtended,
  ProductTable,
  ProductVariations,
} from "../../interfaces/product";
import { ImportExcel } from "@interfaces/import";
import { Pagination } from "@interfaces/shared";

interface ProductUpdateVariations {
  product: {
    id: ProductExtended["id"];
    variations: ProductExtended["variations"];
  };
  children: {
    id?: ProductExtended["id"];
    sku?: ProductExtended["sku"];
    variations: ProductExtended["variations"];
  }[];
}

const productsApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    productsAll: build.query<Pagination<{ products: ProductTable[] }>, {
      category_id: ProductExtended["category_id"];
      offset: Pagination<void>["offset"];
      limit: Pagination<void>["limit"];
      search?: string;
    }>({
      query: ({ category_id, limit, offset, search }) =>
        `category/${category_id}/products?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""}`,
      providesTags: (result, error, page) =>
        result
          ? [
            {
              type: "Product" as const,
              id: page.category_id + "|" + page.limit + "|" + page.offset,
            },
            { type: "Product", id: "PRODUCT-LIST" },
          ]
          : [{ type: "Product", id: "PRODUCT-LIST" }],
    }),
    productsOne: build.query<ProductChildren<ProductExtended> & ProductVariations, ProductExtended["id"]>({
      query: (idProduct) => `products/${idProduct}`,
      providesTags: ["Product"],
    }),
    productCreate: build.mutation<ProductChildren<ProductExtended> & ProductVariations, {
      payload: 
        ProductChildren<
          Omit<
            ProductExtended,
            "id" | "parent_id"  | "pictures" | "properties"
          > & { properties?: Record<string, number[]> }
        >
      ;
      pictures: {
        files: Record<string, File>;
        positions: string[];
      };
    }>({
      query: (product) => {
        const formData = new FormData();
        product.pictures.positions.forEach((fileId) =>
          formData.append("pictures", product.pictures.files[fileId]),
        );
        formData.append("payload", JSON.stringify(product.payload));

        return {
          url: "products",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Product", id: "PRODUCT-LIST" }],
    }),
    productUpdate: build.mutation<{ product: ProductExtended }, {
      payload: Omit<
        ProductExtended,
        "pictures"  | "variations" | "properties"
      > & { properties: Record<string, number[]> };
      pictures: {
        files: Record<string, File>;
        positions: string[];
      };
    }>({
      query: (product) => {
        const formData = new FormData();

        product.pictures.positions.forEach((fileId) => {
          if (product.pictures.files[fileId].name) {
            formData.append("pictures", product.pictures.files[fileId]);
          } else {
            formData.append(
              "pictures",
              product.pictures.files[fileId],
              `${fileId}.json`,
            );
          }
        });
        formData.append("payload", JSON.stringify(product.payload));

        return {
          url: `products/${product.payload.id}`,
          method: "PUT",
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Product", id: "PRODUCT-LIST" }],
      async onQueryStarted(
        { payload: { id, parent_id } },
        { dispatch, queryFulfilled },
      ) {
        try {
          const { data: updatedPost } = await queryFulfilled;

          if (!parent_id) {
            dispatch(
              productsApi.util.updateQueryData("productsOne", id, (draft) => {
                draft.product = updatedPost.product;
              }),
            );
          } else {
            dispatch(
              productsApi.util.updateQueryData("productsOne", parent_id, (draft) => {
                const idMutableItem = draft.children.findIndex(
                  (item) => item.id === updatedPost.product.id,
                );

                draft.children[idMutableItem] = updatedPost.product;
              }),
            );
          }
        } catch { }
      },
    }),
    productDelete: build.mutation<void, | ProductExtended["id"][] | {
      parentId: ProductExtended["id"];
      childId: ProductExtended["id"];
    }>({
      query: (data) => {
        let listItem = "";

        if (Array.isArray(data)) {
          listItem = data.map((id) => `items=${id}`).join("&");
        } else {
          listItem = `items=${data.childId}`;
        }

        return {
          url: `products?${listItem}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Product", id: "PRODUCT-LIST" }],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        if (!Array.isArray(data)) {
          try {
            await queryFulfilled;

            dispatch(
              productsApi.util.updateQueryData(
                "productsOne",
                data.parentId,
                (draft) => {
                  draft.children = draft.children.filter(
                    (item) => item.id !== data.childId,
                  );
                },
              ),
            );
          } catch { }
        }
      },
    }),
    productDeleteVariations: build.mutation<ProductChildren<ProductExtended> & ProductVariations, {
      parentId: ProductExtended["id"];
      childIds: ProductExtended["id"][];
    }>({
      query: ({ childIds, parentId }) => {
        let listItem = childIds.map((id) => `items=${id}`).join("&");

        return {
          url: `products/variations?${listItem}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [{ type: "Product", id: "PRODUCT-LIST" }],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        if (!Array.isArray(data)) {
          try {
            const newData = await queryFulfilled;

            dispatch(
              productsApi.util.upsertQueryData(
                "productsOne",
                data.parentId,
                newData.data,
              ),
            );
          } catch { }
        }
      },
    }),
    productUpdateVariations: build.mutation<ProductChildren<ProductExtended> & ProductVariations, {
      product: ProductChildren<ProductExtended>;
      variations: ProductVariations["variations"];
    }>({
      query: ({ product, variations }) => {
        let combinations = generateCombinations(variations);
        let combinationsConcatStr = combinations.map((combination) =>
          combination.reduce<string>((concatStr, item) => {
            return concatStr + "|" + item.name + ":" + item.value;
          }, ""),
        );

        const newVariations: ProductUpdateVariations = {
          product: {
            id: product.product.id,
            variations: (() => {
              const variationsConcatStr =
                (product.product.variations || []).reduce<string>((concatStr, item) => {
                  return concatStr + "|" + item.name + ":" + item.value;
                }, "");

              const combinationIndex = combinationsConcatStr.findIndex(
                (combination) => combination.includes(variationsConcatStr),
              );

              const combination = combinations.splice(combinationIndex, 1)[0];

              combinationsConcatStr.splice(combinationIndex, 1);

              return combination?.slice(
                variationsConcatStr.split("|").length - 1,
              ) || [];
            })(),
          },
          children: [
            ...product.children.map((child) => {
              const variationsConcatStr = child.variations.reduce<string>(
                (concatStr, item) => {
                  return concatStr + "|" + item.name + ":" + item.value;
                },
                "",
              );

              const combinationIndex = combinationsConcatStr.findIndex(
                (combination) => combination.includes(variationsConcatStr),
              );

              const combination = combinations.splice(combinationIndex, 1)[0];

              combinationsConcatStr.splice(combinationIndex, 1);

              return {
                id: child.id,
                variations: combination?.slice(
                  variationsConcatStr.split("|").length - 1,
                ) || [],
              };
            }),
            ...combinations.map((combination) => ({
              sku:
                product.product.sku.split("-")[0] +
                "-" +
                combination.map((elem) => elem.value).join("-"),
              variations: combination,
            })),
          ],
        };

        return {
          url: `products/variations`,
          method: "POST",
          body: newVariations,
        };
      },
      async onQueryStarted({ product }, { dispatch, queryFulfilled }) {
        try {
          const { data: newData } = await queryFulfilled;

          dispatch(
            productsApi.util.upsertQueryData(
              "productsOne",
              product.product.id,
              newData,
            ),
          );
        } catch { }
      },
    }),
    productAddPictures: build.mutation<void, {
      payload: {
        products: ProductExtended["id"][];
        parent_id: ProductExtended["id"];
      };
      pictures: {
        files: Record<string, File>;
        positions: string[];
      };
    }>({
      query: (product) => {
        const formData = new FormData();

        product.pictures.positions.forEach((fileId) =>
          formData.append("pictures", product.pictures.files[fileId]),
        );
        formData.append("payload", JSON.stringify(product.payload));

        return {
          url: "products/multi/pictures",
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["Product"],
    }),
    productImportExcel: build.mutation<{
      ok: boolean,
      products: {
        errors: string[],
        added: number,
        existing: number
      },
      pictures: {
        errors: string[],
        added: number
      }
    }, ImportExcel>({
      query: (data) => {
        return {
          url: "horoshop/import/json",
          method: "POST",
          body: data,
        };
      },
    })
  }),
  overrideExisting: false,
});

export const {
  useProductsAllQuery,
  useProductsOneQuery,
  useLazyProductsOneQuery,
  useProductCreateMutation,
  useProductUpdateMutation,
  useProductDeleteMutation,
  useProductDeleteVariationsMutation,
  useProductUpdateVariationsMutation,
  useProductAddPicturesMutation,
  useProductImportExcelMutation
} = productsApi;
