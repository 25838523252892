import { AllPropertiesCheckbox } from "./components";
import style from "./TablePropertiesHead.module.scss";

export function TablePropertiesHead() {
  return (
    <div className={style["head"]}>
      <AllPropertiesCheckbox />
      <div className={style["label"]}>Назва властивості</div>
      <div className={style["label"]}>Тип властивості</div>
    </div>
  );
}
