import { ProductProvider } from "@interfaces/stock";
import { useLazyProductsProvidersQuery } from "@RTKQuery/stock/api";
import { Spinner } from "@ui";
import { useEffect } from "react";
import { useAppSelector } from "store/hooks";
import style from "./TableProductsProvidersBody.module.scss";

export function TableProductsProvidersBody({
  sortBy,
  order,
  children,
}: {
  sortBy: string;
  order: string;
  children: (data: ProductProvider) => JSX.Element;
}) {
  const [getProductsProviders, { data, isFetching, isError }] =
    useLazyProductsProvidersQuery();

  const activeModal = useAppSelector(
    (state) => state.modalState["AddProductProviderModal"],
  );
  const searchText = useAppSelector(
    (state) => state.stockPage.searchTextProductProvider,
  );

  useEffect(() => {
    if (activeModal?.visibility) {
      getProductsProviders(searchText, true);
    }
  }, [activeModal, searchText]);

  if (isFetching) {
    return (
      <div className={style["centered"]}>
        <Spinner />
      </div>
    );
  } else if (isError) {
    return (
      <div className={style["centered"]}>
        <p className={style["message"]}>Щось пішло не так.</p>
      </div>
    );
  } else {
    return (
      <>
        {data?.products
          .slice()
          .sort((a, b) => {
            if (sortBy === "provider") {
              return sort(a.supplier, b.supplier, order);
            } else if (sortBy === "name") {
              return sort(a.name, b.name, order);
            } else {
              return 0;
            }
          })
          .map((item) => children(item))}
      </>
    );
  }
}

function sort<T>(a: T, b: T, order: string) {
  if (typeof a === "string" && typeof b === "string") {
    return order === "asc" ? a.localeCompare(b) : b.localeCompare(a);
  } else if (typeof a === "number" && typeof b === "number") {
    return order === "asc" ? a - b : b - a;
  } else {
    return 0;
  }
}
