import { useEffect } from "react";
import { allFiles } from "../ProductImages/ProductImages";
import { useNavigate, useParams } from "react-router-dom";
import {
  useProductCreateMutation,
  useProductUpdateMutation,
} from "@RTKQuery/products/api";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { SubmitButton } from "@ui";
import { setPicturesIsValid, visibleAllErrorProperty } from "@global-state/pages/product/slice";

export function ProductSubmitButton({
  basicFormRef,
}: {
  basicFormRef: React.RefObject<HTMLButtonElement>;
}) {
  const { productId, variationId, categoryId } = useParams();

  const [productCreate, createStatus] = useProductCreateMutation({
    fixedCacheKey: "product-create",
  });

  const [productUpdate, updateStatus] = useProductUpdateMutation({
    fixedCacheKey: "product-edit",
  });

  const {
    basicInfo: { data, isValid },
    pictures,
    
    combinationsSelectedVariationValues: combinations,
    properties,

    properties_group_id,

    errorsChars,
    errorsVariations,

    activatedChars, 
    activatedVariations
  } = useAppSelector((state) => state.productPage);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const crm =
    process.env.NODE_ENV === "development"
      ? "/" + window.localStorage.getItem("subdomain")
      : "";

    if (createStatus.isSuccess || updateStatus.isSuccess) {
      createStatus.reset();
      updateStatus.reset();
      navigate(crm + `/catalog/${categoryId}`);
    }

    if (createStatus.isError || updateStatus.isError) {
      const timer = setTimeout(() => {
        createStatus.reset();
        updateStatus.reset();
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [
    createStatus.isSuccess,
    createStatus.isError,
    updateStatus.isSuccess,
    updateStatus.isError,
  ]);

  return (
    <SubmitButton
      className="max-sm:rounded-[6px] max-sm:text-[12px] "
      type="button"
      disabled={createStatus.isLoading || updateStatus.isLoading}
      isUninitialized={
        createStatus.isUninitialized && updateStatus.isUninitialized
      }
      isLoading={createStatus.isLoading || updateStatus.isLoading}
      isSuccess={createStatus.isSuccess || updateStatus.isSuccess}
      isError={createStatus.isError || updateStatus.isError}
      uninitializedText="Зберегти"
      successText="Успішно збережено"
      errorText="Не вдалось зберегти"
      errorMesage={
        (createStatus.error
          ? (createStatus.error as unknown as { data: { error: string } }).data
              ?.error
          : "") ||
        (updateStatus.error
          ? (
              updateStatus.error as unknown as {
                data: { error: string };
              }
            ).data?.error
          : "")
      }
      onClick={() => {
        basicFormRef.current?.click();

        const isNotValidPictures = pictures.length === 0;
        const isNotValidProperties = (activatedChars && Object.keys(errorsChars).length !== 0) || (activatedVariations && Object.keys(errorsVariations).length !== 0);

        if (isNotValidPictures) {
          dispatch(setPicturesIsValid(false));
        }

        if (isNotValidProperties) {
          dispatch(visibleAllErrorProperty());
        }

        if (isNotValidPictures || isNotValidProperties || !isValid) {
          return null;
        }

        if (data) {
          if (!productId) {
            productCreate({
              payload: {
                product: {
                  ...data,
                  sku:
                    data.sku +
                    (combinations[0].length
                      ? "-" +
                      combinations[0].map((elem) => elem.value).join("-")
                      : ""),
                  variations: combinations[0],
                  properties,
                  properties_group_id
                },
                children: combinations.slice(1).map((item) => ({
                  ...data,
                  sku:
                    data.sku + "-" + item.map((elem) => elem.value).join("-"),
                  variations: item,
                  properties,
                })),
              },
              pictures: {
                files: allFiles,
                positions: pictures.map((item) => item.id),
              },
            });
          } else {
            productUpdate({
              payload: {
                id: variationId ? Number(variationId) : Number(productId),
                parent_id: variationId ? Number(productId) : null,
                ...data,
                properties,
              },
              pictures: {
                files: allFiles,
                positions: pictures.map((item) => item.id),
              },
            });
          }
        }
      }}
    />
  );
}
