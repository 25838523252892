import {
  AllProducts,
  ParentProduct,
  Providers,
  TopPanel,
} from "components/catalog/stock";
import style from "./StockOnePage.module.scss";
import {
  AddProductProviderModal,
  ConnectedProductStockModal,
  DeleteProductProviderModal,
} from "@modals";
import { useEffect } from "react";
import { clearAllStockData } from "@global-state/pages/stock/slice";
import { useAppDispatch } from "store/hooks";
import { useParams } from "react-router-dom";
import { useProductStockOneQuery } from "@RTKQuery/stock/api";
import { Spinner } from "@ui";
import { Helmet } from "react-helmet";

export function StockOnePage() {
  const { productId } = useParams();

  const { data, isFetching, isError, error } = useProductStockOneQuery(
    Number(productId),
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearAllStockData());
    };
  }, []);

  if (isFetching) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <Spinner className="fill-[#FFFFFF] dark:fill-[#2C2D31]" />
      </div>
    );
  } else if (isError) {
    return (
      <div className="flex h-[100vh] w-full items-center justify-center">
        <p className="text-[22px] font-medium text-[#6A6A6A] max-sm:text-[18px]">
          {error
            ? (error as unknown as { data: { error: string } }).data?.error
            : "Щось пішло не так."}
        </p>
      </div>
    );
  } else {
    return (
      <main className={style["layout"]}>
        <Helmet>
          <title>{"Наявність: " + data?.product.ua_name}</title>
        </Helmet>
        <TopPanel />
        <div className={style["scrollContainer"]}>
          <ParentProduct />
          <div className={style["flexContainer"]}>
            <AllProducts />
            <Providers />
          </div>
        </div>
        <DeleteProductProviderModal />
        <AddProductProviderModal />
        <ConnectedProductStockModal />
      </main>
    );
  }
}
