import { Button, SwitchButton } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import style from "./DynamicMarkupList.module.scss";
import {
  addFormulaMarkupRange,
  switchUseFormulaMarkup,
} from "@global-state/pages/unloading/slice";
import { DynamicMarkupItem } from "../DynamicMarkupItem";
import cn from "classnames";

export function DynamicMarkup() {
  const { use_formula_markup, formula_markup } = useAppSelector(
    (state) => state.unloadingPage.unloadingInfoObject,
  );

  const dispatch = useAppDispatch();

  return (
    <div className={style["dynamicMarkup"]}>
      <div className={style["switchButtonBox"]}>
        <SwitchButton
          label="Динамічна націнка"
          checked={Boolean(use_formula_markup)}
          onChange={() => {
            dispatch(switchUseFormulaMarkup());
          }}
        />
      </div>
      <div className={style["formulaMarkup"]}>
        <div className={cn(style["rangesList"], "scroll")}>
          {formula_markup?.map((item, index) => (
            <DynamicMarkupItem key={item.id} data={item} index={index} />
          ))}
        </div>
        <div className={style["addRangeButton"]}>
          <Button
            size="big"
            variant="contained"
            type="add"
            disabled={!use_formula_markup}
            onClick={() => {
              dispatch(addFormulaMarkupRange());
            }}
          />
        </div>
      </div>
    </div>
  );
}
