import { FormulaMarkup } from "@interfaces/unloading";
import style from "./DynamicMarkupItem.module.scss";
import { InfinitySvg } from "../DynamicMarkupList/Infinity.svg";
import { Button, InputNoFormik } from "@ui";
import { useAppDispatch, useAppSelector } from "store/hooks";
import cn from "classnames";
import {
  addFormulaMarkupErrors,
  deleteFormulaMarkupRange,
  removeFormulaMarkupErrors,
  setFormulaMarkupMarkUp,
  setFormulaMarkupToPrice,
} from "@global-state/pages/unloading/slice";
import { useEffect } from "react";

export function DynamicMarkupItem({
  data,
  index,
}: {
  data: FormulaMarkup;
  index: number;
}) {
  const { use_formula_markup, formula_markup } = useAppSelector(
    (state) => state.unloadingPage.unloadingInfoObject,
  );
  const error = useAppSelector(
    (state) => state.unloadingPage.formulaMarkupErrors[data.id],
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (error) {
      dispatch(
        addFormulaMarkupErrors({
          idRange: data.id,
          errorText: `Значення повинно бути більшим за: ${data.from_price}`,
        }),
      );
    }
  }, [data.from_price]);

  useEffect(() => {
    return () => {
      dispatch(removeFormulaMarkupErrors(data.id));
    };
  }, []);

  return (
    <>
      <div className={style["range"]}>
        <input
          value={data.from_price}
          readOnly
          className={cn(
            style["autoFromPrice"],
            !use_formula_markup && "opacity-55",
          )}
        />
        <p
          className={cn(
            style["specSymbol"],
            !use_formula_markup && "opacity-55",
          )}
        >
          {"<"}
        </p>
        {data.to_price === null ? (
          <div
            className={cn(
              style["infinity"],
              !use_formula_markup && "opacity-55",
            )}
          >
            <InfinitySvg />
          </div>
        ) : (
          <InputNoFormik
            label="До*"
            defaultValue={data.to_price ? String(data.to_price) : ""}
            disabled={!use_formula_markup}
            validate={(value) => {
              return value.replace(/\D/g, "");
            }}
            setValue={(newValue) => {
              if (Number(newValue) <= data.from_price) {
                dispatch(
                  addFormulaMarkupErrors({
                    idRange: data.id,
                    errorText: `Значення повинно бути більшим за: ${data.from_price}`,
                  }),
                );
              } else {
                dispatch(removeFormulaMarkupErrors(data.id));
              }
              dispatch(
                setFormulaMarkupToPrice({
                  value: Number(newValue),
                  indexRange: index,
                }),
              );
            }}
            error={Boolean(error)}
          />
        )}
        <p
          className={cn(
            style["specSymbol"],
            !use_formula_markup && "opacity-55",
          )}
        >
          {"="}
        </p>
        <InputNoFormik
          label="Націнка*"
          defaultValue={data.mark_up ? String(data.mark_up) : ""}
          disabled={!use_formula_markup}
          validate={(value) => {
            return value.replace(/\D/g, "");
          }}
          setValue={(newValue) => {
            dispatch(
              setFormulaMarkupMarkUp({
                value: Number(newValue),
                indexRange: index,
              }),
            );
          }}
        />
        {index === 0 || index === (formula_markup?.length || 1) - 1 ? (
          <div className="absolute right-0"></div>
        ) : (
          <div className="absolute -right-[44px]  mt-[30px] max-sm:-right-[30px]">
            <Button
              type="delete"
              disabled={!use_formula_markup}
              onClick={() => {
                dispatch(deleteFormulaMarkupRange(index));
              }}
            />
          </div>
        )}
      </div>
      <div
        className={cn(
          style["errorMessage"],
          !use_formula_markup && "opacity-55",
        )}
      >
        {error}
      </div>
    </>
  );
}
