import { HoroshopCategory, MarketplaceId, ProductHoroshop, UnloadingExtended, UnloadingLink } from "@interfaces/unloading";
import { authApi } from "..";
import { Pagination } from "@interfaces/shared";
import Category from "@interfaces/category";
import { PartialExcept } from "store/store";

const unloadingApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        unloadingAll: build.query<UnloadingLink[], MarketplaceId>({
            query: (id) => `unloadings/list?marketplace_id=${id}`,
            providesTags: ["UnloadingList"],
        }),
        unloadingOne: build.query<UnloadingExtended, UnloadingLink["id"]>({
            query: (id) => `unloadings/horoshop/${id}`,
            transformResponse: (response) => {
                const data = response as UnloadingExtended;

                data.formula_markup =
                    data.formula_markup ?
                        data.formula_markup.map(item => ({
                            ...item,
                            id: crypto.randomUUID()
                        })) : null

                return data;
            }
        }),
        productsAllHoroshop: build.query<Pagination<{ products: ProductHoroshop[] }>, {
            categoriesIds: Category["id"][];
            unloadingId: UnloadingExtended["id"];
            offset: Pagination<void>["offset"];
            limit: Pagination<void>["limit"];
            search?: string;
        }>({
            query: ({ categoriesIds, limit, offset, search, unloadingId }) => {
                return `unloadings/${unloadingId}/horoshop/products?category_id=${categoriesIds[0]}&limit=${limit}&offset=${offset}${search ? `&search=${search}` : ""}`
            },
            providesTags: (result, error, page) =>
                result
                    ? [
                        {
                            type: "ProductHoroshop" as const,
                            id: page.categoriesIds.join("|") + "|" + page.limit + "|" + page.offset,
                        },
                        { type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" },
                    ]
                    : [{ type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" }],
        }),
        unloadingDelete: build.mutation<void, {
            marketplaceId: MarketplaceId,
            links: UnloadingLink["id"][]
        }>({
            query: ({ marketplaceId, links }) => {
                let listItem = links.map((id) => `items=${id}`).join("&");

                return {
                    url: `unloadings/horoshop?marketplace_id=${marketplaceId}&${listItem}`,
                    method: "DELETE",
                };
            },
            async onQueryStarted(data, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;

                    dispatch(
                        unloadingApi.util.updateQueryData(
                            "unloadingAll",
                            data.marketplaceId,
                            (draft) => (draft.filter(
                                (item) => !data.links.includes(item.id),
                            )),
                        ),
                    );
                } catch { }
            },
        }),
        unloadingCreate: build.mutation<UnloadingExtended, PartialExcept<Omit<UnloadingExtended, "id">, "name" | "file_format">>({
            query: (data) => {
                return {
                    url: "unloadings/horoshop",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: ["UnloadingList"],
        }),
        unloadingUpdate: build.mutation<UnloadingExtended, PartialExcept<UnloadingExtended, "name" | "file_format">>({
            query: (data) => {
                return {
                    url: "unloadings/horoshop/" + data.id,
                    method: "PUT",
                    body: data
                }
            },
            invalidatesTags: ["UnloadingList"],
        }),
        unloadingsHoroshopCategoriesAdd: build.mutation<Pagination<{ products: ProductHoroshop[] }>, {
            unloading_id: UnloadingExtended["id"];
            product_ids: ProductHoroshop["id"][];
            horoshop_category_ids: HoroshopCategory["id"][];
            product_categories: Category["id"][];
            offset: Pagination<void>["offset"];
            limit: Pagination<void>["limit"];
            search?: string;
        }>({
            query: (body) => {
                return {
                    url: `unloadings/${body.unloading_id}/horoshop/categories`,
                    method: "POST",
                    body: body
                }
            },
            invalidatesTags: [{ type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" }],
        }),
        unloadingsHoroshopCategoriesRemove: build.mutation<Pagination<{ products: ProductHoroshop[] }>, {
            unloading_id: UnloadingExtended["id"];
            product_ids: ProductHoroshop["id"][];
            horoshop_category_ids: HoroshopCategory["id"][];
            product_categories: Category["id"][];
            offset: Pagination<void>["offset"];
            limit: Pagination<void>["limit"];
            search?: string;
        }>({
            query: ({ unloading_id, ...query }) => {
                let list_product_ids = query.product_ids.map((id) => `product_ids=${id}`).join("&");
                let list_horoshop_category_ids = query.horoshop_category_ids.map((id) => `horoshop_category_ids=${id}`).join("&");
                let list_product_categories = query.product_categories.map((id) => `product_categories=${id}`).join("&");

                return {
                    url: `unloadings/${unloading_id}/horoshop/categories?${list_product_ids}&${list_horoshop_category_ids}&${list_product_categories}&limit=${query.limit}&offset=${query.offset}${query.search ? `&search=${query.search}` : ""}`,
                    method: "DELETE"
                }
            },
            invalidatesTags: [{ type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" }],
        }),

        categoriesToUnloadingAdd: build.mutation<UnloadingExtended, {
            unloading_id: UnloadingExtended["id"],
            categories: Category["id"][],
            products: ProductHoroshop["id"][]
        }>({
            query: (data) => {
                return {
                    url: "unloadings/horoshop/products",
                    method: "POST",
                    body: data
                }
            },
            invalidatesTags: [{ type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" }],
        }),
        categoriesToUnloadingDelete: build.mutation<UnloadingExtended, {
            unloading_id: UnloadingExtended["id"],
            categories: Category["id"][],
            products: ProductHoroshop["id"][]
        }>({
            query: (data) => {
                const categories = data.categories.map((id) => `categories=${id}`).join("&");
                const products = data.products.map((id) => `products=${id}`).join("&");
                const unloading_id = "&unloading_id=" + data.unloading_id;

                return {
                    url: "unloadings/horoshop/products?" + categories + products + unloading_id,
                    method: "DELETE",
                }
            },
            invalidatesTags: [{ type: "ProductHoroshop", id: "PRODUCT-HOROSHOP-LIST" }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useUnloadingAllQuery,
    useUnloadingOneQuery,
    useLazyUnloadingOneQuery,
    useProductsAllHoroshopQuery,
    useUnloadingDeleteMutation,
    useUnloadingCreateMutation,
    useUnloadingUpdateMutation,
    useUnloadingsHoroshopCategoriesAddMutation,
    useUnloadingsHoroshopCategoriesRemoveMutation,
    useCategoriesToUnloadingAddMutation,
    useCategoriesToUnloadingDeleteMutation
} = unloadingApi;